import React from "react";
import Page from "../common/page/Page.js";
import useTranslationFooter from "./useTranslationFooter.js";
import Terms from "./Terms.js";


export default function TermsPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationFooter();

  return (

      <Page className="TermsPage">
        <Page.Title loading={tLoading}>
        {t("footer:footer_item_terms_and_conditions")}
        </Page.Title>
        <Page.Content>
          <Terms/>
        </Page.Content>
      </Page>

  );
}

