import React, {useMemo} from "react";
import { useMutation } from "@apollo/client";
import QueryUtils from "./QueryUtils.js";
import graphqlErrorFormatter from "./graphqlErrorFormatter.js";

// noinspection JSValidateJSDoc
/**
 * Calls useMutation with the given query and options. When mutate callback is called, return the data contents
 * instead of the data attribute (remove unnecessary levels).
 * @param query GQL query
 * @param queryScope Query scope (first child of Query)
 * @param queryName Query name (child of scope)
 * @param options Options, if any, to pass to useMutation
 * @return {{mutate: (function(*=): Promise<* | null>), called: boolean, data: (*|null), client: ApolloClient<object>, loading: boolean, error: ApolloError}}
 */
export default function useWrappedMutation(
  query,
  queryScope,
  queryName,
  options = {}
) {
  // Override the onCompleted callback passed as an option with a callback that will remove the query name before passing the result to the onCompleted callback
  const higherLevelOnCompleted = options.onCompleted;
  if (higherLevelOnCompleted != null)
    options.onCompleted = (data) =>
      higherLevelOnCompleted(
        QueryUtils.removeQueryScopeAndName(data, queryScope, queryName)
      );

  const [mutate, { data, loading, error, called, client }] = useMutation(
    query,
    options
  );

  // Decorate lower-level "mutate" callback to remove the query name from the result passed to the caller
  const mutateWrapper = React.useCallback(
    (params) => {
      return mutate(params)
        .then((result) =>
          result
            ? QueryUtils.removeQueryScopeAndName(
                result.data,
                queryScope,
                queryName
              )
            : null
        )
        .catch(error => {
          // Convert the ApolloError into a normalized error
          return Promise.reject(graphqlErrorFormatter(error));
        });
    },
    [mutate, queryScope, queryName]
  );

  // Convert the ApolloError into a normalized error
  const normalizedErrors = useMemo(() => graphqlErrorFormatter(error), [error]);

  return useMemo(() => ({
    mutate: mutateWrapper,
    loading,
    errors: normalizedErrors,
    called,
    client,
    data: QueryUtils.removeQueryScopeAndName(data, queryScope, queryName),
  }), [mutateWrapper, loading, normalizedErrors, called, client, data, queryScope, queryName]);
}
