import React from "react";
import PillList from "../../common/widgets/PillList.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import SkillMatchInText from "./SkillMatchInText.js";

export const formatRequiredTechnicalSkills = (requiredTechnicalSkills) => {
  return PillList.formatStrings(TypeUtils.ensureArray(requiredTechnicalSkills));
}

export const formatResumeTechnicalSkills = (resumeTechnicalSkills) => {
  return PillList.formatStrings(
    TypeUtils.ensureArray(resumeTechnicalSkills).reduce((acc, skillGroup) =>
        acc.concat(TypeUtils.ensureArray(skillGroup.skills).map(skill => skill.name)),
      []));
}

export function TechnicalSkillMatchSection(props) {

  // RENDER
  const {allSkills, actualSkillsAsText} = props;

  return (
    <SkillMatchInText allSkills={allSkills} actualSkillsAsText={actualSkillsAsText}/>
  );
}

export default TechnicalSkillMatchSection;
