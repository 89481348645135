import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import FormGroup from "react-bootstrap/esm/FormGroup";
import ValidatedInputText from "../../common/widgets/ValidatedInputText.js";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import indeed from "../../../img/indeed.png";
import Image from "react-bootstrap/esm/Image";
import "./CandidateSaveResumeForm.scss";

export default function CandidateSaveResumeForm({t, control, candidate, ResumeDropbox}) {
  return (
    <div className="CandidateSaveResumeForm">
      <Row>
        <Col className="profile-choice-col">
          <p className="profile-choice-paragraph">
            {t("candidates:candidates_choice_profile")}
          </p>
        </Col>
      </Row>
      <div className="input-div-container">
        <Row className="input-row-container">
          <Col className="input-col-container">
            <Row className="input-row-container">
              <Col className="icon-col">
                <LinkedInIcon className="linkedIn-icon" />
              </Col>
              <Col className="input-col">
                <FormGroup controlId="linkedin">
                  <ValidatedInputText
                    name="linkedin"
                    className="FormControl"
                    defaultValue={candidate.linkedin}
                    control={control}
                    type="text"
                    placeholder={t(
                      "candidates:candidates_create_candidate_linkedin_placeholder"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="input-row-container">
              <Col className="icon-col">
                <div className="indeed-icon-div">
                  <Image src={indeed} />
                </div>
              </Col>
              <Col className="input-col">
                <FormGroup controlId="indeed">
                  <ValidatedInputText
                    name="indeed"
                    className="FormControl"
                    defaultValue={candidate.indeed}
                    control={control}
                    type="text"
                    placeholder={t(
                      "candidates:candidates_create_candidate_indeed_placeholder"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          <Col className="download-col-container">
            <Row>
              <Col className="resume-div">
                <div>{ResumeDropbox}</div>
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </div>
  );
}
