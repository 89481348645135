import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationSponsorship from "../useTranslationSponsorship.js";
import useAuthUser from "../../authentication-no-ui/useAuthUser.js";
import * as ROUTES from "../../../constants/routes.js";
import StringUtils from "../../../utils/StringUtils.js";
import ActionLink from "../../common/widgets/ActionLink.js";
import {CopyToClipboard} from "react-copy-to-clipboard";
import CopyIcon from "@mui/icons-material/Assignment";
import "./SponsoredInviteBlock.scss";
import ShareBar from "../../common/components/ShareBar.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function SponsoredInviteBlock(/*props*/) {
  const { t, loading: tLoading } = useTranslationSponsorship();
  useShowLoader(tLoading, "SponsoredInviteBlock");

  const [copied, setCopied] = React.useState(false);

  const authUser = useAuthUser();
  const {redirectTo} = useReferrer();

  const goToSponsoredList = () => {
    redirectTo(ROUTES.SPONSORED);
  }

  const sponsorCode = authUser && authUser.profile && authUser.profile.sponsorCode;
  const sponsorName = authUser && authUser.profile && authUser.profile.firstName;
  const sponsorLink = `https://spotterful.com/?sponsor=${sponsorCode}`;

  const linkToDashboard = <ActionLink onClick={goToSponsoredList}>{t("sponsorship:invite_dashboard_link_label")}</ActionLink>

  // An error occurred, user does not have a sponsor code
  if (StringUtils.isNullOrEmpty(sponsorCode)) {
    return (
      <div className="SponsoredInviteBlock">
        <Row>
          <Col>
            <h1>{t("sponsorship:invite_title")}</h1>
          </Col>
        </Row>
        <Row>
          <Col>
            {t("sponsorship:invite_no_sponsor_code_msg")}
          </Col>
        </Row>
      </div>
    )
  }

  return (
    <div className="SponsoredInviteBlock">
      <Row>
        <Col>
          <h1>{t("sponsorship:invite_title")}</h1>
        </Col>
      </Row>
      <Row>
        <Col>
          {t("sponsorship:invite_personal_link_msg")}
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={"sponsor-link-container"}>
            <span className={"sponsor-link"}>{sponsorLink}</span>
            <CopyToClipboard text={sponsorLink} onCopy={() => setCopied(true)}>
              <span className={"copy-action"}>
                <CopyIcon titleAccess={t("sponsorship:invite_clipboard_copy_action")}/>
              </span>
            </CopyToClipboard>
            <span className={"copied-label" + (copied ? " active" : "")}>{t("sponsorship:invite_clipboard_copied")}</span>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          {t("sponsorship:invite_use_share_buttons_msg")}
        </Col>
      </Row>
      <Row>
        <Col>
          <ShareBar
            url={sponsorLink}
            title={t("sponsorship:invite_share_twitter_title") /* default title */}
            twitterHashtags={[
              t("sponsorship:invite_share_twitter_hashtag1"), t("sponsorship:invite_share_twitter_hashtag2"),
              t("sponsorship:invite_share_twitter_hashtag3")
            ]}
            twitterTitle={t("sponsorship:invite_share_twitter_title")}
            emailSubject={t("sponsorship:invite_share_email_subject", {name: sponsorName})}
            emailBody={t("sponsorship:invite_share_email_body")}
            linkedInTitle={t("sponsorship:invite_share_linkedin_title")}
            linkedInSummary={t("sponsorship:invite_share_linkedin_summary")}
            facebookHashtag={t("sponsorship:invite_share_facebook_hashtag")}
            facebookQuote={t("sponsorship:invite_share_facebook_quote")}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          {t("sponsorship:invite_view_dashboard_msg", {
            postProcess: "reactPostprocessor",
            link: linkToDashboard
          })}
        </Col>
      </Row>
    </div>
  );
}
