// Don't forget to add localized labels for every option
const RecruiterLocationEnum = {
  QC_ABITIBI: "QC_ABITIBI",
  QC_BAS_ST_LAURENT: "QC_BAS_ST_LAURENT",
  QC_CAPITALE_NATIONALE: "QC_CAPITALE_NATIONALE",
  QC_CENTRE_DU_QUEBEC: "QC_CENTRE_DU_QUEBEC",
  QC_CHAUDIERE_APPALACHES: "QC_CHAUDIERE_APPALACHES",
  QC_COTE_NORD: "QC_COTE_NORD",
  QC_ESTRIE: "QC_ESTRIE",
  QC_GASPESIE_ILES_MADELEINE: "QC_GASPESIE_ILES_MADELEINE",
  QC_LANAUDIERE: "QC_LANAUDIERE",
  QC_LAURENTIDES: "QC_LAURENTIDES",
  QC_LAVAL: "QC_LAVAL",
  QC_MAURICIE: "QC_MAURICIE",
  QC_MONTEREGIE: "QC_MONTEREGIE",
  QC_MONTREAL: "QC_MONTREAL",
  QC_NORD_DU_QUEBEC: "QC_NORD_DU_QUEBEC",
  QC_OUTAOUAIS: "QC_OUTAOUAIS",
  QC_SAGUENAY_LAC_ST_JEAN: "QC_SAGUENAY_LAC_ST_JEAN",
  CA_AB: "CA_AB",
  CA_BC: "CA_BC",
  CA_MB: "CA_MB",
  CA_NB: "CA_NB",
  CA_NL: "CA_NL",
  CA_NS: "CA_NS",
  CA_ON: "CA_ON",
  CA_PE: "CA_PE",
  CA_SK: "CA_SK",
  OTHER_AFRICA: "OTHER_AFRICA",
  OTHER_AMERICA: "OTHER_AMERICA",
  OTHER_ASIA: "OTHER_ASIA",
  OTHER_EUROPE: "OTHER_EUROPE",
};

export default RecruiterLocationEnum;

/**
 * Return the translation for a given enum value.
 * @param t Translation function
 * @param code Work mode enum value
 * @returns {*} Translation string
 */
export function getLocationLabel(t, code) {
  // The method receives a t function and repeats "namespace:" in front of every translation key so that
  // the following keys are extracted when running scan-translations.js script (or "npm run scan-translations").
  // Without the t(), the keys wouldn't be recognized as translation keys.

  switch (code) {
    case RecruiterLocationEnum.QC_ABITIBI:
      return t("profile:location_qc_abitibi_label");
    case RecruiterLocationEnum.QC_BAS_ST_LAURENT:
      return t("profile:location_qc_bas_st_laurent_label");
    case RecruiterLocationEnum.QC_CAPITALE_NATIONALE:
      return t("profile:location_qc_capitale_nationale_label");
    case RecruiterLocationEnum.QC_CENTRE_DU_QUEBEC:
      return t("profile:location_qc_centre_du_quebec_label");
    case RecruiterLocationEnum.QC_CHAUDIERE_APPALACHES:
      return t("profile:location_qc_chaudiere_appalaches_label");
    case RecruiterLocationEnum.QC_COTE_NORD:
      return t("profile:location_qc_cote_nord_label");
    case RecruiterLocationEnum.QC_ESTRIE:
      return t("profile:location_qc_estrie_label");
    case RecruiterLocationEnum.QC_GASPESIE_ILES_MADELEINE:
      return t("profile:location_qc_gaspesie_iles_madeleine_label");
    case RecruiterLocationEnum.QC_LANAUDIERE:
      return t("profile:location_qc_lanaudiere_label");
    case RecruiterLocationEnum.QC_LAURENTIDES:
      return t("profile:location_qc_laurentides_label");
    case RecruiterLocationEnum.QC_LAVAL:
      return t("profile:location_qc_laval_label");
    case RecruiterLocationEnum.QC_MAURICIE:
      return t("profile:location_qc_mauricie_label");
    case RecruiterLocationEnum.QC_MONTEREGIE:
      return t("profile:location_qc_monteregie_label");
    case RecruiterLocationEnum.QC_MONTREAL:
      return t("profile:location_qc_montreal_label");
    case RecruiterLocationEnum.QC_NORD_DU_QUEBEC:
      return t("profile:location_qc_nord_du_quebec_label");
    case RecruiterLocationEnum.QC_OUTAOUAIS:
      return t("profile:location_qc_outaouais_label");
    case RecruiterLocationEnum.QC_SAGUENAY_LAC_ST_JEAN:
      return t("profile:location_qc_saguenay_lac_st_jean_label");
    case RecruiterLocationEnum.CA_AB:
      return t("profile:location_ca_ab_label");
    case RecruiterLocationEnum.CA_BC:
      return t("profile:location_ca_bc_label");
    case RecruiterLocationEnum.CA_MB:
      return t("profile:location_ca_mb_label");
    case RecruiterLocationEnum.CA_NB:
      return t("profile:location_ca_nb_label");
    case RecruiterLocationEnum.CA_NL:
      return t("profile:location_ca_nl_label");
    case RecruiterLocationEnum.CA_NS:
      return t("profile:location_ca_ns_label");
    case RecruiterLocationEnum.CA_ON:
      return t("profile:location_ca_on_label");
    case RecruiterLocationEnum.CA_PE:
      return t("profile:location_ca_pe_label");
    case RecruiterLocationEnum.CA_SK:
      return t("profile:location_ca_sk_label");
    case RecruiterLocationEnum.OTHER_AFRICA:
      return t("profile:location_other_africa_label");
    case RecruiterLocationEnum.OTHER_AMERICA:
      return t("profile:location_other_america_label");
    case RecruiterLocationEnum.OTHER_ASIA:
      return t("profile:location_other_asia_label");
    case RecruiterLocationEnum.OTHER_EUROPE:
      return t("profile:location_other_europe_label");
    default:
      return code;
  }
}
