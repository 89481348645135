import React from "react";
import AppRouter from "./AppRouter.js";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary.js";
import UntranslatedContentError from "../common/error-boundaries/UntranslatedContentError.js";

// Main App
export default function App() {

    return (
      <ErrorBoundary component={<UntranslatedContentError/>}>
        <AppRouter/>
      </ErrorBoundary>
    );
}
