import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FormGroup from "react-bootstrap/FormGroup";
import StringUtils from "../../utils/StringUtils.js";
import ActionButton from "../common/widgets/ActionButton.js";
import FormText from "react-bootstrap/FormText";
import useMutationRecruiterAdd from "./useMutationRecruiterAdd.js";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi.js";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";
import useAuthUser from "../authentication-no-ui/useAuthUser.js";
import useSignOut from "../authentication-no-ui/useSignOut.js";
import Modal from "react-bootstrap/Modal";
import useServerErrorFormatter from "../common/modals/useServerErrorFormatter.js";
import ValidatedInputText from "../common/widgets/ValidatedInputText.js";
import { useValidatedInputForm } from "../common/widgets/useValidatedInputForm.js";
import ActionLink from "../common/widgets/ActionLink.js";
import useLanguages from "../infra-no-ui/translation/useLanguages.js";
import Image from "react-bootstrap/Image";
import add from "../../img/add.png";
import "./ProfileCreationForm.scss";
import ValidatedInputSelect from "../common/widgets/ValidatedInputSelect.js";
import RecruiterLocationEnum, {getLocationLabel} from "../profile/RecruiterLocationEnum.js";
import useTranslationProfile from "../profile/useTranslationProfile.js";
import RecruiterSignUpReasonEnum, {getSignUpReasonLabel} from "../profile/RecruiterSignUpReasonEnum.js";
import useSponsorCode from "../sponsorship/useSponsorCode.js";
import TypeUtils from "../../utils/TypeUtils.js";

export default function ProfileCreationForm() {
  const [errors, setErrors] = React.useState([]);

  // Get used language
  const { getCurrentLanguageCode } = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  const [loading, setLoading] = React.useState(false);
  const { mutate, errors: mutationErrors } = useMutationRecruiterAdd();
  const {
    signOut,
    loading: signOutLoading,
    ready: signOutReady,
  } = useSignOut();
  const { t, tLoading } = useTranslationAuthenticationUi();
  const {t: tProfile, tLoading: tProfileLoading} = useTranslationProfile();

  const sponsorCode = useSponsorCode();

  const authUser = useAuthUser();
  const errorMessage = useServerErrorFormatter(errors);
  useShowLoader(
    tLoading || tProfileLoading || !signOutReady || signOutLoading,
    "ProfileCreationForm"
  );

  const name = StringUtils.nullToEmpty(authUser.displayName);

  const firstName = name.split(" ")[0];
  const lastName = name.split(" ").length > 1 ? name.split(" ")[1] : "";

  const onSubmit = (event) => {
    event.preventDefault();
    const fields = getValues();
    // Manage the loading state manually, don't tie it to the mutation, because we want to control
    // (we want it to stay loading until the user refetch is done)
    setLoading(true);
    setErrors([]);

    // When creating profile, use email as returned by Firebase because it lowercases it
    return mutate({
      variables: {
        firstName: fields.firstName,
        lastName: fields.lastName,
        email: authUser.email,
        sponsoredByCode: sponsorCode,
        location: !StringUtils.isNullOrEmpty(fields.location) ? fields.location : undefined,
        signUpReason: !StringUtils.isNullOrEmpty(fields.signUpReason) ? fields.signUpReason : undefined,
        signUpSource: fields.signUpSource,
        defaultLanguage: currentLanguageCode.toUpperCase(),
      },
    })
      .then(() => {
        // Refetch complete user, don't stop being in a loading state until refetch is done
        authUser.refetch();
      })
      .catch(() => {

        if (!TypeUtils.arrayIsEmpty(mutationErrors) && mutationErrors[0].name === 'DuplicatedProfileError') {
          // If error is a duplicate profile, just reload the page, the existing profile will be used
          authUser.refetch();
        } else {
          // Display error and let the user resubmit
          setErrors(mutationErrors);
          setLoading(false);
        }
      });
  };

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = () => {
    return isValid;
  };

  const onClickSignOut = (event) => {
    event.preventDefault();
    signOut();
  };

  const choiceCompareFunction = (choiceA, choiceB) => StringUtils.nullToEmpty(choiceA.label).localeCompare(choiceB.label);

  const quebecGroup = {
    label: t("profile:location_quebec_group_label"),
    choices: [RecruiterLocationEnum.QC_ABITIBI, RecruiterLocationEnum.QC_BAS_ST_LAURENT, RecruiterLocationEnum.QC_CAPITALE_NATIONALE,
      RecruiterLocationEnum.QC_CENTRE_DU_QUEBEC, RecruiterLocationEnum.QC_CHAUDIERE_APPALACHES, RecruiterLocationEnum.QC_COTE_NORD,
      RecruiterLocationEnum.QC_ESTRIE, RecruiterLocationEnum.QC_GASPESIE_ILES_MADELEINE, RecruiterLocationEnum.QC_LANAUDIERE,
      RecruiterLocationEnum.QC_LAURENTIDES, RecruiterLocationEnum.QC_LAVAL, RecruiterLocationEnum.QC_MAURICIE,
      RecruiterLocationEnum.QC_MONTEREGIE, RecruiterLocationEnum.QC_MONTREAL, RecruiterLocationEnum.QC_NORD_DU_QUEBEC,
      RecruiterLocationEnum.QC_OUTAOUAIS, RecruiterLocationEnum.QC_SAGUENAY_LAC_ST_JEAN].map(location => ({
      value: location,
      label: getLocationLabel(tProfile, location)
    }))
  };
  quebecGroup.choices.sort(choiceCompareFunction);

  const canadaGroup = {
    label: t("profile:location_canada_group_label"),
    choices: [RecruiterLocationEnum.CA_AB, RecruiterLocationEnum.CA_BC, RecruiterLocationEnum.CA_MB,
      RecruiterLocationEnum.CA_NB, RecruiterLocationEnum.CA_NL, RecruiterLocationEnum.CA_NS,
      RecruiterLocationEnum.CA_ON, RecruiterLocationEnum.CA_PE, RecruiterLocationEnum.CA_SK].map(location => ({
      value: location,
      label: getLocationLabel(tProfile, location)
    }))
  };
  canadaGroup.choices.sort(choiceCompareFunction);

  const otherGroup = {
    label: t("profile:location_other_group_label"),
    choices: [RecruiterLocationEnum.OTHER_AFRICA, RecruiterLocationEnum.OTHER_AMERICA,
      RecruiterLocationEnum.OTHER_ASIA, RecruiterLocationEnum.OTHER_EUROPE].map(location => ({
      value: location,
      label: getLocationLabel(tProfile, location)
    }))
  };
  otherGroup.choices.sort(choiceCompareFunction);

  const locationGroups = [quebecGroup, canadaGroup, otherGroup];

  // For some reason, memoizing this on [tProfile, tProfileLoading] does not work
  const signUpReasonChoices = Object.values(RecruiterSignUpReasonEnum).map(reason => ({
    value: reason,
    label: tProfileLoading ? reason : getSignUpReasonLabel(tProfile, reason)
  }));

  // RENDER

  return (
    <Modal
      centered
      show={true}
      size={"lg"}
      backdrop={"static"}
      keyboard={false}
      onHide={signOut}
      className="ProfileCreationForm"
    >
      <Modal.Body className="body-container">
        <Row>
          <Col>
            <Image src={add} className="picture" />
          </Col>
        </Row>
        <Row>
          <Col className="profile-message">
            {t("authentication-ui:missing_profile_msg")}
          </Col>
        </Row>
        <Row>
          <Col className="input-col-container">
            <Form onSubmit={(event) => onSubmit(event)}>
              <FormGroup controlId="firstName">
                <ValidatedInputText
                  name="firstName"
                  control={control}
                  required={true}
                  defaultValue={firstName}
                  className="FormControl"
                  autoFocus
                  placeholder={t(
                    "authentication-ui:sign_up_firstName_placeholder"
                  )}
                  autoComplete="firstName"
                />
              </FormGroup>
              <FormGroup controlId="lastName">
                <ValidatedInputText
                  name="lastName"
                  className="FormControl"
                  control={control}
                  defaultValue={lastName}
                  required={true}
                  placeholder={t(
                    "authentication-ui:sign_up_lastName_placeholder"
                  )}
                  autoComplete="lastName"
                />
              </FormGroup>
              <FormGroup controlId="location">
                <ValidatedInputSelect
                  name="location"
                  className="FormControl form-control-lg"
                  control={control}
                  defaultValue={""}
                  placeholder={t(
                    "authentication-ui:sign_up_location_placeholder"
                  )}
                  withBlankOption
                  groups={locationGroups}
                />
              </FormGroup>
              <FormGroup controlId="signUpReason">
                <ValidatedInputSelect
                  name="signUpReason"
                  className="FormControl form-control-lg"
                  control={control}
                  defaultValue={""}
                  placeholder={t(
                    "authentication-ui:sign_up_reason_placeholder"
                  )}
                  withBlankOption
                  choices={signUpReasonChoices}
                />
              </FormGroup>
              <FormGroup controlId="signUpSource">
                <ValidatedInputText
                  name="signUpSource"
                  className="FormControl"
                  control={control}
                  defaultValue={""}
                  placeholder={t(
                    "authentication-ui:sign_up_source_placeholder"
                  )}
                />
              </FormGroup>
              <Row className="action-row-container">
                <Col className="continue-action">
                  <ActionButton
                    loading={loading}
                    type="submit"
                    disabled={!canSubmit()}
                  >
                    {t("authentication-ui:missing_profile_continue_action")}
                  </ActionButton>
                </Col>
                <Col className="sign-out-action">
                  <ActionLink onClick={onClickSignOut}>
                    {t("authentication-ui:sign_up_sign_out_action")}
                  </ActionLink>
                </Col>
              </Row>
              {errorMessage && (
                <FormText as="div" className="form-error">
                  {errorMessage}
                </FormText>
              )}
            </Form>
          </Col>
        </Row>
      </Modal.Body>
    </Modal>
  );
}
