import React from "react";
import "./PageFooter.scss";
import Container from "react-bootstrap/Container";

/**
 * Displays the footer on every page.
 * This footer needs to be displayable even if some helpers from the main tree fail, such as Firebase authentication
 * and Apollo initialization.
 *
 * @type {React.ForwardRefExoticComponent<React.PropsWithoutRef<{}> & React.RefAttributes<unknown>>}
 */
const PageFooter = React.forwardRef((props, ref) => (
  // RENDER

  <div className="PageFooter">
    <Container ref={ref} />
  </div>
));

export default PageFooter;
