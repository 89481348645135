import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import TypeUtils from "../../../utils/TypeUtils.js";
import useTranslationEmployers from "../useTranslationEmployers.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import KeywordFilter from "../../jobs/search/KeywordFilter.js";
import WorkModeFilter from "../../jobs/search/WorkModeFilter.js";
import JobSort from "../../jobs/search/JobSort.js";
import EmploymentTypeFilter from "../../jobs/search/EmploymentTypeFilter.js";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import FilterOption from "../../common/filters/FilterOption.js";
import StringUtils from "../../../utils/StringUtils.js";
import useMultiValueFilter from "../../common/filters/useMultiValueFilter.js";
import "./JobSearchPane.scss";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function JobSearchPane(props) {
  const {
    companyName,
    searchFilters,
    updateSearchFilters,
    sortValue,
    updateSortValue,
    pageSizeValue,
  } = props;

  const location = useLocation();
  const {redirectTo} = useReferrer();

  const updateSearchFilterField = (field, values) => {
    if (values && values.length > 0) {
      updateSearchFilters(
        TypeUtils.shallowCopyObjectSetProp(searchFilters, field, values)
      );
    } else {
      const { [field]: deletedKey, ...filters } = searchFilters;
      updateSearchFilters(filters);
    }
  };

  // Add the parameters in the url when search keywords change
  useEffect(() => {

    redirectTo({
      pathname: location.pathname,
      search: queryString.stringify({
        keyword: searchFilters.queryText ? searchFilters.queryText : "",
        employmenttype: searchFilters.employmentType
          ? searchFilters.employmentType
          : "",
        sortby: sortValue,
        pagesize: pageSizeValue,
      }),
    });
  }, [searchFilters, sortValue, pageSizeValue, redirectTo, location.pathname]);

  const { t, loading: tLoading } = useTranslationEmployers();
  useShowLoader(tLoading, "JobSearchPane");


  const searchIcon = <SearchIcon />;

  const searchPlaceholder = t("employers:employer_filter_job_keyword", {
    company: companyName,
  });

  const onUncheckValue = (value) => {
    removeFilterValue(value);
  };

  // Normalize selected values to remove close duplicates with available values
  const normalizationFunction = StringUtils.normalizeString;

  const {
    filterValues,
    removeFilterValue,
    inputValue,
    setInputValue,
    addFilterValue,
  } = useMultiValueFilter(
    searchFilters.queryText,
    (value) => updateSearchFilterField("queryText", value),
    normalizationFunction
  );

  const {
    filterValues: placeFilterValues,
  } = useMultiValueFilter(
    searchFilters.location,
    (value) => updateSearchFilterField("location", value),
    normalizationFunction
  );

  const {
    filterValues: companyFilterValues,
  } = useMultiValueFilter(
    searchFilters.companyName,
    (value) => updateSearchFilterField("companyName", value),
    normalizationFunction
  );

  const lastKeywordIndexValue = filterValues.length - 1;

  // RENDER
  return (
    <Card className="JobSearchPane">
      <Card.Body className="job-search-pane-body">
        <Row className="searchContainer">
          <Col className="employer-keyword-filter-col">
            <KeywordFilter
              icon={searchIcon}
              placeholder={searchPlaceholder}
              addFilterValue={addFilterValue}
              setInputValue={setInputValue}
              inputValue={inputValue}
            />
          </Col>
        </Row>
        {(placeFilterValues.length > 0 ||
          filterValues.length > 0 ||
          companyFilterValues.length > 0) && (
          <>
            <Row>
              <Col className="employer-jobs-filter">
                <EmploymentTypeFilter
                  selectedValue={searchFilters.employmentType}
                  updateSelectedValue={(value) =>
                    updateSearchFilterField("employmentType", value)
                  }
                />
              </Col>
              <Col className="employer-jobs-filter">
                <WorkModeFilter
                  name={"workMode"}
                  selectedValue={searchFilters.workMode}
                  updateSelectedValue={(value) =>
                    updateSearchFilterField("workMode", value)
                  }
                />
              </Col>
              <Col className="employer-jobs-filter">
                <JobSort
                  updateSelectedValue={(value) => updateSortValue(value)}
                  selectedValue={sortValue}
                />
              </Col>
            </Row>

            <Row>
              {filterValues.length > 0 && (
                <Col className="filter-option-col">
                  <Row className="filter-results-jobs-row">
                    <Col className="filter-results-jobs">
                      {t("jobs:filter_results_jobs")}
                    </Col>
                    <Col>
                      {filterValues.map((value, index) => (
                        <FilterOption
                          key={value}
                          index={index}
                          checked
                          onClick={() => onUncheckValue(value)}
                          indexValue={lastKeywordIndexValue}
                          value={value}
                        />
                      ))}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
