import React from "react";
import useMutationApplicationUpdate from "./useMutationApplicationUpdate.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useErrorModal from "../../common/modals/useErrorModal.js";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import StringUtils from "../../../utils/StringUtils.js";

/**
 * Generic component for all application editable fields
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ApplicationInlineEditInput(props) {
  const {
    InlineEditInputComponent,
    application,
    placeholder,
    property,
    className,
    ...otherProps
  } = props;
  const { mutate, loading, errors } = useMutationApplicationUpdate();
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ApplicationInlineEditInput");
  const errorMsg = useServerErrorFormatter(errors);

  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("candidates:application_update_error")}</p>
      {errorMsg}
    </>
  );

  const onSubmit = (value) => {
    const variables = {};
    variables["id"] = application._id;
    variables[property] = value;
    mutate({ variables }).catch((error) => {
      showErrorModal();
    });
  };

  return (
    <>
      {ErrorModal}
      <div className={["ApplicationInlineEditInput", className].join(" ")}>
        <InlineEditInputComponent
          placeholder={placeholder}
          value={StringUtils.nullToEmpty(application[property])}
          onSubmit={onSubmit}
          loading={loading}
          {...otherProps}
        />
      </div>
    </>
  );
}
