import React from "react";
import ProfileInlineEditInputText from "../mutate/ProfileInlineEditInputText.js";
import {Col} from "react-bootstrap";
import {Row} from "react-bootstrap";
import useTranslationProfile from "../useTranslationProfile.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import ProfileInlineEditWysiwyg from "../mutate/ProfileInlineEditWysiwyg.js";
import ProfileDeleteButton from "../mutate/ProfileDeleteButton.js";
import UpdatePasswordButton from "../../authentication-ui/UpdatePasswordButton.js";
import PhoneIcon from "@mui/icons-material/Phone";
import EmailIcon from "@mui/icons-material/Email";
import useQueryRecruiterById from "./useQueryRecruiterById.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import "./ProfileBlock.scss";
import { MAX_LENGTH_ABOUT_ME } from "../../../constants/ui.js";
import RequiredLabel from "../../common/widgets/RequiredLabel.js";
import FormLabel from "react-bootstrap/esm/FormLabel";
import FormGroup from "react-bootstrap/esm/FormGroup";

export default function ProfileBlock(props) {
  const { profile: recruiter } = props;

  const {
    data: profile,
    loading: recruiterLoading,
    errors: recruiterErrors,
  } = useQueryRecruiterById(recruiter._id);
  const { t, loading: tLoading } = useTranslationProfile();
  useShowLoader(tLoading, "ProfileBlock");

  return (
    <WaitForData
      loading={recruiterLoading}
      errors={recruiterErrors}
      onLoaded={() => (
        <>
          <div className="ProfileBlock">
            <Row>
              <Col>
                <FormGroup>
                  <FormLabel className="label">
                    {t("profile:recruiters_profile_name_msg")}
                    <RequiredLabel />
                  </FormLabel>
                  <Row>
                    <Col className="displayName">
                      <ProfileInlineEditInputText
                        profile={profile}
                        viewAs="span"
                        property={"firstName"}
                        placeholder={t(
                          "profile:recruiters_profile_firstname_placeholder"
                        )}
                        required
                      />
                    </Col>
                    <Col>
                      <ProfileInlineEditInputText
                        profile={profile}
                        viewAs="span"
                        property={"lastName"}
                        placeholder={t(
                          "profile:recruiters_profile_lastname_placeholder"
                        )}
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <Col>
                <FormGroup>
                  <FormLabel className="label">
                    {t("profile:recruiters_profile_job_title_msg")}
                    <RequiredLabel />
                  </FormLabel>
                  <Row>
                    <Col className="displayName">
                      <ProfileInlineEditInputText
                        profile={profile}
                        viewAs="span"
                        property={"jobTitle"}
                        placeholder={t(
                          "profile:recruiters_profile_job_title_placeholder"
                        )}
                        required
                      />
                    </Col>
                  </Row>
                </FormGroup>
              </Col>
            </Row>
            <Row>
              <FormGroup>
                <FormLabel className="label">
                  {t("profile:recruiters_profile_contact_information_msg")}
                  <RequiredLabel />
                </FormLabel>
                <Row>
                  <Col className="icon-col">
                    <PhoneIcon />
                  </Col>
                  <Col>
                    <ProfileInlineEditInputText
                      profile={profile}
                      property={"phone"}
                      placeholder={t(
                        "profile:recruiters_profile_phone_placeholder"
                      )}
                      required
                    />
                  </Col>
                </Row>
                <Row>
                  <Col className="icon-col">
                    <EmailIcon />
                  </Col>
                  <Col>{profile.email}</Col>
                </Row>
              </FormGroup>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col>
                    <FormGroup>
                      <FormLabel className="label">
                        {t("profile:recruiters_profile_presentation_msg")}
                        <RequiredLabel />
                      </FormLabel>
                      <ProfileInlineEditWysiwyg
                        className={"note-input"}
                        required
                        maxLength={MAX_LENGTH_ABOUT_ME}
                        profile={profile}
                        property={"aboutMe"}
                        placeholder={t(
                          "profile:recruiters_profile_presentation_placeholder"
                        )}
                      />
                    </FormGroup>
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <Row>
            <Col className="deleteUpdateContainer">
              <Row>
                <Col>
                  <UpdatePasswordButton recruiterId={profile._id} />
                </Col>
              </Row>
              <Row>
                <Col>
                  <ProfileDeleteButton recruiterId={profile._id} />
                </Col>
              </Row>
            </Col>
          </Row>
        </>
      )}
    />
  );
}
