import React from "react";
import RefreshIcon from "@mui/icons-material/Refresh";
import ActionLink from "./ActionLink.js";
import useTranslationCommon from "../useTranslationCommon.js";
import useShowLoader from "../loading-widgets/useShowLoader.js";
import useClearApolloStore from '../../infra-no-ui/apollo/useClearApolloStore.js';

export default function RefreshButton() {
  const { t, loading: tLoading } = useTranslationCommon();
  const { clearApolloStore, loading } = useClearApolloStore();

  useShowLoader(tLoading);

  return (
    <ActionLink onClick={clearApolloStore} loading={loading}>
      <RefreshIcon />
      {t("common:common_refresh_label")}
    </ActionLink>
  );

}
