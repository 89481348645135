import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import "./SponsorshipBlock.scss";
import useTranslationSponsorship from "../useTranslationSponsorship.js";
import useAuthUser from "../../authentication-no-ui/useAuthUser.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import * as ROUTES from "../../../constants/routes.js";
import step1Img from "../../../img/undraw_add_user_re_5oib.svg";
import step2Img from "../../../img/undraw_share_link_re_54rx.svg";
import step3Img from "../../../img/step3.svg";
import {Row, Col, Image} from "react-bootstrap";
import ActionLink from "../../common/widgets/ActionLink.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function SponsorshipBlock(/*props*/) {
  const { t, loading: tLoading } = useTranslationSponsorship();
  useShowLoader(tLoading, "SponsorshipBlock");

  const authUser = useAuthUser();
  const {redirectTo} = useReferrer();

  const goToInvite = () => {
    redirectTo(ROUTES.SPONSORSHIP_INVITE);
  }

  const goToCreateAccount = () => {
    // Go to Sign Up page, then redirect to the sponsorship details page so that user can see next step
    redirectTo(ROUTES.SIGN_UP);
  }

  return (
    <div className="SponsorshipBlock">
      <Row>
        <Col>
          <div className={"header-block"}>
            <Row>
              <Col>
                <h1 className={"title"}>{t("sponsorship:sponsorship_landing_header")}</h1>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className={"subtitle"}>{t("sponsorship:sponsorship_landing_subheader1")}</span>
              </Col>
            </Row>
            <Row>
              <Col>
                <span className={"subtitle"}>{t("sponsorship:sponsorship_landing_subheader2")}</span>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={"how-block"}>
            <Row>
              <Col>
                <span className={"title"}>{t("sponsorship:sponsorship_landing_how_title")}</span>
              </Col>
            </Row>
            <Row className={"step-row"}>
              <Col className={"step-col"}>
                <div className={"step-cell"}>
                  <Row>
                    <Col className="step-pic-col">
                      <Image className="step-img" src={step1Img} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className={"step-number"}>1.</span> {t("sponsorship:sponsorship_landing_step1_msg")}
                      {!authUser.authenticated &&
                      <>
                        <br/><ActionLink onClick={goToCreateAccount}>{t("sponsorship:sponsorship_landing_step1_action")}</ActionLink>
                      </>
                      }
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className={"step-col"}>
                <div className={"step-cell"}>
                  <Row>
                    <Col className="step-pic-col">
                      <Image className="step-img" src={step2Img} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className={"step-number"}>2.</span> {t("sponsorship:sponsorship_landing_step2_msg")}
                      {authUser.authenticated &&
                      <>
                      <br/><ActionLink onClick={goToInvite}>{t("sponsorship:sponsorship_landing_step2_action")}</ActionLink>
                      </>
                      }
                    </Col>
                  </Row>
                </div>
              </Col>
              <Col className={"step-col"}>
                <div className={"step-cell"}>
                  <Row>
                    <Col className="step-pic-col">
                      <Image className="step-img" src={step3Img} />
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <span className={"step-number"}>3.</span> {t("sponsorship:sponsorship_landing_step3_msg")}
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={"action-block"}>
            {authUser.authenticated ?
              <ActionButton onClick={goToInvite}>{t("sponsorship:sponsorship_landing_invite_action")}</ActionButton>
              :
              <ActionButton onClick={goToCreateAccount}>{t("sponsorship:sponsorship_landing_create_action")}</ActionButton>
            }
          </div>
        </Col>
      </Row>
    </div>
  );
}
