import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import PlaceIcon from "@mui/icons-material/Place";
import BusinessIcon from "@mui/icons-material/Business";
import DateRangeIcon from "@mui/icons-material/DateRange";
import StringUtils from "../../../utils/StringUtils.js";
import {
  formatRequiredTechnicalSkills,
  TechnicalSkillMatchSection,
} from "../resume-matching/TechnicalSkillMatchSection";
import {
  formatRequiredSoftSkills,
  SoftSkillMatchSection,
} from "../resume-matching/SoftSkillMatchSection";
import ShareBar from "../../common/components/ShareBar.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import * as ROUTES from "../../../constants/routes.js";
import RouterUtils from "../../../utils/RouterUtils.js";
import DisplayDate from "../../common/widgets/DisplayDate.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import "./ApprovalDetailsJob.scss";
import CandidateApprovalStatusEnum from "../../candidates/CandidateApprovalStatusEnum.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function ApprovalDetailsJob(props) {
  const { job, applicationId, approval } = props;

  const {redirectTo} = useReferrer();

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "CandidateApprovalBlock");

  const onClickApproval = () => {
    redirectTo(
      RouterUtils.injectParamsInRoute(
        ROUTES.RECRUITER_CANDIDATE_APPROVED_APPLICATION,
        {
          jobId: job._id,
          applicationId: applicationId,
        }
      )
    );
  };

  const requiredTechnicalSkills = formatRequiredTechnicalSkills(
    job.technicalSkills
  );

  const requiredSoftSkills = formatRequiredSoftSkills(job.softSkills);

  const addressLine = TypeUtils.arrayRemoveNullOrEmpty([job.city, job.state, job.country])
    .join(", ");

  const domainName = window.location.hostname;

  const url =
    domainName +
    RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_JOB, {
      jobId: job._id,
    });

  function onClickBecomeSpotter(event) {
    event.preventDefault();
    redirectTo(ROUTES.SIGN_UP);
  }

  return (
    <>
      <div className="ApprovalDetailsJob">
        <Row>
          <Col>
            <h1>{job.title}</h1>
          </Col>
        </Row>
        <Row>
          <Col className="business-block">
            <Row>
              <Col className="icon-col">
                <BusinessIcon />
              </Col>
              <Col>{job.organization && job.organization.company}</Col>
            </Row>
          </Col>
          {!StringUtils.isNullOrEmpty(addressLine) && (
            <Col className="place-block">
              <Row>
                <Col className="icon-col">
                  <PlaceIcon />
                </Col>
                <Col>{addressLine}</Col>
              </Row>
            </Col>
          )}
          <Col className="date-block">
            <Row>
              <Col className="icon-col">
                <DateRangeIcon />
              </Col>
              <Col>
                <DisplayDate date={job.createdAt} />
              </Col>
            </Row>
          </Col>
        </Row>
        <Row className="share-row">
          <Col>
            <ShareBar
              url={url}
              tags={[job.title, job.organization.company].concat(
                job.technicalSkills
              )}
              title={job.title}
            />
          </Col>
        </Row>

        {requiredTechnicalSkills.length > 0 && (
          <>
            <Row>
              <Col>
                <hr />
              </Col>
            </Row>
            <Row>
              <Col>
                <Row>
                  <Col id="TechnicalSkill" className="section-title">
                    {t("jobs:job_technical_skills_label")}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <TechnicalSkillMatchSection
                      allSkills={requiredTechnicalSkills}
                    />
                  </Col>
                </Row>
              </Col>
            </Row>
          </>
        )}
        {requiredSoftSkills.length > 0 && (
          <Row>
            <Col>
              <Row>
                <Col className="section-title">
                  {t("jobs:job_soft_skills_label")}
                </Col>
              </Row>
              <Row>
                <Col>
                  <SoftSkillMatchSection allSkills={requiredSoftSkills} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
        <Row>
          <Col>
            <hr />
          </Col>
        </Row>
        <Row>
          <Col>{HtmlUtils.htmlToComponents(job.description)}</Col>
        </Row>

        {approval.candidateApprovalStatus ===
          CandidateApprovalStatusEnum.APPROVED && (
          <>
            <Row>
              <Col className="become-spotter-col">
                <ActionButton
                  className="become-spotter-action-button"
                  onClick={(event) => onClickBecomeSpotter(event)}
                >
                  {t("jobs:job_approval_action_button_label")}
                </ActionButton>
              </Col>
            </Row>
          </>
        )}
        <Row>
          <Col>
            <Row className="approval-action-row">
              <Col className="approval-col">
                {approval.candidateApprovalStatus !==
                  CandidateApprovalStatusEnum.APPROVED && (
                  <ActionButton
                    className="become-spotter-action-button"
                    onClick={() =>
                      onClickApproval()
                    }
                  >
                    {t("jobs:job_approval_action")}
                  </ActionButton>
                )}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
