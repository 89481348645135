import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationDashboard from "../useTranslationDashboard.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import DashboardActiveApplicationsList from "./DashboardActiveApplicationsList.js";
import DashboardAlertsList from "./DashboardAlertsList.js";
import * as ROUTES from "../../../constants/routes.js";
import TotalRevenueItem from "./TotalRevenueItem.js";
import CandidatesByStatusCountItem from "./CandidatesByStatusCountItem.js";
import RatingStarsItem from "./RatingStarsItem.js";
import ActionLink from "../../common/widgets/ActionLink.js";
import PageSubtitle from "../../common/widgets/PageSubtitle.js";
import RefreshButton from "../../common/widgets/RefreshButton.js";
import "./DashboardBlock.scss";
import SponsorshipItem from "./SponsorshipItem.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

function DashboardBlock(props) {
  const { recruiterId } = props;

  const { t, loading: tLoading } = useTranslationDashboard();
  useShowLoader(tLoading, "DashboardBlock");
  const {redirectTo} = useReferrer();

  const onClickCreateCandidate = (event) => {
    event.preventDefault();
    redirectTo(ROUTES.RECRUITER_CANDIDATE_CREATE);
  };

  const onClickViewAll = (event) => {
    event.preventDefault();
    redirectTo(ROUTES.RECRUITER_CANDIDATES);
  };

  const browseJobs = (event) => {
    event.preventDefault();
    redirectTo(ROUTES.RECRUITER_JOBS);
  };

  return (
    <div className="DashboardBlock">
      <Row>
        <Col>
          <h1>{t("dashboard:recruiters_dashboard_title")}</h1>
        </Col>
        <Col className={"link-col"}>
          <RefreshButton />
        </Col>
      </Row>

      <Row>
        <Col>
          <div className="recommendation-div-container">
            <Row className="browse-jobs-Row">
              <Col className="browse-jobs-label">
                {t("dashboard:recruiters_dashboard_browse_jobs")}
                <ActionLink className="label-action" onClick={browseJobs}>
                  {t("dashboard:recruiters_dashboard_browse_jobs_label_button")}
                </ActionLink>
                {t("dashboard:recruiters_dashboard_browse_jobs_label_or")}
                <ActionLink
                  className="label-action"
                  onClick={onClickCreateCandidate}
                >
                  {t(
                    "dashboard:recruiters_dashboard_create_candidate_button_label"
                  )}
                </ActionLink>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="dashboardStatisticalBlocs">
                  <Row className={"list-row "}>
                    <Col className="information-col">
                      <div className="sub-block">
                        <TotalRevenueItem recruiterId={recruiterId} />
                      </div>
                    </Col>
                    <Col className="information-col">
                      <div className="sub-block">
                        <CandidatesByStatusCountItem
                          recruiterId={recruiterId}
                        />
                      </div>
                    </Col>
                    <Col className="information-col">
                      <div className="employer-reviews-div">
                        <Row>
                          <Col>
                            <PageSubtitle
                              colTitle={t(
                                "dashboard:recruiters_dashboard_recruiter_score_title"
                              )}
                            />
                          </Col>
                        </Row>
                        <Row>
                          <Col className="starsItemCol">
                            <RatingStarsItem
                              recruiterId={recruiterId}
                              size={45}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                    <Col className="information-col">
                      <div className="sub-block">
                        <SponsorshipItem
                          recruiterId={recruiterId}
                        />
                      </div>
                    </Col>
                  </Row>
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>

      <Row className="list-row">
        <Col>
          <DashboardAlertsList recruiterId={recruiterId} />
        </Col>
      </Row>
      <Row>
        <Col>
          <div className={"widget-block"}>
            <Row className="list-row">
              <Col>
                <DashboardActiveApplicationsList recruiterId={recruiterId} />
              </Col>
            </Row>
            <Row className="list-row">
              <Col>
                <ActionLink onClick={onClickViewAll}>
                  {t(
                    "dashboard:recruiters_dashboard_applications_list_view_all_link"
                  )}
                </ActionLink>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default DashboardBlock;
