import i18next from 'i18next';
import { initReactI18next } from 'react-i18next';
import LANGUAGES from '../../../constants/languages.js';
import LanguageDetector from 'i18next-browser-languagedetector';
import StringUtils from "../../../utils/StringUtils.js";
import ReactPostprocessor from 'i18next-react-postprocessor';

// Export a promise of i18next that is resolved once the initialization is done
const i18n = i18next
  // detect user language. See: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // load only needed translations from server
  //.use(Xhr)
  // allow placeholders to JSX components in strings like <name> instead of node numbers like <0>...</0>
  // See https://github.com/orzechowskid/i18next-react-postprocessor to learn how to use it
  .use(new ReactPostprocessor({prefix: "<", suffix: ">"}))
  // init i18next
  .init({
    // Beware that changes in these options may require changes in scripts/extract-translations.js

    // Fallback language (must be one of the LANGUAGES keys)
    fallbackLng: Object.keys(LANGUAGES)[0],
    // Authorized languages (if a locale is found for a language not in LANGUAGES, don't use it)
    whitelist: Object.keys(LANGUAGES),
    // Derivatives of authorized languages (eg. 'en-US') are also allowed (otherwise fallback would be used automatically)
    nonExplicitWhitelist: true,
    // 'en-US' becomes 'en-us'
    lowerCaseLng: true,
    // Display key if translation is not found anywhere
    returnEmptyString: false,
    // Fetch translations for those namespaces only
    // These will be loaded later depending on selected bundle
    //ns: TRANSLATION_NAMESPACES,
    // If a namespace is not provided in t(key), try 'common:key'
    defaultNS: 'common',
    // Console.log some info in development only
    debug: StringUtils.stringIsTrue(process.env.REACT_APP_I18N_LOGGING),

    // Will be loaded later; we must pass at least an empty object so that i18n status changes for "ready"
    resources: {},

    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },

    //backend: {
    //  for all available options read the backend's repository readme file
    //  loadPath: '/locales/{{lng}}/{{ns}}.json',
    //  crossDomain: true,
    //},

    react: {
      // useSuspense works only on first load, we provide our own mechanism to support waiting while
      // changing language too
      useSuspense: false,
    }
  });

export default i18n;
