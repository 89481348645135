import React from "react";
import "./JobSearchResultList.scss";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import JobSearchItemLanding from "./JobSearchItemLanding.js";

export default function JobSearchResultList(props) {

  const {loading, errors, jobs} = props;

  // Translation or Query is loading for the first time, return a spinner
  useShowLoader(loading);

  const resultComponent = <JobSearchItemLanding searchFilters={{}} />;

  return (
    <div className="JobSearchResultList">
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <>
            {jobs.map((item, index) =>
              React.cloneElement(resultComponent, {
                key: index,
                item,
              })
            )}
          </>
        )}
      />
    </div>
  );
}
