import React from "react";
import Page from "../../common/page/Page.js";
import LandingBlock from "./EmployerLandingBlock.js";
import useTranslationEmployerLanding from "../useTranslationEmployerLanding.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";

export default function EmployerLandingPage(props) {

  const { t, loading: tLoading } = useTranslationEmployerLanding();
  useShowLoader(tLoading, "EmployerLandingBlock");

  return (
    <Page className="EmployerLandingPage">
      <Page.Title text={t("employer-landing:landing_title")} />
      <Page.Content className="spaced-children">
        <LandingBlock />
      </Page.Content>
    </Page>
  );
}
