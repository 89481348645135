import React, { useEffect } from "react";
import Card from "react-bootstrap/Card";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import BusinessIcon from "@mui/icons-material/Business";
import PlaceIcon from "@mui/icons-material/Place";
import SearchIcon from "@mui/icons-material/Search";
import TypeUtils from "../../../utils/TypeUtils.js";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import KeywordFilter from "./KeywordFilter.js";
import JobSort from "./JobSort.js";
import EmploymentTypeFilter from "./EmploymentTypeFilter.js";
import {useLocation} from "react-router-dom";
import queryString from "query-string";
import FilterOption from "../../common/filters/FilterOption.js";
import StringUtils from "../../../utils/StringUtils.js";
import useMultiValueFilter from "../../common/filters/useMultiValueFilter.js";
import "./JobSearchPane.scss";
import WorkModeFilter from "./WorkModeFilter.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function JobSearchPane(props) {
  const {
    searchFilters,
    updateSearchFilters,
    sortValue,
    updateSortValue,
    pageSizeValue,
  } = props;

  const location = useLocation();
  const {redirectTo} = useReferrer();

  const updateSearchFilterField = (field, values) => {
    if (values && values.length > 0) {
      updateSearchFilters(
        TypeUtils.shallowCopyObjectSetProp(searchFilters, field, values)
      );
    } else {
      const { [field]: deletedKey, ...filters } = searchFilters;
      updateSearchFilters(filters);
    }
  };

  const locationPathname = location.pathname;

  // Add the parameters in the url when search keywords change
  useEffect(() => {

    redirectTo({
      pathname: locationPathname,
      search: queryString.stringify({
        companyname: searchFilters.companyName ? searchFilters.companyName : "",
        keyword: searchFilters.queryText ? searchFilters.queryText : "",
        location: searchFilters.location ? searchFilters.location : "",
        employmenttype: searchFilters.employmentType
          ? searchFilters.employmentType
          : "",
        sortby: sortValue,
        pagesize: pageSizeValue,
      }),
    });
  }, [searchFilters, sortValue, pageSizeValue, redirectTo, locationPathname]);

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobSearchPane");

  const companyIcon = <BusinessIcon/>;
  const placeIcon = <PlaceIcon />;
  const searchIcon = <SearchIcon />;

  const companyPlaceholder = t("jobs:filter_job_company");
  const placePlaceholder = t("jobs:filter_job_location");
  const searchPlaceholder = t("jobs:filter_job_keyword");

  const onUncheckValue = (value) => {
    removeFilterValue(value);
  };

  const onUncheckPlaceValue = (value) => {
    removePlaceFilterValue(value);
  };

  const onUncheckCompanyValue = (value) => {
    removeCompanyFilterValue(value);
  };

  // Normalize selected values to remove close duplicates with available values
  const normalizationFunction = StringUtils.normalizeString;

  const {
    filterValues,
    removeFilterValue,
    inputValue,
    setInputValue,
    addFilterValue,
  } = useMultiValueFilter(
    searchFilters.queryText,
    (value) => updateSearchFilterField("queryText", value),
    normalizationFunction
  );

  const {
    filterValues: placeFilterValues,
    removeFilterValue: removePlaceFilterValue,
    inputValue: placeInputValue,
    setInputValue: setPlaceInputValue,
    addFilterValue: addPlaceFilterValue,
  } = useMultiValueFilter(
    searchFilters.location,
    (value) => updateSearchFilterField("location", value),
    normalizationFunction
  );

  const {
    filterValues: companyFilterValues,
    removeFilterValue: removeCompanyFilterValue,
    inputValue: companyInputValue,
    setInputValue: setCompanyInputValue,
    addFilterValue: addCompanyFilterValue,
  } = useMultiValueFilter(
    searchFilters.companyName,
    (value) => updateSearchFilterField("companyName", value),
    normalizationFunction
  );

  const lastPlaceIndexValue = placeFilterValues.length - 1;
  const lastKeywordIndexValue = filterValues.length - 1;
  const lastCompanyIndexValue = companyFilterValues.length - 1;

  // RENDER
  return (
    <Card className="JobSearchPane">
      <Card.Body className="job-search-pane-body">
        <Row className="searchContainer">
          <Col className="keyword-filter-col">
            <KeywordFilter
              icon={searchIcon}
              placeholder={searchPlaceholder}
              addFilterValue={addFilterValue}
              setInputValue={setInputValue}
              inputValue={inputValue}
            />
          </Col>
          <Col className="keyword-filter-col">
            <KeywordFilter
              icon={placeIcon}
              placeholder={placePlaceholder}
              addFilterValue={addPlaceFilterValue}
              setInputValue={setPlaceInputValue}
              inputValue={placeInputValue}
            />
          </Col>
          <Col className="keyword-filter-col">
            <KeywordFilter
              icon={companyIcon}
              placeholder={companyPlaceholder}
              addFilterValue={addCompanyFilterValue}
              setInputValue={setCompanyInputValue}
              inputValue={companyInputValue}
            />
          </Col>
        </Row>
        {(placeFilterValues.length > 0 || filterValues.length > 0 || companyFilterValues.length > 0) && (
          <>
            <Row>
              <Col className="job-filter">
                <EmploymentTypeFilter
                  selectedValue={searchFilters.employmentType}
                  updateSelectedValue={(value) =>
                    updateSearchFilterField("employmentType", value)
                  }
                />
              </Col>
              <Col className="job-filter">
                <WorkModeFilter
                  name={"workMode"}
                  selectedValue={searchFilters.workMode}
                  updateSelectedValue={(value) =>
                    updateSearchFilterField("workMode", value)
                  }
                />
              </Col>
              <Col className="job-filter">
                <JobSort
                  updateSelectedValue={(value) => updateSortValue(value)}
                  selectedValue={sortValue}
                />
              </Col>
            </Row>

            <Row>
              {filterValues.length > 0 && (
                <Col className="filter-option-col">
                  <Row className="filter-results-jobs-row">
                    <Col className="filter-results-jobs">
                      {t("jobs:filter_results_jobs")}
                    </Col>
                    <Col>
                      {filterValues.map((value, index) => (
                        <FilterOption
                          key={value}
                          index={index}
                          checked
                          onClick={() => onUncheckValue(value)}
                          indexValue={lastKeywordIndexValue}
                          value={value}
                        />
                      ))}
                    </Col>
                  </Row>
                </Col>
              )}
              {companyFilterValues.length > 0 && (
                <Col className="filter-option-col">
                  <Row className="filter-results-jobs-row">
                    {filterValues.length > 0 ? (
                      <Col className="filter-results-jobs">
                        {t("jobs:filter_in_for_company")}
                      </Col>
                    ) : (
                      <Col className="filter-results-jobs">
                        {t("jobs:filter_results_jobs")}
                      </Col>
                    )}
                    <Col>
                      {companyFilterValues.map((value, index) => (
                        <FilterOption
                          key={value}
                          index={index}
                          indexValue={lastCompanyIndexValue}
                          checked
                          onClick={() => onUncheckCompanyValue(value)}
                          value={value}
                        />
                      ))}
                    </Col>
                  </Row>
                </Col>
              )}
              {placeFilterValues.length > 0 && (
                <Col className="filter-option-col">
                  <Row className="filter-results-jobs-row">
                    {filterValues.length > 0 || companyFilterValues.length > 0 ? (
                      <Col className="filter-results-jobs">
                        {t("jobs:filter_in_for_location")}
                      </Col>
                    ) : (
                      <Col className="filter-results-jobs">
                        {t("jobs:filter_results_jobs")}
                      </Col>
                    )}
                    <Col>
                      {placeFilterValues.map((value, index) => (
                        <FilterOption
                          key={value}
                          index={index}
                          indexValue={lastPlaceIndexValue}
                          checked
                          onClick={() => onUncheckPlaceValue(value)}
                          value={value}
                        />
                      ))}
                    </Col>
                  </Row>
                </Col>
              )}
            </Row>
          </>
        )}
      </Card.Body>
    </Card>
  );
}
