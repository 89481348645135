import {gql} from "@apollo/client";
import React from "react";
import usePagedQuery from "../../common/data-fetching/usePagedQuery.js";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobPublicSearch";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($cursor: Int, $pageSize: Int, $filter: JobFilterInput, $sort: JobSortEnum, $language: String) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(cursor: $cursor, pageSize: $pageSize, filter: $filter, sort: $sort, language: $language) {
        nodes {
          _id
          organizationId
          organization {
            _id
            company
          }
          title
          city
          state
          country
          commission
          createdAt
        }
      }
    }
  }
`;

export default function useQueryJobPublicSearch(pageSize, filter, sort, language) {

  // Memoize option object to avoid rendering loop in usePagedQuery
  // MAKE SURE filter IS ALSO MEMOIZED BY CALLER!
  const options = React.useMemo(() => ({
    variables: {
      filter,
      sort,
      language
    },
    fetchPolicy: "network-only"
  }), [filter, sort, language]);

  return usePagedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, pageSize, pageSize, options);
}
