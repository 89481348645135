import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import useQueryRevenueByRecruiterId from "./useQueryRevenueByRecruiterId.js";
import useTranslationDashboard from "../useTranslationDashboard.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./TotalRevenueItem.scss";
import PageSubtitle from "../../common/widgets/PageSubtitle.js";

export default function TotalRevenueItem(props) {
  const { recruiterId } = props;

  const { t, loading: tLoading } = useTranslationDashboard();
  useShowLoader(tLoading, "DashboardBlock");

  const {
    data: amount,
    loading,
    errors,
  } = useQueryRevenueByRecruiterId(recruiterId);

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <div className="TotalRevenueItem">
            <Row>
              <Col>
                <PageSubtitle
                  colTitle={t(
                    "dashboard:recruiters_dashboard_applications_revenue_label"
                  )}
                />
              </Col>
            </Row>
            <Row>
              <Col className="title-col-sub">
                <span className = "revenue-span">
                {t(
                  "dashboard:recruiters_dashboard_applications_revenue_amount",
                  {
                    amount: amount,
                  }
                )}
                </span>
              </Col>
            </Row>
          </div>
        )}
      />
    </HorizontalLoader>
  );
}
