import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import RouterUtils from "../../../utils/RouterUtils.js";
import * as ROUTES from "../../../constants/routes.js";
import "./CandidateSearchWithApplicationCountResult.scss";
import ActionButton from "../../common/widgets/ActionButton.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import DateUtils from "../../../utils/DateUtils.js";
import ApplicationCountItem from "./ApplicationCountItem.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function CandidateSearchWithApplicationCountResult(props) {
  const { item: candidate } = props;

  const {redirectTo} = useReferrer();

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidateSearchWithApplicationCountResult");

  function onClickCandidate(event, candidateId) {
    event.preventDefault();
    redirectTo(
      RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_CANDIDATE_PROFILE, {
        candidateId,
      })
    );
  }

  return (
    <ActionButton
      as={"div"}
      variant="outline-primary"
      onClick={(event) => onClickCandidate(event, candidate._id)}
      className={"CandidateSearchWithApplicationCountResult"}
    >
      <Row className="row-container">
        <Col className={"name-col"}>{candidate.name}</Col>
        <Col className="application-count-col">
          <ApplicationCountItem candidateId={candidate._id} />
        </Col>
        <Col className="date-col">
          {t("candidates:candidates_details_creation_period_days", {
            count: DateUtils.dateDiff(Date.parse(candidate.updatedAt)),
          })}
        </Col>
      </Row>
    </ActionButton>
  );
}
