import React from "react";
import Firebase from "./Firebase.js";
import FirebaseContext from "./FirebaseContext.js";
import useLanguages from "../translation/useLanguages.js";

/**
 * Context provider that can be consumed by useFirebase to get current Firebase authentication server connection.
 * Environment variables are read at mounting time. If they are invalid, an error page is displayed.
 * The user can't go further and the app has to be fixed. Does not detect connection errors or invalid urls/credentials
 * in configuration because no connection is attempted yet.
 *
 * The connection language is updated when the current language changes, so that error messages from Firebase are
 * displayed in the user's language.
 */
export default function FirebaseProvider(props) {

  // Get the current language code
  const {getCurrentLanguageCode} = useLanguages();
  const currentLanguage = getCurrentLanguageCode();

  // Get a new Firebase connection
  const [firebase] = React.useState(new Firebase());

  // Every time the current language changes, change the firebase connection language
  React.useEffect(() => {
    if (firebase.isInitialized())
      firebase.setLanguageCode(currentLanguage);

    // It is not required to call setFirebase, because change of Firebase language has no client-side effect
  }, [currentLanguage, firebase /*avoid warnings*/]);

  // RENDER

  // If an error occurred during Firebase initialization, it means the configuration is bad. It is not a temporary
  // connection problem. User can't go further.
  const ErrorComponent = props.errorComponent;

  if (firebase.lastError) {
    return <ErrorComponent error={firebase.lastError.message}/>
  }

  // Return a Firebase connection provider
  return (
    <FirebaseContext.Provider value={firebase}>
      {props.children}
    </FirebaseContext.Provider>
  );
}
