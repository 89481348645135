/**
 * Utils to scroll page
 */
export default class ScrollUtils {
  /**
   * Scroll to the top of the page.
   */
  static scrollToTop = () => {
    window.scrollTo(0, 0);
  };
}
