import React from "react";
import Page from "../common/page/Page.js";
import useTranslationFooter from "./useTranslationFooter.js";
import Privacy from "./Privacy.js";


export default function PrivacyPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationFooter();

  return (

      <Page className="PrivacyPage">
        <Page.Title loading={tLoading}>
        {t("footer:footer_privacy")}
        </Page.Title>
        <Page.Content>
          <Privacy/>
        </Page.Content>
      </Page>

  );
}
