import { Button } from "react-bootstrap";
import SearchIcon from "@mui/icons-material/Search";
import queryString from "query-string";
import * as ROUTES from "../../../constants/routes.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

const PopularSearchButton = (props) => {
  const { keyword } = props;

  const {redirectTo} = useReferrer();

  const OnClickPopularSearch = () => {
    redirectTo({
      pathname: ROUTES.RECRUITER_MARKET_INSIGHTS,
      search: queryString.stringify({
        keyword: keyword,
      }),
    });
  };

  return (
    <Button onClick={OnClickPopularSearch}>
      <SearchIcon className="search-icon" />
      {keyword}
    </Button>
  );
};

export default PopularSearchButton;
