import { gql } from "@apollo/client";
import useDeferredQuery from "../../common/data-fetching/useDeferredQuery.js";

const QUERY_SCOPE = "candidate";
const QUERY_NAME = "candidateByRecruiterIdAndEmail";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($recruiterId: ID!, $email: String!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(recruiterId: $recruiterId, email: $email) {
        _id
      } 
    }
  }
`;

export default function useLazyQueryCandidateByRecruiterIdAndEmail() {
  return useDeferredQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {});
}
