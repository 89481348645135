import React from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationJobs from "../useTranslationJobs.js";
import JobWorkModeEnum from "../JobWorkModeEnum.js";
import {getJobWorkModeLabel} from "../JobWorkModeEnum.js";
import EnumFilter from "./EnumFilter.js";

export default function WorkModeFilter(props) {
  const { updateSelectedValue, selectedValue, name } = props;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading);

  // Retrieve translations for work mode options (anything before tLoading is ready won't be actually displayed)
  const jobWorkModeChoices = React.useMemo(() => {
      if (tLoading)
        return [];

      // Concatenate empty value with all choices from enum
      return ([{
        value: "",
        label: t("jobs:work_mode_all_label")
      }, ...Object.values(JobWorkModeEnum).map(workMode => ({
        value: workMode,
        label: getJobWorkModeLabel(t, workMode)
      }))]);
    },
    [t, tLoading]
  );

  return (
    <EnumFilter
      name={name}
      selectedValue={selectedValue}
      updateSelectedValue={updateSelectedValue}
      choices={jobWorkModeChoices}
      label={t("jobs:work_mode_filter_label")}
    />
  );
}
