// Don't forget to add localized labels for every option
const RecruiterSignUpReasonEnum = {
  HELP_SOMEONE: "HELP_SOMEONE",
  MAKE_MONEY: "MAKE_MONEY",
  SEEK_JOB: "SEEK_JOB",
  OTHER: "OTHER"
};

export default RecruiterSignUpReasonEnum;

/**
 * Return the translation for a given enum value.
 * @param t Translation function
 * @param code Work mode enum value
 * @returns {*} Translation string
 */
export function getSignUpReasonLabel(t, code) {
  // The method receives a t function and repeats "namespace:" in front of every translation key so that
  // the following keys are extracted when running scan-translations.js script (or "npm run scan-translations").
  // Without the t(), the keys wouldn't be recognized as translation keys.

  switch (code) {
    case RecruiterSignUpReasonEnum.HELP_SOMEONE:
      return t("profile:sign_up_reason_help_someone_label");
    case RecruiterSignUpReasonEnum.MAKE_MONEY:
      return t("profile:sign_up_reason_make_money_label");
    case RecruiterSignUpReasonEnum.SEEK_JOB:
      return t("profile:sign_up_reason_seek_job_label");
    case RecruiterSignUpReasonEnum.OTHER:
      return t("profile:sign_up_reason_other_label");
    default:
      return code;
  }
}
