export const SEO_CONFIG = {
  domain: "https://spotterful.com",
  siteName: "Spotterful",
  defaultTitle:
    "Spotterful: Everybody can become a recruiter and earn money",
  logo: "/logo.png",
  cover: "/cover.png",
  socialLinks: {
    facebookAppId: process.env.REACT_APP_FACEBOOK_APP_ID,
    defaultAuthorTwitterUserName: process.env.REACT_APP_TWITTER_USER_NAME,
  },
};
