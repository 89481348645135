class MathUtils {
  /**
   * Calculate the percentage of a number.
   * @param number Number that we want to calculate.
   * @param total Total number that we want to get the percent of.
   * @returns {Number}
   */
  static percentage(number, total) {
    return (number / total) * 100;
  }
}

export default MathUtils;
