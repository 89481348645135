import React from "react";
import Form from "react-bootstrap/Form";
import "./InlineEditWysiwyg.scss";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import InlineEditBase from "./InlineEditBase.js";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import withOnBlur from "react-onblur";
import useInlineEditInputEvents from "./useInlineEditInputEvents.js";
import ValidatedInputWysiwyg from "./ValidatedInputWysiwyg.js";
import { formatWysiwygInput, formatWysiwygOutput } from "./WysiwygEditor.js";
import { useValidatedInputForm } from "./useValidatedInputForm.js";
import InlineEditInputButtons from "./InlineEditInputButtons.js";
import "./InlineEditValidatedInputWysiwyg.scss";


/**
 * Div that displays the rendered html as read only until we click the Edit icon next to the note.
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */

export default function InlineEditValidatedInputWysiwyg(props) {
  return (
    <InlineEditBase
      EditComponent={WithOnBlurWysiwygEditComponent}
      formatValue={HtmlUtils.htmlToComponents}
      multilineView
      {...props}
    />
  );
}

// Add onBlur listeners on the whole edit component to detect when focus is not in any of its children anymore
const WithOnBlurWysiwygEditComponent = withOnBlur()(
  ValidatedInputWysiwygEditComponent
);

const validatedInputName = "validatedInput";

function ValidatedInputWysiwygEditComponent(props) {
  const {
    placeholder,
    value,
    onSubmit,
    onCancel,
    maxLength,
    required,
    setBlurListener,
    unsetBlurListener,
  } = props;

  const focusRef = React.useRef();

  const {
    control,
    formState: { isValid },
    watch,
  } = useValidatedInputForm();

  // Validation is performed by react-hook-form but useInlineEditInputEvents require a validation function to know
  // if the submit button can be enabled. Mock a validation function based on react-hook-form's decision.

  const validate = React.useCallback(() => {
    return isValid ? "" : "invalid";
  }, [isValid]);

  // We use currentValue only at initialization (it returns the formatted initial value).
  // After that, we call changeValue everytime the user types a letter so that the hook can update its submit and cancel
  // events, but even if currentValue changes as well, we don't feed it to ValidatedInputWysiwyg because it is managed
  // by react-hook-form inside ValidatedInputWysiwyg.

  const { currentValue, submit, cancel, changeValue } =
    useInlineEditInputEvents(
      value,
      onSubmit,
      validate,
      onCancel,
      setBlurListener,
      unsetBlurListener,
      focusRef,
      formatWysiwygInput,
      formatWysiwygOutput
    );

  const hasError = !isValid;

  // Submit button is clicked
  const onLocalSubmit = (event) => {
    event.preventDefault();
    submit();
  };

  // User makes one change in the wysiwyg content. Inform the useInlineEditInputEvents hook.
  const rawValue = watch(validatedInputName);
  React.useEffect(() => {
    changeValue(rawValue);
  }, [rawValue, changeValue]);

  // Cancel button is clicked
  const onClickCancel = (event) => {
    event.preventDefault();
    cancel();
  };

  return (
    <Form
      onSubmit={onLocalSubmit}
      className={"ValidatedInputWysiwygEditComponent"}
    >
      <Row className={"input-row"}>
        <Col className="input-col">
          <ValidatedInputWysiwyg
            name={validatedInputName}
            control={control}
            defaultValue={currentValue}
            editorRef={(ref) =>
              (focusRef.current = ref)
            } /* Get a ref to the editor so that we can focus on it */
            placeholder={placeholder}
            maxLength={maxLength}
            required={required}
          />
        </Col>
      </Row>
      <Row className={"button-row"}>
        <Col>
          <InlineEditInputButtons disabled={hasError} onClick={onClickCancel} />
        </Col>
      </Row>
    </Form>
  );
}
