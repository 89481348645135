import React from "react";
import Page from "../../common/page/Page.js";
import useTranslationDashboard from "../useTranslationDashboard.js";
import useAuthUser from "../../authentication-no-ui/useAuthUser.js";
import DashboardBlock from "./DashboardBlock.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized.js";

export default function DashboardPage(/*props*/) {
  const { t, loading: tLoading } = useTranslationDashboard();
  useShowLoader(tLoading, "DashboardPage");

  const { profile } = useAuthUser();

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="DashboardPage">
          <Page.Title
            text={t("dashboard:recruiters_dashboard_title")}
            loading={tLoading}
          />
          <Page.Content>
            <DashboardBlock recruiterId={profile._id} />
          </Page.Content>
        </Page>
      )}
    />
  );
}
