import {useState} from "react";

/**
 * Convenience hook to change a form state when one of its fields changes value.
 * @param initialState
 * @returns {unknown[]} Array of values (one per field), and a single callback to handle input onChange events.
 */
export function useFormFields(initialState) {
  const [fields, setValues] = useState(initialState);

  return [
    fields,
    function (event) {
      setValues(prevFields => ({
        // Copy all field values from previous state
        ...prevFields,
        // Override the value that just changed
        [event.target.id]: event.target.value
      }));
    }
  ];
}

