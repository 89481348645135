import useQueryInsightTopCompanies from "./useQueryInsightTopCompanies.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import StringUtils from "../../../utils/StringUtils.js";
import Loader from "../../common/loading-widgets/Loader.js";
import SkeletonList from "./SkeletonList.js";

const CompaniesCard = (props) => {
  const { keyword, language, title } = props;

  const {
    loading,
    errors,
    data: companies,
  } = useQueryInsightTopCompanies(keyword, 10, language);

  return (
    <div className="widget-block">
      <Loader icon={<SkeletonList />}>
        <div className="card-title">{title}</div>
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            <ol>
              {companies.map((company, index) => (
                <li key={index}>
                  {StringUtils.capitalizeFirstWords(company._id)}
                </li>
              ))}
            </ol>
          )}
        />
      </Loader>
    </div>
  );
};

export default CompaniesCard;
