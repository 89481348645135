import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonList = () => {
  return (
    <>
      <Skeleton width={`40%`} />
      <Skeleton count={10} width={`100%`} />
      <Skeleton width={`90%`} />
      <Skeleton width={`20%`} />
    </>
  );
};

export default SkeletonList;
