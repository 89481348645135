import React, {useCallback, useMemo} from "react";
import {useApolloClient} from "@apollo/client";

/**
 * Provides a callback to clear Apollo cached queries.
 */
export default function useClearApolloStore() {

  const apolloClient = useApolloClient();
  const [loading, setLoading] = React.useState(false);

  const clearApolloStore = useCallback(() => {
    if (apolloClient) {
      setLoading(true);
      return apolloClient.resetStore().finally(() => setLoading(false));
    } else {
      return Promise.resolve();
    }
  }, [apolloClient]);

  return useMemo(() => ({
    clearApolloStore,
    loading
  }), [clearApolloStore, loading]);
}
