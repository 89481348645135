import React from "react";
import "./ActionDiv.scss";

/**
 * Common behaviour and styling for clickable regions (other than ActionButton and ActionIcon components).
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ActionDiv(props) {

  const {className, children, ...otherProps} = props;
  const newClassName = ["ActionDiv", className].join(" ");
  const newChildren = children ? children : null;

  return (
    <div className={newClassName} {...otherProps}>{newChildren}</div>
  )
};
