// The purpose of this page is to have an URL where we can test the behavior of the application when an
// unexpected front-end error occurs
export default function ErrorTestPage() {

  // Generate an error at runtime for testing purposes
  const a = 2;
  a.doSomething();

  return null;

}
