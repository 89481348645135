import React from "react";
import useTranslationSponsorship from "../useTranslationSponsorship.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import DateUtils from "../../../utils/DateUtils.js";
import StringUtils from "../../../utils/StringUtils.js";

/**
 * Display "Sponsored since today", "Sponsored since yesterday", "Sponsored since X days"
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function DisplaySinceDate(props) {
  const { date } = props;
  const { t, loading: tLoading } = useTranslationSponsorship();
  const dateCalculator = DateUtils.dateDiff(Date.parse(date));
  useShowLoader(tLoading);

  return (
    <>
      {dateCalculator === 0 ? (
        <span>{t("sponsorship:sponsored_details_since_today")}</span>
      ) : (
        <span>
          {t("sponsorship:sponsored_details_since_days", {
            count: StringUtils.nullToZero(dateCalculator),
          })}
        </span>
      )}
    </>
  );
}
