import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./DashboardApplicationByStatusCount.scss";

export default function DashboardApplicationByStatusCount(props) {
  const { label, count } = props;

  return (
    <div className="DashboardApplicationByStatusCount">
      <Row>
        <Col className="count-label">{label}</Col>
        <Col className="count-col">{count}</Col>
      </Row>
    </div>
  );
}
