import React from 'react';
import './scss/globals.scss';
import App from './components/app/App.js';
import { createRoot } from "react-dom/client";

const root = createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);
