import React from "react";
import "./HeaderNavbar.scss";
import Navbar from "react-bootstrap/Navbar";
import Container from "react-bootstrap/Container";

export default function HeaderNavbar(props) {

  const {logo, menu} = props;

  return (
    // collapseOnSelect will work only if Navbar contains a Nav item with Nav.Link children.
    // The Nav.Link children must be known when the Navbar is constructed, they cannot be injected through a ref afterwards.
    <Navbar fixed="top" expand="lg" collapseOnSelect className={"HeaderNavbar"}>
      <Container
        className="container" /*Remove Container to spread menu items over all screen width*/
      >
        <Navbar.Brand>
          {logo}
        </Navbar.Brand>
        <Navbar.Toggle className="header-toggle" aria-controls="main-navbar-nav" />
        <Navbar.Collapse id="main-navbar-nav" className={"header-collapse"}>
          {menu}
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}
