import React from "react";
import Page from "../../common/page/Page.js";
import { useParams } from "react-router";
import useQueryJobById from "./useQueryJobById.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import JobBlock from "./JobBlock.js";
import JobNotFoundBlock from "./JobNotFoundBlock.js";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import JopOpeningSeo from "../../common/seo/JopOpeningSeo.js";
import * as ROUTES from "../../../constants/routes.js";
import RouterUtils from "../../../utils/RouterUtils.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";

export default function JobPage(props) {
  // Fetch job from ID provided in the url params.
  const urlParams = useParams();
  const jobId = urlParams.jobId;

  const domainName = window.location.hostname;
  const url =
    domainName +
    RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_JOB, {
      jobId,
    });

  const {
    data: job,
    loading: jobLoading,
    errors: jobErrors,
  } = useQueryJobById(jobId);

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobPage");

  return (
    <WaitForData
      loading={jobLoading}
      errors={jobErrors}
      onLoaded={() => (
        <>
          {job && (
            <>
              <JopOpeningSeo
                title={job.title}
                description={HtmlUtils.removeHtml(job.description)}
                datePosted={job.datePosted}
                employmentType={job.employmentType}
                company={job.organization.company}
                jobCity={job.city}
                jobState={job.state}
                jobCountry={job.country}
                url={url}
                companyUrl={job.organization.companyUrl}
              />
              <Page className="JobPage">
                <Page.Title loading={jobLoading} text={job.title} />
                <Page.Content className="spaced-children">
                  <JobBlock job={job} url={url} />
                </Page.Content>
              </Page>
            </>
          )}
          {!job && (
            <Page className="JobPage">
              <Page.Title
                loading={jobLoading}
                text={t("jobs:job_details_not_found_title")}
              />
              <Page.Content>
                <JobNotFoundBlock />
              </Page.Content>
            </Page>
          )}
        </>
      )}
    />
  );
}
