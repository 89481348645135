import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import "./DisconnectedModal.scss";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi.js";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";

export default function DisconnectedModal(props) {

  const {t, loading: tLoading} = useTranslationAuthenticationUi();
  useShowLoader(tLoading, "DisconnectedModal");

  // RENDER

  return (
    <Modal show={props.show} onHide={props.handleClose} className="DisconnectedModal">
      <Modal.Body className={"spaced-children"}>
        <div>{t("authentication-ui:disconnected_msg")}</div>
        <div className={"button-row"}>
          <Button variant="primary" onClick={props.handleClose}>
            {t("authentication-ui:close_button")}
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  );
}
