import React from "react";
import useTranslationDashboard from "../useTranslationDashboard.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useQueryApplicationByRecruiterId from "./useQueryApplicationByRecruiterId.js";
import LastViewedList from "../../common/components/LastViewedList.js";
import ApplicationsSearchResult from "../search/ApplicationSearchResult.js";

export default function ApplicationsList(props) {
  const { recruiterId } = props;

  const { data, loading, errors } =
    useQueryApplicationByRecruiterId(recruiterId);

  const { t, loading: tLoading } = useTranslationDashboard();
  useShowLoader(tLoading, "ApplicationsList");

  const emptyMsg = t("dashboard:recruiters_dashboard_empty_candidate_list_msg");
  const columnsLabels = [
    t("dashboard:recruiters_dashboard_page_job_title_column_label"),
    t("dashboard:recruiters_dashboard_page_name_column_label"),
    t("dashboard:recruiters_dashboard_page_status_column_label"),
    t("dashboard:recruiters_dashboard_page_date_column_label"),
  ];
  const listTitle = t("dashboard:recruiters_dashboard_applications_list_label");
  const getItemId = (application) => application._id;

  return (
    <LastViewedList
      items={data}
      listTitle={listTitle}
      loading={loading}
      errors={errors}
      columnsLabels={columnsLabels}
      className={"dashboardActiveJobsList"}
      emptyMsg={emptyMsg}
      getItemId={getItemId}
      SearchResult={<ApplicationsSearchResult />}
    />
  );
}
