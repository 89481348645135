import React from "react";
import Button from "react-bootstrap/Button";
import ActionLoadingComponent from "./ActionLoadingComponent.js";

/**
 * Bootstrap Button, wrapped around a component that manages the loading state.
 * Accepts all Button props, plus a boolean "loading".
 * @param props
 * @returns {JSX.Element}
 * @constructor
 */
export default function ActionButton(props) {

  const {loading, children, className, disabled, ...otherProps} = props;

  const newClassName = ["ActionButton", className].join(" ");

  return (
    <Button {...otherProps} className={newClassName} disabled={loading || disabled}>
      <ActionLoadingComponent loading={loading}>{children}</ActionLoadingComponent>
    </Button>
  );
};
