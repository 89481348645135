import React, {useCallback, useMemo} from "react";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import ActionLink from "../../common/widgets/ActionLink.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import StringUtils from "../../../utils/StringUtils.js";
import useErrorModal from "../../common/modals/useErrorModal.js";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import useCandidateSaveInformationForm from "./useCandidateSaveInformationForm.js";
import useCandidateSaveResumeForm from "./useCandidateSaveResumeForm.js";
import useCandidateSaveRewardForm from "./useCandidateSaveRewardForm.js";
import useCandidateSaveApplicationForm from "./useCandidateSaveApplicationForm.js";
import * as ROUTES from "../../../constants/routes.js";
import useSuccessModal from "../../common/modals/useSuccessModal.js";
import RouterUtils from "../../../utils/RouterUtils.js";
import "./CandidateSaveForm.scss";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";
import useCandidateSaveSelectionForm from "./useCandidateSaveSelectionForm.js";
import useQueryCandidateById from "../view/useQueryCandidateById.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";

export const STEP_ENUM = {
  CANDIDATE_SELECTION: "CANDIDATE_SELECTION",
  CANDIDATE_INFORMATION: "CANDIDATE_INFORMATION",
  RESUME: "RESUME",
  REWARD: "REWARD",
  NOTES: "NOTES",
};

export default function CandidateSaveForm({job, candidateId, recruiterId, step, onChangeStep, email}) {

  const { t, loading } = useTranslationCandidates();
  useShowLoader(loading);

  const jobId = job ? job._id : undefined;

  const {redirectTo} = useReferrer();

  const stepOrder = useMemo(() => ({
    [STEP_ENUM.CANDIDATE_SELECTION]: true,
    [STEP_ENUM.CANDIDATE_INFORMATION]: true,
    [STEP_ENUM.RESUME]: true,
    [STEP_ENUM.REWARD]: !StringUtils.isNullOrEmpty(jobId),
    [STEP_ENUM.NOTES]: !StringUtils.isNullOrEmpty(jobId)
  }), [jobId]);

  const visibleStepNames = React.useMemo(() =>
      Object.keys(stepOrder).filter(stepName => stepOrder[stepName]),
    [stepOrder]);

  // Find the visible step by name and return its index. If step is not visible, go backward to find the closest visible one.
  const getClosestVisibleStepIndexByName = React.useCallback(name => {
    // Move backward from position (including current) until step is visible
    let index = Object.keys(stepOrder).indexOf(name);

    while (index > -1 && !Object.values(stepOrder)[index]) {
      index--;
    }

    const closestVisibleName = Object.keys(stepOrder)[index];
    return visibleStepNames.indexOf(closestVisibleName);
  }, [stepOrder, visibleStepNames]);

  // Find target step index from step name provided in props. If step is not visible, go backward to find the closest
  // visible step. If none found, use step 0.
  const currentStepIndex = React.useMemo(() => Math.max(
    0, getClosestVisibleStepIndexByName(step)
  ), [getClosestVisibleStepIndexByName, step]);

  // Candidate and recommendation are not modified using this multistep form, so we don't have to persist the last saved step
  // with the candidate or recommendation in the DB because it is used only at creation time
  const [lastSavedStepIndex, setLastSavedStepIndex] = React.useState(currentStepIndex - 1);
  const [lastVisitedStepIndex, setLastVisitedStepIndex] = React.useState(Math.max(currentStepIndex, lastSavedStepIndex));

  React.useEffect(() => {
    if (lastVisitedStepIndex < currentStepIndex)
      setLastVisitedStepIndex(currentStepIndex);
  }, [currentStepIndex, lastVisitedStepIndex]);

  // When picking an existing candidate in CandidateSaveInformationForm, reload the step so that the candidateId is recorded.
  const onChangeCandidate = useCallback((candidateId) => {
    onChangeStep(step, candidateId);
  }, [step, onChangeStep]);

  // Load candidate if candidateId is provided, otherwise build a fake one
  const {data: existingCandidate, loading: candidateLoading, errors: candidateErrors} = useQueryCandidateById(candidateId);
  const newCandidate = useMemo(() => ({
    email,
    recruiterId
  }), [email, recruiterId]);

  const candidate = existingCandidate || newCandidate;

  useShowLoader(candidateLoading);

  const candidateSaveSelectionForm = useCandidateSaveSelectionForm(candidate, recruiterId, jobId, onChangeCandidate);
  const candidateSaveInformationForm = useCandidateSaveInformationForm(candidate, recruiterId, jobId);
  const candidateSaveResumeForm = useCandidateSaveResumeForm(candidate);
  const candidateSaveRewardForm = useCandidateSaveRewardForm(candidate, job);
  const candidateSaveApplicationForm = useCandidateSaveApplicationForm(candidate, recruiterId, job);

  // Define all the steps of the form
  const stepDefinitions = useMemo(() => ({
    [STEP_ENUM.CANDIDATE_SELECTION]: {
      label: t("candidates:candidates_save_candidate_selection_step_label"),
      ...candidateSaveSelectionForm,
    },
    [STEP_ENUM.CANDIDATE_INFORMATION]: {
      label: t("candidates:candidates_save_candidate_information_step_label"),
      ...candidateSaveInformationForm,
    },
    [STEP_ENUM.RESUME]: {
      label: t("candidates:candidates_save_candidate_resume_step_label"),
      ...candidateSaveResumeForm,
    },
    [STEP_ENUM.REWARD]: {
      label: t("candidates:candidates_save_candidate_reward_step_label"),
      ...candidateSaveRewardForm,
    },
    [STEP_ENUM.NOTES]: {
      label: t("candidates:candidates_save_candidate_note_step_label"),
      ...candidateSaveApplicationForm,
    }
  }),[t, candidateSaveSelectionForm, candidateSaveInformationForm, candidateSaveResumeForm, candidateSaveRewardForm, candidateSaveApplicationForm]);

  const currentStepName = visibleStepNames[currentStepIndex];
  const currentStepDefinition = stepDefinitions[currentStepName];
  const CurrentForm = currentStepDefinition.form;

  // Form is not ready for display until the form content is ready
  const ready = currentStepDefinition.ready;

  // It is an error to display a step after candidate creation if there is no existing candidate
  const candidateNotFound =
    !candidate._id && !candidateLoading && currentStepIndex > getClosestVisibleStepIndexByName(STEP_ENUM.RESUME) ? (
      <p>{t("candidates:candidates_save_no_candidate")}</p>
    ) : null;

  // Return error message
  const getErrorMessage = useCallback((code) => {
    switch (code) {
      case "DuplicatedCandidateError":
        return t(
          "candidates:candidates_server_duplicated_candidate_error_message"
        );
      case "MultipleFilesSelectedError":
        return t(
          "candidates:candidates_resume_multiple_files_error"
        );
      case "MaxFileSizeError":
        return t(
          "candidates:candidates_resume_file_size_error"
        );
      case "FileUploadError":
        return t(
          "candidates:candidates_details_file_upload_error"
        );
      case "UnsupportedMediaTypeError":
        return t(
          "candidates:candidates_unsupported_file_type_error"
        );
      case "MissingQualificationError":
        return t(
          "candidates:candidates_save_application_qualifications_error"
        );
      case "CandidateLockupPeriodError":
        return t(
          "candidates:candidates_server_candidate_lockup_period_error_message"
        );
      case "CandidateMaxActiveApplicationsError":
        return t(
          "candidates:candidates_server_candidate_max_active_applications_error_message"
        );
      case "CandidateCannotBeRecommendedError":
        return t(
          "candidates:candidates_server_candidate_cannot_be_recommended_error_message"
        );
      case "CandidateAlreadyRecommendedError":
        return t(
          "candidates:candidates_server_candidate_already_recommended_error_message"
        );
      case "RecruiterMaxRecommendationsError":
        return t(
          "candidates:candidates_server_recruiter_max_recommendations_error_message"
        );
      default:
        return t("candidates:candidates_server_error_message");
    }
  }, [t]);

  const serverErrorMessage = currentStepDefinition.errors
    ? getErrorMessage(currentStepDefinition.errors[0].name)
    : candidateErrors;

  const submitError = useServerErrorFormatter(
    currentStepDefinition.errors,
    serverErrorMessage,
    false
  );

  const error = candidateNotFound || submitError;
  const { ErrorModal, show: showErrorModal } = useErrorModal(<>{error}</>, undefined);

  const onRecommendSuccessShowModalAbort = useCallback(() => {
    // Redirect to CANDIDATES when done
    if (jobId) {
      return redirectTo(
        RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_CANDIDATE_PROFILE, {
          candidateId: candidate._id,
        })
      );
    } else {
      return redirectTo(ROUTES.RECRUITER_JOBS);
    }
  }, [jobId, redirectTo, candidate._id]);

  // Modal to show a success message after the application is sent or candidate is added
  const recommendSuccessMessage = jobId ? (
    <div>{t("candidates:candidates_application_approval_message")}</div>
  ) : (
    <div>{t("candidates:candidates_add_candidate_success_message")}</div>
  );

  const {
    SuccessModal: RecommendSuccessShowModal,
    show: showRecommendSuccessShowModal,
  } = useSuccessModal(
    recommendSuccessMessage,
    onRecommendSuccessShowModalAbort
  );

  // Get name of previous visible step (excluding current position)
  const prevStepName = currentStepIndex < 0 ? "" : visibleStepNames[currentStepIndex - 1];

  // Get name of next visible step (excluding current position)
  const nextStepName = currentStepIndex > visibleStepNames.length - 2 ? "" : visibleStepNames[currentStepIndex + 1];

  const onClickPrevStep = useCallback(event => {
    event.preventDefault();
    onChangeStep(prevStepName, candidate._id, candidate.email);
  }, [onChangeStep, prevStepName, candidate]);

  const onClickNextStep = useCallback(event => {
    event.preventDefault();
    currentStepDefinition
      .submit()
      .then((candidate) => {
        if (lastSavedStepIndex < currentStepIndex)
          setLastSavedStepIndex(currentStepIndex);

        return onChangeStep(nextStepName, candidate._id, candidate.email)
      })
      .catch(() => {
        // Error display is managed by an effect so that we can handle errors in resume upload as well
      });
  }, [currentStepDefinition, lastSavedStepIndex, currentStepIndex, onChangeStep, nextStepName]);

  const onClickLastStep = useCallback(event => {
    event.preventDefault();
    currentStepDefinition
      .submit()
      .then(() => {
        showRecommendSuccessShowModal();
      })
      .catch(() => {
        // Error display is managed by an effect so that we can handle errors in resume upload as well
      });
  }, [currentStepDefinition, showRecommendSuccessShowModal]);

  // Display an error modal as soon as an error is returned by any step, at any time
  React.useEffect(() => {
    if (error)
      showErrorModal();
  }, [error, showErrorModal]);

  // Label of the last action
  const saveLastLabel = StringUtils.isNullOrEmpty(jobId)
    ? t("candidates:candidates_create_candidate_save_last_action")
    : t("candidates:candidates_save_last_action");

  const submitLabel = StringUtils.isNullOrEmpty(nextStepName) ? saveLastLabel : t("candidates:candidates_save_next_action");

  const onSubmit = StringUtils.isNullOrEmpty(nextStepName) ? onClickLastStep : onClickNextStep;

  return (
    <>
      {ErrorModal}
      {RecommendSuccessShowModal}
      <form onSubmit={ (event) => onSubmit(event)} className="CandidateSaveForm form-with-rows">
        <Row>
          <div className="step-div">
            <Col className="step-wizard">
              <ul className={"step-wizard-list"}>
                {visibleStepNames.map((stepName, stepIndex) => (
                  <li key={stepName} className={"step-wizard-item"}>
                    <StepIndicator
                      stepName={stepName}
                      stepIndex={stepIndex}
                      stepDefinition={stepDefinitions[stepName]}
                      isCurrent={stepIndex === currentStepIndex}
                      isSaved={stepIndex <= lastSavedStepIndex}
                      isVisited={stepIndex <= lastSavedStepIndex + 1}
                      onChangeStep={(step) => onChangeStep(step, candidate._id, candidate.email)}
                    />
                  </li>
                ))}
              </ul>
            </Col>
          </div>
        </Row>
        <Row>
          <Col className={StringUtils.nullToEmpty(currentStepDefinition.className)}>
            {/* Wait for candidate to be loaded it is exists, to avoid rendering form with temporary candidate
            if it is to be replaced shortly after */}
            <WaitForData loading={candidateLoading || !ready} errors={candidateErrors} onLoaded={() =>
              <CurrentForm {...currentStepDefinition.formProps} loading={!ready}/>
            }/>
          </Col>
        </Row>
        <Row>
          <Col className={"form-actions"}>
            {!StringUtils.isNullOrEmpty(prevStepName) && (
              <ActionButton onClick={onClickPrevStep}>
                {t("candidates:candidates_save_previous_action")}
              </ActionButton>
            )}
            <ActionButton
              type="submit"
              loading={currentStepDefinition.submitting}
              disabled={!currentStepDefinition.canSubmit() || !ready}
            >
              {submitLabel}
            </ActionButton>
          </Col>
        </Row>
      </form>
    </>
  );
}

// Make a link to reach a step directly without using Previous and Next buttons
function StepIndicator({stepName, stepIndex, stepDefinition, isCurrent, isSaved, isVisited, onChangeStep}) {

  const stepLabel = stepDefinition.label;
  const progressCount = isSaved ? <span className="progress-count"/> : <span className="progress-count-number">{stepIndex + 1}</span>;

  // Tell the parent that the step has changed, in case it wants to change the url
  const onClickStep = React.useCallback((event, stepName) => {
    event.preventDefault();
    onChangeStep(stepName);
  }, [onChangeStep]);

  if (isCurrent) {
    // Current step is highlighted and not clickable
    return (
      <>
        {progressCount}
        <span className={"current-step-link progress-label"}>
            {stepLabel}
          </span>
      </>
    );
  } else if (!isVisited) {
    // The steps not visited yet do not have a link
    return (
      <>
        {progressCount}
        <span className="progress-label">{stepLabel}</span>
      </>
    );
  } else {
    // Steps previously visited have a link
    return (
      <>
        {progressCount}
        <span className="progress-span">
            <ActionLink
              className="progress-action"
              onClick={(event) => onClickStep(event, stepName)}
            >
              {stepLabel}
            </ActionLink>
          </span>
      </>
    );
  }
}
