import {useCallback, useEffect, useMemo, useState} from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm.js";
import StringUtils from "../../../utils/StringUtils.js";
import CandidateSaveSelectionForm from "./CandidateSaveSelectionForm.js";
import useLazyQueryCandidateByRecruiterIdAndEmail from "./useLazyQueryCandidateByRecruiterIdAndEmail.js";
import useLazyQueryApplicationValidate from "./useLazyQueryApplicationValidate.js";
import ServerError from "../../common/data-fetching/ServerError.js";

export default function useCandidateSaveSelectionForm(
  candidate,
  recruiterId,
  jobId,
  onSelectCandidate
) {

  const { t, loading: tLoading } = useTranslationCandidates();

  useShowLoader(tLoading);

  const { fetch: fetchCandidateByRecruiterIdAndEmail } = useLazyQueryCandidateByRecruiterIdAndEmail();
  const { fetch: fetchApplicationValidate } = useLazyQueryApplicationValidate();

  const [submitting, setSubmitting] = useState(false);
  const [submitErrors, setSubmitErrors] = useState(null);

  const {
    control,
    formState: { isValid },
    getValues,
    setValue
  } = useValidatedInputForm();

  // When selecting a candidate, fill form
  useEffect(() => {
    if (candidate) {
      const updateOptions = {shouldValidate: true, shouldDirty: true, shouldTouch: true};
      setValue("email", StringUtils.nullToEmpty(candidate.email), updateOptions);
    }
  }, [candidate, setValue]);

  const canSubmit = useCallback(() => {
    return isValid;
  }, [isValid]);

  const submit = useCallback(() => {

    const fields = getValues();
    const email = fields.email.trim();

    setSubmitErrors(null);
    setSubmitting(true);

    // Fetch candidate with the email entered, to see if it exists
    return fetchCandidateByRecruiterIdAndEmail({recruiterId, email})
      .then((replacementCandidate) => {

        const newCandidate = {email};

        if (jobId) {
          // We are recommending a candidate; use found candidate, or create a new one
          const updatedCandidate = replacementCandidate || newCandidate;

          // If we're recommending an existing candidate, check if we can recommend it again
          if (jobId && updatedCandidate._id) {
            return fetchApplicationValidate({candidateId: updatedCandidate._id, jobId, recruiterId})
              .then(() => {
                // Candidate is valid, so keep it; if not valid, parent's promise will catch validation error
                return Promise.resolve(updatedCandidate);
              });
          } else {
            // New candidate (or not in a recommendation process), just return the candidate to the next step
            return Promise.resolve(updatedCandidate);
          }
        } else {
          if (replacementCandidate) {
            // We are creating a candidate; it must not exist
            const errors = [new ServerError("DuplicatedCandidateError")];
            return Promise.reject(errors);
          } else {
            return Promise.resolve(newCandidate);
          }
        }
      }).catch(errors => {
        setSubmitErrors(errors);
        return Promise.reject(errors);
      }).finally(() => {
        setSubmitting(false);
      });
  }, [getValues, fetchCandidateByRecruiterIdAndEmail, jobId, fetchApplicationValidate, recruiterId]);

  const formProps = useMemo(() => ({
    t,
    control,
    candidate,
    jobId,
    onSelectCandidate,
    recruiterId
  }), [t, control, candidate, jobId, onSelectCandidate, recruiterId]);

  return {
    form: CandidateSaveSelectionForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting,
    errors: submitErrors
  };
}
