import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";

const SkeletonBarChart = () => {
  const percentages = [
    100, 75, 65, 60, 55, 50, 45, 40, 35, 33, 30, 28, 25, 23, 20, 15,
  ];

  return (
    <>
      {percentages.map((percentage, index) => (
        <Skeleton key={index} width={`${percentage}%`} />
      ))}
    </>
  );
};

export default SkeletonBarChart;
