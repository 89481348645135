import { Row, Col } from "react-bootstrap";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import noResultImg from "../../../img/no-result.svg";
import { Image } from "react-bootstrap";
import "./JobNotFoundBlock.scss";

const JobNotFoundBlock = () => {
  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobNotFoundBlock");

  return (
    <div className="JobNotFoundBlock">
      <Row>
        <Col>
          <Image src={noResultImg} />
        </Col>
      </Row>
      <Row>
        <Col className="job-not-found-label">
          {t("jobs:job_details_not_found")}
        </Col>
      </Row>
    </div>
  );
};

export default JobNotFoundBlock;
