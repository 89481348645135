import React from "react";
import Page from "../../common/page/Page.js";
import useTranslationEmailSubscription from "../useTranslationEmailSubscription.js";
import CandidateUnsubscriptionBlock from "./CandidateUnsubscriptionBlock.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import { useParams } from "react-router";

export default function CandidateUnsubscriptionPage(/*props*/) {
  // Fetch the email provided in the url params.
  const urlParams = useParams();
  const email = urlParams.email;

  const { t, loading: tLoading } = useTranslationEmailSubscription();
  useShowLoader(tLoading, "CandidateUnsubscriptionPage");

  return (
    <Page className="CandidateUnsubscriptionPage">
      <Page.Title
        text={t("email-subscription:email_unsubscription_candidate_title")}
        loading={tLoading}
      />
      <Page.Content>
        <CandidateUnsubscriptionBlock email={email} />
      </Page.Content>
    </Page>
  );
}
