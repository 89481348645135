import React from "react";
import { Row, Col } from "react-bootstrap";
import Page from "../common/page/Page.js";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi.js";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";
import ConnectionMode from "./ConnectionMode.js";

export default function ConnectionModePage() {
  const { t, loading: tLoading } = useTranslationAuthenticationUi();
  useShowLoader(tLoading, "ConnectionMode");
  if (tLoading) return null;

  return (
    <div className="ConnectionModePage">
      <Page>
        <Page.Title
          text={t("authentication-ui:connection_mode")}
          loading={tLoading}
        />
        <Page.Content>
          <Row>
            <Col>
              <ConnectionMode />
            </Col>
          </Row>
        </Page.Content>
      </Page>
    </div>
  );
}
