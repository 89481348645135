import React from "react";
import TranslationContext from "./TranslationContext.js";

/**
 * Holds a reference to the translation context
 * @return {null}
 */
export default function useTranslationContext() {
  return React.useContext(TranslationContext);
}
