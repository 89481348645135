import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes, useLocation,
} from "react-router-dom";
import * as ROUTES from "../../constants/routes.js";
import AuthUserProvider from "../authentication-no-ui/AuthUserProvider.js";
import FirebaseProvider from "../infra-no-ui/firebase/FirebaseProvider.js";
import BasePage from "../common/page/BasePage.js";
import HeaderPortal from "../common/page/HeaderPortal.js";
import FooterPortal from "../common/page/FooterPortal.js";
import TranslationProvider from "../infra-no-ui/translation/TranslationProvider.js";
import FirebaseAuthProvider from "../authentication-no-ui/FirebaseAuthProvider.js";
import NotFoundPage from "./NotFoundPage.js";
import AuthenticatedApolloProvider from "../authentication-no-ui/AuthenticatedApolloProvider.js";
import MissingProfileInterceptor from "../authentication-ui/MissingProfileInterceptor.js";
import DisconnectedModal from "../authentication-ui/DisconnectedModal.js";
import ResetPasswordPage from "../authentication-ui/ResetPasswordPage.js";
import FirebaseErrorPage from "../authentication-ui/FirebaseErrorPage.js";
import DatePickerLocaleProvider from "./DatePickerLocalizationProvider.js";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";
import Loader from "../common/loading-widgets/Loader.js";
import Menu from "../menu/Menu.js";
import Footer from "../footer/Footer.js";
import SignOutPage from "../authentication-ui/SignOutPage.js";
import SignUpPage from "../authentication-ui/SignUpPage.js";
import SignInPage from "../authentication-ui/SignInPage.js";
import DashboardPage from "../dashboard/view/DashboardPage.js";
import JobsPage from "../jobs/view/JobsPage.js";
import JobPage from "../jobs/view/JobPage.js";
import CandidateRecommendPage from "../candidates/mutate/CandidateRecommendPage.js";
import CandidateProfilePage from "../candidates/view/CandidateProfilePage.js";
import CandidatesPage from "../candidates/view/CandidatesPage.js";
import ProfilePage from "../profile/view/ProfilePage.js";
import LandingPage from "../landing/view/LandingPage.js";
import { apolloCacheManager } from "./apolloCacheManager.js";
import useQueryRecruiterByEmail from "../profile/view/useQueryRecruiterByEmail.js";
import CandidateApprovedApplicationPage from "../jobs/view/CandidateApprovedApplicationPage.js";
import CandidateRejectedApplicationPage from "../jobs/view/CandidateRejectedApplicationPage.js";
import ContactUsPage from "../footer/ContactUsPage.js";
import PrivacyPage from "../footer/PrivacyPage.js";
import CareersPage from "../footer/CareersPage.js";
import TermsPage from "../footer/TermsPage.js";
import EmployerLandingPage from "../employer-landing/view/EmployerLandingPage.js";
import AboutUsPage from "../footer/AboutUsPage.js";
import PricingPage from "../footer/PricingPage.js";
import CandidateUnsubscriptionPage from "../email-subscription/view/CandidateUnsubscriptionPage.js";
import SpotterUnsubscriptionPage from "../email-subscription/view/SpotterUnsubscriptionPage.js";
import BlogPage from "../blog/view/BlogPage.js";
import BlogCategoryPage from "../blog/view/BlogCategoryPage.js";
import BlogPostPage from "../blog/view/BlogPostPage.js";
import MenuLogo from "../menu/MenuLogo.js";
import EmployerMenuLogo from "../menu/EmployerMenuLogo.js";
import EmployerMenu from "../menu/EmployerMenu.js";
import HeaderNavbar from "../menu/HeaderNavbar.js";
import InsightsSearchPage from "../../components/insights/view/InsightsSearchPage.js";
import InsightsResultPage from "../../components/insights/view/InsightsResultPage.js";
import InsightsComparisonResultPage from "../../components/insights/view/InsightsComparisonResultPage.js";
import TranslatedContentError from "../common/error-boundaries/TranslatedContentError.js";
import ErrorBoundary from "../common/error-boundaries/ErrorBoundary.js";
import DefaultLanguageSwitcher from "../common/page/DefaultLanguageSwitcher.js";
import ConnectionModePage from "../authentication-ui/ConnectionModePage.js";
import ConnectionModeSignUpPage from "../authentication-ui/ConnectionModeSignUpPage.js";
import ApolloErrorLogger from "../common/error-boundaries/ApolloErrorLogger.js";
import ErrorTestPage from "./ErrorTestPage.js";
import EmployerPage from "../employers/view/EmployerPage.js";
import HowItWorksPage from "../landing/view/HowItWorksPage.js";
import SponsorCodeProvider from "../sponsorship/SponsorCodeProvider.js";
import SponsorshipPage from "../sponsorship/view/SponsorshipPage.js";
import SponsoredListPage from "../sponsorship/view/SponsoredListPage.js";
import SponsoredInvitePage from "../sponsorship/view/SponsoredInvitePage.js";
import EmployeeReferralLandingPage from "../employer-landing/view/EmployeeReferralLandingPage.js";
import CandidateCreatePage from "../candidates/mutate/CandidateCreatePage.js";

export default function AppRouter() {
  return <Router><AppRouterContent/></Router>
}

function AppRouterContent() {
  const location = useLocation();

  // On the employer landing page, display Spotterful Employer logo.  On any other page, display Spotterful regular logo.
  const menuLogo = location.pathname === ROUTES.RECRUITER_EMPLOYER_LANDING ? <EmployerMenuLogo/> : <MenuLogo/>;

  // On the employer landing page, display Spotterful Employer menu. On any other page, display Spotterful regular menu.
  const menu = location.pathname === ROUTES.RECRUITER_EMPLOYER_LANDING ? <EmployerMenu/> : <Menu/>;

  // <Router> must come before BasePage, because BasePage ultimately renders links
  // Display BasePage before everything else, so that we can use it to display translation and authentication errors.
  // BasePage will use portals to display menu and footer, which depend on translation and authentication that are not yet ready
  return (
      <BasePage header={<HeaderNavbar logo={menuLogo} />}>
        <Loader>
          <TranslationProvider useShowLoader={useShowLoader}>
            <ErrorBoundary component={<TranslatedContentError />}>
              <DatePickerLocaleProvider>
                <FirebaseProvider errorComponent={FirebaseErrorPage}>
                  <FirebaseAuthProvider disconnectedModal={DisconnectedModal}>
                    <AuthenticatedApolloProvider
                      cacheManager={apolloCacheManager()}
                    >
                      {/* At this point we can log frontend errors using Apollo because it is now available */}
                      <ErrorBoundary
                        component={<TranslatedContentError />}
                        logger={ApolloErrorLogger}
                      >
                        <AuthUserProvider
                          useQueryProfile={useQueryRecruiterByEmail}
                        >
                          <SponsorCodeProvider>
                            <DefaultLanguageSwitcher>
                              <HeaderPortal
                                component={
                                  <HeaderNavbar logo={menuLogo} menu={menu} />
                                }
                              />
                              <Routes>
                                {/* Put that outside MissingProfileInterceptor because missing profile must not prevent from signing out */}
                                <Route
                                  path={ROUTES.SIGN_OUT}
                                  element={<SignOutPage/>}
                                />
                                {/* Don't go deeper if user is authenticated but has no profile */}
                                <Route element={<MissingProfileInterceptor/>}>
                                  {/* Try to keep declarations here and in routes.js in the same order, for maintainability */}
                                  <Route
                                    path={ROUTES.CONNECTION_MODE}
                                    element={<ConnectionModePage/>}
                                  />
                                  <Route
                                    path={ROUTES.CONNECTION_MODE_SIGN_UP}
                                    element={<ConnectionModeSignUpPage/>}
                                  />
                                  <Route
                                    path={ROUTES.SIGN_IN}
                                    element={<SignInPage/>}
                                  />
                                  <Route
                                    path={ROUTES.SIGN_UP}
                                    element={<SignUpPage/>}
                                  />
                                  <Route
                                    path={ROUTES.HOME}
                                    element={<LandingPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_DASHBOARD}
                                    element={<DashboardPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_JOBS}
                                    element={<JobsPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_JOB}
                                    element={<JobPage/>}
                                  />
                                  <Route path={ROUTES.INTERNAL_JOB}
                                    element={<JobPage internal />}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_CANDIDATES}
                                    element={<CandidatesPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_CANDIDATE_PROFILE}
                                    element={<CandidateProfilePage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_CANDIDATE_CREATE}
                                    element={<CandidateCreatePage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_CANDIDATE_RECOMMEND}
                                    element={<CandidateRecommendPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_CANDIDATE_APPROVED_APPLICATION}
                                    element={<CandidateApprovedApplicationPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_CANDIDATE_REJECTED_APPLICATION}
                                    element={<CandidateRejectedApplicationPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_PROFILE}
                                    element={<ProfilePage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_PRIVACY}
                                    element={<PrivacyPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_CAREERS}
                                    element={<CareersPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_TERMS}
                                    element={<TermsPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_ABOUT_US}
                                    element={<AboutUsPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_PRICING}
                                    element={<PricingPage/>}
                                  />
                                  <Route
                                    path={ROUTES.CANDIDATE_EMAIL_UNSUBSCRIPTION}
                                    element={<CandidateUnsubscriptionPage/>}
                                  />
                                  <Route
                                    path={ROUTES.BLOG}
                                    element={<BlogPage/>}
                                  />
                                  <Route
                                    path={ROUTES.BLOG_CATEGORY}
                                    element={<BlogCategoryPage/>}
                                  />
                                  <Route
                                    path={ROUTES.BLOG_POST}
                                    element={<BlogPostPage/>}
                                  />
                                  <Route
                                    path={ROUTES.HOW_IT_WORKS}
                                    element={<HowItWorksPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_EMAIL_UNSUBSCRIPTION}
                                    element={<SpotterUnsubscriptionPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_EMPLOYER_LANDING}
                                    element={<EmployerLandingPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_EMPLOYEE_REFERRAL_LANDING}
                                    element={<EmployeeReferralLandingPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_MARKET_INSIGHTS_TOOL}
                                    element={<InsightsSearchPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_MARKET_INSIGHTS}
                                    element={<InsightsResultPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_MARKET_INSIGHTS_COMPARISON}
                                    element={<InsightsComparisonResultPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RESET_PASSWORD}
                                    element={<ResetPasswordPage/>}
                                  />
                                  <Route
                                    path={ROUTES.RECRUITER_CONTACT_US}
                                    element={<ContactUsPage/>}
                                  />
                                  <Route
                                    path={ROUTES.EMPLOYER}
                                    element={<EmployerPage/>}
                                  />
                                  <Route
                                    path={ROUTES.INTERNAL_EMPLOYER}
                                    element={<EmployerPage isInternal />}
                                  />
                                  <Route
                                    path={ROUTES.SPONSORSHIP}
                                    element={<SponsorshipPage />}
                                  />
                                  <Route
                                    path={ROUTES.SPONSORSHIP_INVITE}
                                    element={<SponsoredInvitePage />}
                                  />
                                  <Route
                                    path={ROUTES.SPONSORED}
                                    element={<SponsoredListPage />}
                                  />

                                  {/* Error test page */}
                                  <Route
                                    path={ROUTES.ERROR_TEST}
                                    element={<ErrorTestPage/>}
                                  />
                                  {/* Catch-all rule when none of the above matches */}
                                  <Route
                                    path="*"
                                    element={<NotFoundPage/>}
                                  />
                                </Route>
                              </Routes>
                              <FooterPortal component={<Footer/>} />
                            </DefaultLanguageSwitcher>
                          </SponsorCodeProvider>
                        </AuthUserProvider>
                      </ErrorBoundary>
                    </AuthenticatedApolloProvider>
                  </FirebaseAuthProvider>
                </FirebaseProvider>
              </DatePickerLocaleProvider>
            </ErrorBoundary>
          </TranslationProvider>
        </Loader>
      </BasePage>
  );
}
