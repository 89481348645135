import React from "react";
import useAuthUser from "../../authentication-no-ui/useAuthUser.js";
import { useTranslation } from "react-i18next";

/**
 * This component is used to automatically switch to the user default language if the user is athentified.
 */
export default function DefaultLanguageSwitcher(props) {
  const authUser = useAuthUser();
  const { i18n } = useTranslation();

  React.useEffect(() => {
    if (authUser.authenticated && authUser.profile) {
      i18n.changeLanguage(
        authUser.profile.defaultLanguage.toLowerCase(),
        (/*err*/) => {
          // Do nothing if an error occurs
        }
      );
    }
  }, [authUser.authenticated, authUser.profile, i18n]);

  return <React.Fragment>{props.children}</React.Fragment>;
}
