import React from "react";
import Page from "../../common/page/Page.js";
import { useParams } from "react-router";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import useQueryOrganizationByNameUrl from "./useQueryOrganizationByNameUrl.js";
import useTranslationEmployers from "../useTranslationEmployers.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import EmployerBlock from "./EmployerBlock.js";
import EmployerNotFoundBlock from "./EmployerNotFoundBlock.js";

export default function EmployerPage(props) {

  const {isInternal} = props;

  // Fetch company name from ID provided in the url params.
  const urlParams = useParams();
  const companyName = urlParams.companyName;

  const {
    data: organization,
    loading: organizationLoading,
    errors: organizationErrors,
  } = useQueryOrganizationByNameUrl(companyName);

  const { t, loading: tLoading } = useTranslationEmployers();
  useShowLoader(tLoading, "EmployerPage");

  return (
    <WaitForData
      loading={organizationLoading}
      errors={organizationErrors}
      onLoaded={() => (
        <>
          {organization && (
            <>
              <Page className="EmployerPage">
                <Page.Title loading={organizationLoading} text={organization.company} />
                <Page.Content className="spaced-children">
                  <EmployerBlock
                    organization={organization} isInternal={isInternal}
                  />
                </Page.Content>
              </Page>
            </>
          )}
          {!organization && (
            <Page className="EmployerPage">
              <Page.Title
                loading={organizationLoading}
                text={t("employers:employer_details_not_found_title")}
              />
              <Page.Content>
                <EmployerNotFoundBlock />
              </Page.Content>
            </Page>
          )}
        </>
      )}
    />
  );
}
