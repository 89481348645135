import React from "react";
import LoadingContext from "./LoadingContext.js";
import LoadingSpinner from "./LoadingSpinner.js";
import "./Loader.scss";
import FadeInLoader from "./FadeInLoader.js";

/**
 * Displays a loading spinner when a registered component triggers it. Insert this component somewhere in the tree and
 * have children register to it, so that when any of the children is in a loading state, the spinner is shown instead.
 * It avoids having multiple spinners on the screen when several components are loading. Children should register/unregister
 * themselves by calling the callback provided by the LoadingContext provider with true/false. When more than one Loader is
 * in the tree hierarchy, the closest parent catches the callback.
 *
 * Use the "icon" prop to provide a component to display while in loading state. If animation is desired, it should be
 * in the icon component, not in the loader here, so that different loaders may have different animations.
 *
 * @param props
 * @return {*}
 * @constructor
 */
export default function Loader(props) {
  // "name" is optional. Provide it to log debugging information.
  const { icon, name, minDelay } = props;
  const debug = !!name;

  const [registeredCount, setRegisteredCount] = React.useState(0);
  const [forceDisplay, setForceDisplay] = React.useState(false);

  // Memoize this function so that it does not change on every render (which would also trigger a re-render of children
  // that use it)
  const registerToLoader = React.useCallback(
    (register, component) => {
      const increment = register ? 1 : -1;
      if (debug) {
        console.debug(
          `Loader (${name}) is turned ` +
            (register ? "on" : "off") +
            ` by component ${component}`
        );
      }
      setRegisteredCount((prevCount) => {
        // If we want the loader to display for a minimum amount of time when it is turned on, register a timeout function
        // as soon as the loader is turned on and set the forceDisplay state variable to true. The timeout will set
        // this variable back to false when it expires, and the loading state will take it into account
        if (prevCount === 0 && increment === 1 && minDelay > 0) {
          setForceDisplay(true);
          setTimeout(() => {
            setForceDisplay(false);
          }, minDelay);
        }

        return prevCount + increment;
      });
    },
    [name, debug, setForceDisplay, minDelay]
  );

  const IconComponent = icon ? (
    icon
  ) : (
    <FadeInLoader icon={<LoadingSpinner />} />
  );

  // Show the loader if at least one child requires it. Hide it when none of the children requires it, unless we want
  // to force the display for a certain amount of time to avoid flickering.
  const show = registeredCount > 0 || forceDisplay;

  if (debug)
    console.debug(
      `Loader (${name}) is rendering with status: ` +
        (show ? "on" : "off") +
        ` (registered: ${registeredCount}, forced display: ${forceDisplay})`
    );

  // RENDER

  return (
    <div className="Loader">
      {/* Provide the registerToLoader callback to any child through a context */}
      <LoadingContext.Provider value={registerToLoader}>
        <div className={show ? "visible-spinner" : "hidden-spinner"}>
          {IconComponent}
        </div>
        {/* Children must remain in the tree even when loading, to avoid their destruction when loading starts */}
        <div className={show ? "hidden-contents" : "visible-contents"}>
          {props.children}
        </div>
      </LoadingContext.Provider>
    </div>
  );
}
