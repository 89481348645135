import React from "react";
import useQueryJobPublicSearch from "./useQueryJobPublicSearch.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import SimilarJobsItem from "./SimilarJobsItem.js";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import { Col, Row } from "react-bootstrap";
import "./SimilarJobsResultList.scss";
import StringUtils from "../../../utils/StringUtils.js";

export default function SimilarJobsResultList(props) {
  const { query, currentJobId, company } = props;

  // Memoize searchFilters to avoid re-rendering of components that use them after every render of this component
  const searchFilters = React.useMemo(
    () => {
      if (StringUtils.isNullOrEmpty(company)) {
        return { queryText: StringUtils.nullToEmpty(query).slice(0, 5) };
      } else {
        return { companyName: company };
      }
    }, [company, query]);

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "JobSearchItem");

  const {
    loading,
    errors,
    data: jobs,
  } = useQueryJobPublicSearch(
    5,
    searchFilters,
    "DATE_POSTED_DESC"
  );

  const jobSimilar = jobs.filter((item) => item._id !== currentJobId);

  return (
    <div className="SimilarJobsResultList">
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <div className="simular-jobs-block">
            {jobSimilar.length > 0 && (
              <Row>
                <Col className="similar-jobs-title">
                  {t("jobs:job_similar_jobs_label")}
                </Col>
              </Row>
            )}
            <Row>
              <Col>
                {jobSimilar.map((item, index) => (
                  <SimilarJobsItem key={index} item={item} />
                ))}
              </Col>
            </Row>
          </div>
        )}
      />
    </div>
  );
}
