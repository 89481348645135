import { React, useState } from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import SimilarJobsResultList from "../search/SimilarJobsResultList.js";
import * as ROUTES from "../../../constants/routes.js";
import party from "../../../img/party.svg";
import Image from "react-bootstrap/Image";
import ActionLink from "../../common/widgets/ActionLink.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import ApprovalDetailsJob from "./ApprovalDetailsJob.js";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import "./CandidateApprovedApplicationBlock.scss";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function CandidateApprovedApplicationBlock(props) {
  const { job, applicationId, approval } = props;

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "CandidateApprovedApplicationBlock");

  const {redirectTo} = useReferrer();

  function onClickBecomeSpotter(event) {
    event.preventDefault();
    redirectTo(ROUTES.SIGN_UP);
  }

  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);

  const showJob = () => {
    setShow(!show);
    setScroll(!scroll);
  };

  return (
    <>
      <div className="CandidateApprovedApplicationBlock">
        <Row>
          <Col>
            <p className="congratulation-paragraph">
              {t("jobs:job_congratulations")}
            </p>
          </Col>
        </Row>
        <Row className="banner-row">
          <Col className="job-approval-col">
            <div className="job-approval-div">
              <Row>
                <Col>
                  <Row>
                    <Col className="job-approval-text">
                      {HtmlUtils.htmlToComponents(
                        t("jobs:job_approval_action_text", {
                          name: approval.recruiterName,
                          job: job.title,
                        })
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      {HtmlUtils.htmlToComponents(
                        t("jobs:job_approval_approved_status_text", {
                          name: approval.recruiterName,
                          job: job.title,
                        })
                      )}
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <ActionButton
                        className="become-spotter-action"
                        onClick={(event) => onClickBecomeSpotter(event)}
                      >
                        {t("jobs:job_approval_action_button_label")}
                      </ActionButton>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row className="show-detail-job-Row-containeur">
                <Col className="show-detail-job-col">
                  <VisibilityOutlinedIcon />
                </Col>
                <Col className="show-detail-job-col">
                  <ActionLink href="#congratulation" onClick={showJob}>
                    {t("jobs:job_show_detail")}
                  </ActionLink>
                </Col>
              </Row>
            </div>
          </Col>
          <Col className="banner-image-col">
            <Image src={party} className="banner-image" />
          </Col>
        </Row>
        <Row>
          <Col id="congratulation" className="job-details-div">
            <Row>
              <Col>
                <ApprovalDetailsJob
                  job={job}
                  applicationId={applicationId}
                  approval={approval}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SimilarJobsResultList
                  query={[job.title].concat(job.technicalSkills).slice(0, 5)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
