import TechnicalSkillsChart from "./TechnicalSkillsChart.js";
import useQueryInsightMostInDemandTechnicalSkills from "./useQueryInsightMostInDemandTechnicalSkills.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import Loader from "../../common/loading-widgets/Loader.js";
import SkeletonBarChart from "./SkeletonBarChart.js";

const TechnicalSkillsCard = (props) => {
  const { keyword, language, chartColor, displayLegend, insightCount } = props;

  const {
    loading,
    errors,
    data: technicalSkillsData,
  } = useQueryInsightMostInDemandTechnicalSkills(keyword, 15, language);

  return (
    <div className="widget-block">
      <Loader icon={<SkeletonBarChart />}>
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            <TechnicalSkillsChart
              keyword={keyword}
              technicalSkillsData={technicalSkillsData}
              chartColor={chartColor}
              displayLegend={displayLegend}
              insightCount={insightCount}
            />
          )}
        />
      </Loader>
    </div>
  );
};

export default TechnicalSkillsCard;
