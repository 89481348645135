import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import useQueryRecruiterFeedbackByRecruiterId from "./useQueryRecruiterFeedbackByRecruiterId.js";
import ReactStars from "react-rating-stars-component";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./RatingStarsItem.scss";
import TypeUtils from "../../../utils/TypeUtils.js";

export default function RatingStarsItem(props) {
  const { recruiterId, size } = props;

  const { data, loading, errors } =
    useQueryRecruiterFeedbackByRecruiterId(recruiterId);

  const ratingAverage = !TypeUtils.arrayIsEmpty(data)
    ? data.reduce((accumulator, current) => accumulator + current.rating, 0) /
      data.length
    : 0;

  return (
    <div className = "RatingStarsItem">
      <HorizontalLoader>
        <WaitForData
          loading={loading}
          errors={errors}
          onError={() => <ReportProblemOutlined className="LoadingError" />}
          onLoaded={() => (
            <Row className="starsColContainer">
              <Col className="starsCol">
                <ReactStars
                  edit={false}
                  count={5}
                  value={ratingAverage}
                  size={size}
                />
              </Col>
            </Row>
          )}
        />
      </HorizontalLoader>
    </div>
  );
}
