import {gql} from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery.js";

const QUERY_SCOPE = "recruiter";
const QUERY_NAME = "recruiterById";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(id: $id) {
        _id,
        sponsoredRecruiterCount
      }
    }
  }
`;

export default function useQuerySponsoredRecruiterCount(recruiterId) {
  const variables = {
    id: recruiterId,
  };
  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME,{
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
