import Page from "../../common/page/Page.js";
import InsightsSearchBlock from "./InsightsSearchBlock.js";
import CommonSeo from "../../common/seo/CommonSeo.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationInsights from "../useTranslationInsights.js";
import * as ROUTES from "../../../constants/routes.js";
import RouterUtils from "../../../utils/RouterUtils.js";

const InsightsSearchPage = () => {
  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "InsightsSearchPage");

  const domainName = window.location.hostname;
  const url =
    domainName +
    RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_MARKET_INSIGHTS_TOOL);

  return (
    <Page className="InsightsSearchPage">
      <CommonSeo
        title={t("insights:insights_search_page_title_message")}
        description={t("insights:insights_search_page_description_message")}
        url={url}
      />
      <Page.Content className="spaced-children">
        <InsightsSearchBlock />
      </Page.Content>
    </Page>
  );
};

export default InsightsSearchPage;
