import React from "react";
import { Row, Col } from "react-bootstrap";
import useTranslationLanding from "../useTranslationLanding.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import "./PresentationSection.scss";

export default function PresentationSection() {
  const { t, loading: tLoading } = useTranslationLanding();
  useShowLoader(tLoading, "PresentationSection");

  return (
    <div className="PresentationSection">
      <Row>
        <Col>
          <Row>
            <Col className="section-title-col">
              {t("landing:landing_presentation_label")}
            </Col>
          </Row>
          <Row>
            <Col className="section-text-col">
              {t("landing:landing_presentation_text")}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
