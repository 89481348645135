import SoftSkillsChart from "./SoftSkillsChart.js";
import useQueryInsightMostInDemandSoftSkills from "./useQueryInsightMostInDemandSoftSkills.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import Loader from "../../common/loading-widgets/Loader.js";
import SkeletonBarChart from "./SkeletonBarChart.js";

const SoftSkillsCard = (props) => {
  const { keyword, language, chartColor, displayLegend, insightCount } = props;

  const {
    loading,
    errors,
    data: softSkillsData,
  } = useQueryInsightMostInDemandSoftSkills(keyword, 15, language);

  return (
    <div className="widget-block">
      <Loader icon={<SkeletonBarChart />}>
        <WaitForData
          loading={loading}
          errors={errors}
          onLoaded={() => (
            <SoftSkillsChart
              keyword={keyword}
              softSkillsData={softSkillsData}
              chartColor={chartColor}
              displayLegend={displayLegend}
              insightCount={insightCount}
            />
          )}
        />
      </Loader>
    </div>
  );
};

export default SoftSkillsCard;
