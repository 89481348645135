import BarChart from "./BarChart.js";
import MathUtils from "../../../utils/MathUtils.js";

const TechnicalSkillsChart = (props) => {
  const {
    technicalSkillsData,
    keyword,
    chartColor,
    displayLegend,
    insightCount,
  } = props;

  const technicalSkills = technicalSkillsData.map(
    (technicalSkill) => technicalSkill._id
  );

  const technicalSkillsTotalCount = technicalSkillsData.map((technicalSkill) =>
    MathUtils.percentage(technicalSkill.totalCount, insightCount)
  );

  const chartData = {
    labels: technicalSkills,
    datasets: [
      {
        label: keyword,
        data: technicalSkillsTotalCount,
        backgroundColor: chartColor,
        borderWidth: 0,
        borderRadius: 3,
        borderSkipped: false,
      },
    ],
  };

  return <BarChart data={chartData} displayLegend={displayLegend} />;
};

export default TechnicalSkillsChart;
