import pathToRegexp from "path-to-regexp";
import * as ROUTES from "../constants/routes.js";

class RouterUtils {

  /**
   * Replaces the parameters in a React Router URL of the form '/.../:param/...' with their actual value
   * @param route URL pattern with :parameters
   * @param params Values for parameters
   * @returns {string} Actual URL
   */
  static injectParamsInRoute(route, params) {
    try {
      // pathToRegexp doesn't support external urls with ports, like localhost:3000, so remove it before compiling
      let path = route;
      try {
        // Use URL function to get the portion of the route after the domain name. The second parameter is mandatory.
        // If route is external, the second parameter is simply ignored. If route is internal, the second parameter is
        // prepended to route but we ignore it anyway ("pathname" is the portion of the route after the domain name).
        const { pathname } = new URL(route, "http://example.com");
        // Fallback to original route if URL failed to build a pathname
        path = pathname ? pathname : route;
      } catch (error) {
        // URL is not supported by IE. Catch error and fail silently. "path" will remain the original "route".
      }
      const compiledPath = pathToRegexp.compile(path);
      return route.replace(path, compiledPath(params));
    } catch (error) {
      // Errors can happen when parameters are missing; redirect to page not found
      return ROUTES.NOT_FOUND;
    }
  };

  /**
   * Opens an external URL in a new tab
   * @param url URL to open
   * @returns undefined
   */
  static openExternalUrl(url) {
    const win = window.open(url, "_blank");
    win.focus();
  };

}

export default RouterUtils;
