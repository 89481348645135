import React, {useMemo} from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import ApplicationStatusEnum from "../ApplicationStatusEnum.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import DateUtils from "../../../utils/DateUtils.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import useQueryApplicationById from "./useQueryApplicationById.js";

export default function DisplayApplicationStatusEffectiveDate({applicationId}) {
  const { t, loading: tLoading } = useTranslationCandidates();
  const { data: application, loading, errors } = useQueryApplicationById(applicationId);
  useShowLoader(tLoading);

  const effectiveDate = useMemo(() => {
    if (!application || !application.hire)
      return undefined;

    let effectiveDateAsString = undefined;
    if (application.status === ApplicationStatusEnum.HIRED)
      effectiveDateAsString = application.hire.hiringDate;
    else if (application.status === ApplicationStatusEnum.HIRING_CANCELLED)
      effectiveDateAsString = application.hire.dateCancelled;

    if (effectiveDateAsString)
      return DateUtils.localDateToTimelessIsoString(new Date(effectiveDateAsString));
    else
      return null;
  }, [application]);

  return (
    <WaitForData loading={loading} errors={errors} onLoaded={() =>
      <>
        {effectiveDate &&
          <div className="DisplayApplicationStatusEffectiveDate">{t("candidates:candidates_application_status_effective_label")} {effectiveDate}</div>
        }
      </>
    }/>
  );
}
