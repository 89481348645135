import React from "react";
import { useController } from "react-hook-form";
import useTranslationCommon from "../useTranslationCommon.js";
import "./ValidatedInput.scss";
import StringUtils from "../../../utils/StringUtils.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import {FormCheck} from "react-bootstrap";

export default function ValidatedInputCheckbox(props) {
  const { t, loading: tLoading } = useTranslationCommon();
  const { name, control, inputRef, defaultValue, choices, required, ...otherProps } = props;

  // Generate a unique ID for the form control, so that clicking the label selects the option
  const id = React.useRef(Math.floor(Math.random() * 100000000000000000));

  const {
    field: { onChange, onBlur, value },
    fieldState: { error },
  } = useController({
    rules: { required },
    name,
    control,
    defaultValue: StringUtils.nullToEmpty(defaultValue),
  });

  let errorMessage = "";

  if (error && !tLoading) {
    switch (error.type) {

      case "required":
        errorMessage = t("common:common_input_required");
        break;

      default:
        errorMessage = error.type;
    }
  }

  return (
    <div className="ValidatedInput">
      {TypeUtils.ensureArray(choices).map(choice =>
        StringUtils.isNullOrEmpty(choice.value) ?
          null :
          <FormCheck
            id={`${id.current}_${choice.value}`}
            key={choice.value}
            name={name}
            type={"checkbox"}
            label={StringUtils.isNullOrEmpty(choice.label) ? choice.value : choice.label}
            onChange={onChange}
            onBlur={onBlur}
            value={choice.value}
            checked={choice.value === value}
            {...otherProps}
          />
        )
      }
      {errorMessage && <p className="errorMessage">{errorMessage}</p>}
    </div>
  );
}
