import React from "react";
import LoadingContext from "./LoadingContext.js";

/**
 * Triggers the nearest Loader component higher in the render tree.
 * @param loading If true, Loader will show; if false, Loader will disappear
 * @param source Optional identifier to know which component triggers the Loader.
 * @return {null} Nothing to display
 */
export default function useShowLoader(loading, source = undefined) {
  const registerToLoader = React.useContext(LoadingContext);

  if (registerToLoader == null)
    console.error("There is no Loader above this component.");

  // Wrapper around registerToLoader to do nothing if it is null. We memoize it so that the function is not redefined
  // on every render, which would trigger the useEffect every time. We could have used useCallback with arguments, but calling
  // registerToLoader on arguments even after checking it is not null would trigger a warning that it can be null...
  const memoizedRegisterToLoader = React.useMemo(() => {
    return registerToLoader ? registerToLoader : () => {};
  }, [registerToLoader]);

  // If we want to load, register the component to the loader and return a callback to unregister it (every time the
  // loading state change or the component is unmounted)
  // If don't want to load, do nothing (if previous state was to load, the callback to unregister will have been run before)
  React.useEffect(() => {
    if (loading) {
      memoizedRegisterToLoader(true, source);

      return () => memoizedRegisterToLoader(false, source);
    }
  }, [loading, memoizedRegisterToLoader, source]);

  return null;
}
