import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationLanding from "../useTranslationLanding.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import JobSearchPane from "./JobSearchPane.js";
import ShortListJob from "./ShortListJob.js";
import HowItWorks from "./HowItWorks.js";
import BecomeSpotterButton from "./BecomeSpotterButton.js";
import CallToActionSection from "./CallToActionSection.js";
import PresentationSection from "./PresentationSection.js";
import "./LandingBlock.scss";

export default function LandingBlock(/*props*/) {
  const { t, loading: tLoading } = useTranslationLanding();
  useShowLoader(tLoading, "LandingBlock");

  return (
    <div className="LandingBlock">
      <Row>
        <Col>
          <div className="tagline-div">
            <Row >
              <Col className="tagline-text-col">
                <h1>{t("landing:landing_tagline_text")}</h1>
              </Col>
            </Row>
            <Row>
              <Col className="subtitle-col">
                {t("landing:landing_subtitle_text")}
              </Col>
            </Row>
            <Row >
              <Col>
                <BecomeSpotterButton />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <HowItWorks />
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <ShortListJob />
        </Col>
      </Row>
      <Row>
        <Col>
          <JobSearchPane />
        </Col>
      </Row>
      <Row>
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col>
          <PresentationSection />
        </Col>
      </Row>
      <Row>
        <Col>
          <CallToActionSection />
        </Col>
      </Row>
    </div>
  );
}
