import React, {useCallback, useMemo} from "react";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useMutationApplicationCreate from "./useMutationApplicationCreate.js";
import {
  formatWysiwygOutput,
  getWysiwygContentLength,
} from "../../common/widgets/WysiwygEditor";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm.js";
import ServerError from "../../common/data-fetching/ServerError.js";
import CandidateSaveApplicationForm from "./CandidateSaveApplicationForm.js";

export default function useCandidateSaveApplicationForm(
  candidate,
  recruiterId,
  job
) {
  const { t, loading: tLoading } = useTranslationCandidates();

  const [qualificationErrors, setQualificationErrors] = React.useState(null);

  const {
    mutate: creationMutate,
    loading: creationLoading,
    errors: creationErrors,
  } = useMutationApplicationCreate();

  const {
    control,
    formState: { isValid },
    getValues,
    watch,
  } = useValidatedInputForm();

  // Get number of characters typed in the wysiwyg (not counting hidden html tags)
  const note = watch("note");
  const textLength = getWysiwygContentLength(note);

  const submit = useCallback(() => {

    setQualificationErrors(null);

    // Check whether there are any error in the form before submitting
    const hasWorkPermit = getValues("hasWorkPermit");
    if (job && job.isWorkPermitRequired && !hasWorkPermit) {
      const qualificationError = new ServerError("MissingQualificationError", t("candidates:candidates_save_application_qualifications_error"));
      setQualificationErrors([qualificationError]);
      return Promise.reject(qualificationError);
    }

    return creationMutate({
      variables: {
        candidateId: candidate._id,
        jobId: job && job._id,
        recruiterId: recruiterId,
        resumeId: candidate.resumeId,
        recruiterNote: formatWysiwygOutput(note),
      },
    });
  }, [getValues, job, creationMutate, candidate, recruiterId, note, t]);

  const canSubmit = useCallback(() => isValid,
    [isValid]
  );

  const formProps = useMemo(() => ({
    t,
    control,
    textLength,
    job,
  }), [t, control, textLength, job]);

  return {
    form: CandidateSaveApplicationForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: creationLoading,
    errors: qualificationErrors || creationErrors,
  };
}
