import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import PlaceIcon from "@mui/icons-material/Place";
import SearchIcon from "@mui/icons-material/Search";
import TypeUtils from "../../../utils/TypeUtils.js";
import useTranslationLanding from "../useTranslationLanding.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import { InputGroup } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import FormControl from "react-bootstrap/FormControl";
import ActionButton from "../../common/widgets/ActionButton.js";
import queryString from "query-string";
import * as ROUTES from "../../../constants/routes.js";
import "./JobSearchPane.scss";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function JobSearchPane(props) {
  const [searchFilters, setSearchFilters] = React.useState({
    queryText: "",
    location: "",
  });

  const updateSearchFilterField = (field, values) => {
    if (values && values.length > 0) {
      setSearchFilters(
        TypeUtils.shallowCopyObjectSetProp(searchFilters, field, values)
      );
    } else {
      const { [field]: deletedKey, ...filters } = searchFilters;
      setSearchFilters(filters);
    }
  };

  const { t, loading: tLoading } = useTranslationLanding();
  useShowLoader(tLoading, "JobSearchPane");

  const {redirectTo} = useReferrer();

  function onClickSearchJobs(event) {
    event.preventDefault();
    redirectTo({
      pathname: ROUTES.RECRUITER_JOBS,
      search: queryString.stringify({
        keyword: searchFilters.queryText,
        location: searchFilters.location,
      }),
    });
  }

  const placePlaceholder = t("landing:landing_filter_job_location");
  const searchPlaceholder = t("landing:landing_filter_job_keyword");

  // RENDER
  return (
    <div className="JobSearchPane">
      <Form
        className="form-container"
        onSubmit={(event) => onClickSearchJobs(event)}
      >
        <Row className="filter-row">
          <Col className="first-filter-input-col">
            <InputGroup>
              <FormControl
                name="name"
                onChange={(event) =>
                  updateSearchFilterField("queryText", event.target.value)
                }
                type="text"
                value={searchFilters.queryText}
                placeholder={searchPlaceholder}
                autoComplete="off"
                className="input"
              />
              <InputGroup.Text className={"icon"}>
                <SearchIcon />
              </InputGroup.Text>
            </InputGroup>
          </Col>
          <Col>
            <InputGroup>
              <FormControl
                name="name"
                onChange={(event) =>
                  updateSearchFilterField("location", event.target.value)
                }
                type="text"
                value={searchFilters.location}
                placeholder={placePlaceholder}
                autoComplete="off"
                className="input"
              />
              <InputGroup.Text className={"icon"}>
                <PlaceIcon />
              </InputGroup.Text>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className="action-col">
            <ActionButton type="submit" className="search-button">
              {t("landing:landing_search_button_label")}
            </ActionButton>
          </Col>
        </Row>
      </Form>
    </div>
  );
}
