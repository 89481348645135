import React from "react";
import HorizontalLoader from "../../common/loading-widgets/HorizontalLoader.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ReportProblemOutlined from "@mui/icons-material/ReportProblemOutlined";
import useQueryJobById from "./useQueryJobById.js";
import DisplayDate from "../../common/widgets/DisplayDate.js";
import DisplayApplicationStatus from "../../candidates/view/DisplayApplicationStatus.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import PersonIcon from "@mui/icons-material/Person";
import DateRangeIcon from "@mui/icons-material/DateRange";
import ApplicationStatusEnum from "../../candidates/ApplicationStatusEnum.js";
import DisplayApplicationStatusEffectiveDate from "../../candidates/view/DisplayApplicationStatusEffectiveDate.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader";
import useTranslationDashboard from "../useTranslationDashboard";

export default function ApplicationItem(props) {
  const { application } = props;
  const { t, loading: tLoading } = useTranslationDashboard();
  useShowLoader(tLoading);

  const { data: job, loading, errors } = useQueryJobById(application.jobId);

  return (
    <HorizontalLoader>
      <WaitForData
        loading={loading}
        errors={errors}
        onError={() => <ReportProblemOutlined className="LoadingError" />}
        onLoaded={() => (
          <Row className="row-container">
            <Col className={"job-col"}>{job ? job.title : t("dashboard:recruiters_dashboard_unavailable_job")}</Col>
            <Col className={"name-col"}>
              <Row>
                <Col className="icon-col">
                  <PersonIcon />
                </Col>
                <Col>{application.candidate.name}</Col>
              </Row>
            </Col>
            <Col className={"approval-col"}>
              <Row>
                <Col>
                  <DisplayApplicationStatus application={application} job={job}/>
                </Col>
              </Row>
              {(application.status === ApplicationStatusEnum.HIRED || application.status === ApplicationStatusEnum.HIRING_CANCELLED) &&
                <Row>
                  <Col>
                    <HorizontalLoader>
                      <DisplayApplicationStatusEffectiveDate applicationId={application._id}/>
                    </HorizontalLoader>
                  </Col>
                </Row>
              }
            </Col>
            <Col className={"count-day-col"}>
              <Row>
                <Col className="icon-col">
                  <DateRangeIcon />
                </Col>
                <Col>
                  <DisplayDate date={application.createdAt} />
                </Col>
              </Row>
            </Col>
          </Row>
        )}
      />
    </HorizontalLoader>
  );
}
