// Try to keep declarations here and in App.js in the same order, to improve maintainability
import StringUtils from "../utils/StringUtils.js";

export const CONNECTION_MODE = "/connectionmode";
export const CONNECTION_MODE_SIGN_UP = "/connectionmode-signup";
export const SIGN_OUT = "/signout";
export const SIGN_IN = "/signin";
export const SIGN_UP = "/signup";
export const HOME = "/";

export const RECRUITER_DASHBOARD = "/dashboard";
export const RECRUITER_JOBS = "/jobs";
export const RECRUITER_JOB = "/jobs/:jobId";
export const RECRUITER_CANDIDATES = "/candidates";
export const RECRUITER_CANDIDATE_PROFILE = "/candidates/:candidateId";
export const RECRUITER_CANDIDATE_CREATE = "/create-candidate";
export const RECRUITER_CANDIDATE_RECOMMEND = "/jobs/:jobId/recommend-candidate";
export const RECRUITER_CANDIDATE_APPROVED_APPLICATION =
  "/jobs/:jobId/:applicationId/approved";
export const RECRUITER_CANDIDATE_REJECTED_APPLICATION =
  "/jobs/:jobId/:applicationId/rejected";
export const RECRUITER_PROFILE = "/profile";
export const RECRUITER_CONTACT_US = "/contact-us";
export const RECRUITER_PRIVACY = "/privacy";
export const RECRUITER_CAREERS = "/careers";
export const RECRUITER_TERMS = "/terms";
export const RECRUITER_ABOUT_US = "/about-us";
export const RECRUITER_EMPLOYER_LANDING = "/employers";
export const RECRUITER_EMPLOYEE_REFERRAL_LANDING = "/employee-referral-program-solution";
export const RECRUITER_PRICING = "/pricing";
export const RECRUITER_MARKET_INSIGHTS_TOOL = "/market-insights-tool";
export const RECRUITER_MARKET_INSIGHTS = "/market-insights";
export const RECRUITER_MARKET_INSIGHTS_COMPARISON =
  "/market-insights-comparison";
export const RECRUITER_EMAIL_UNSUBSCRIPTION = "/unsubscribe/:email";
export const CANDIDATE_EMAIL_UNSUBSCRIPTION = "/candidate/unsubscribe/:email";
export const BLOG = "/blog";
export const BLOG_CATEGORY = "/blog/:category";
export const BLOG_POST = "/blog/:category/:title";
export const HOW_IT_WORKS = "/howitworks";
export const SPONSORSHIP = "/sponsorship";
export const SPONSORSHIP_INVITE = "/sponsorship/invite";
export const SPONSORED = "/sponsored";

// "en" and "fr" paths are different for SEO purposes
export const JOB_TITLES_EN =
  "/blog/job-description-template/job-titles-list-a-z";
export const JOB_TITLES_FR =
  "/blog/description-offre-emploi/liste-titres-emploi-ordre-alphabetique";

export const RESET_PASSWORD = "/pw-forget";

export const NOT_FOUND = "/not-found";

export const EMPLOYER = "/careers/:companyName";

export const INTERNAL_EMPLOYER = "/ref/:companyName";
export const INTERNAL_JOB = "/ref/:companyName/jobs/:jobId";

// error page for tests
export const ERROR_TEST = "/error-test";

//liens
export const EMPLOYER_PORTAL = StringUtils.stripTrailingSlash(
  process.env.REACT_APP_EMPLOYER_URL
);

export const EMPLOYER_SIGN_IN = EMPLOYER_PORTAL + "/signin";
export const EMPLOYER_SIGN_UP = EMPLOYER_PORTAL + "/signup";

export const FACEBOOK_URL = "https://www.facebook.com/Mouseatwork";
export const LINKEDIN_URL = "https://www.linkedin.com/company/mouse-at-work";
export const CALENDLY_URL = "https://calendly.com/mouseatwork/15min";
