import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import Image from "react-bootstrap/Image";
import useTranslationEmployers from "../useTranslationEmployers.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import RouterUtils from "../../../utils/RouterUtils.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import { SocialIcon } from "react-social-icons";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";
import useLanguages from "../../infra-no-ui/translation/useLanguages.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import "./EmployerHeaderBlock.scss";

export default function EmployerHeaderBlock(props) {
  const { organization, isInternal } = props;
  const { t, loading: tLoading } = useTranslationEmployers();
  useShowLoader(tLoading, "EmployerHeaderBlock");

  const { getCurrentLanguageCode } = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  const tagline =
    currentLanguageCode === "fr"
      ? organization.companyCoverTagLineFr
      : organization.companyCoverTagLineEn;
  const description =
    currentLanguageCode === "fr"
      ? organization.companyIntroFr
      : organization.companyIntroEn;

  const onClickLink = (url) => {
    RouterUtils.openExternalUrl(url);
  };

  return (
    <div className="EmployerHeaderBlock">
      <Row>
        <Col>
          <div className="employer-header-div">
            <Row>
              <Col className="tagline-col">{tagline}</Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            {organization.companyLogoUrl && isInternal && (
              <Col className="logo-col">
                <Image className="logo" src={organization.companyLogoUrl} />
              </Col>
            )}
            <Col className="title-col">
              <h1 className="title">
                {organization.company}
              </h1>
            </Col>
          </Row>
        </Col>
        <Col className="button-col">
          {organization.companyFacebookUrl && (
            <SocialIcon
              url={organization.companyFacebookUrl}
              className="SocialIcon"
              target="_blank"
              rel="noopener noreferrer"
            />
          )}
          {organization.companyLinkedinUrl && (
            <SocialIcon
              url={organization.companyLinkedinUrl}
              className="SocialIcon"
              target="_blank"
              rel="noopener noreferrer"
            />
          )}
          {organization.companyEmail && (
            <SocialIcon
              url={"mailto:" + organization.companyEmail}
              className="SocialIcon"
              target="_blank"
              rel="noopener noreferrer"
            />
          )}
          {organization.companyUrl && (
            <ActionButton
              className="action-button"
              onClick={() => onClickLink(organization.companyUrl)}
            >
              {t("employers:employer_company_website_msg")}
              <ExitToAppIcon />
            </ActionButton>
          )}
        </Col>
      </Row>
      <Row>
        <Col>{HtmlUtils.htmlToComponents(description)}</Col>
      </Row>
    </div>
  );
}
