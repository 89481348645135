import React, {useCallback} from "react";
import Page from "../../common/page/Page.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useAuthUser from "../../authentication-no-ui/useAuthUser.js";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized.js";
import ScrollUtils from "../../common/page/ScrollUtils.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";
import CandidateSaveForm from "./CandidateSaveForm.js";

export default function CandidateCreatePage(/*props*/) {
  const { t, loading: tLoading } = useTranslationCandidates();
  const { profile } = useAuthUser();
  const recruiterId = profile._id;
  const location = useLocation();
  const {redirectTo} = useReferrer();

  // Current step
  const step = queryString.parse(location.search).step;

  // candidateId is present when candidate exists, otherwise email may exist until candidate is created
  const candidateId = queryString.parse(location.search).candidateId;
  const email = queryString.parse(location.search).email;

  // Callback when one of the form components wants to change to another page. We want the URL to change so that
  // the browser's back button works, that's why we push another page to the history.
  // Email is used until candidate is created. CandidateId may change after candidate selection or creation.
  const changeStep = useCallback((step, newCandidateId = undefined, email = undefined) => {
    redirectTo({
      pathname: location.pathname,
      search: queryString.stringify({ step, candidateId: newCandidateId, email }),
    });
    ScrollUtils.scrollToTop();
  }, [redirectTo, location.pathname]);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="CandidateCreatePage">
          <Page.Title loading={tLoading}>
            {t("candidates:candidates_create_candidate_title")}
          </Page.Title>
          <Page.Content>
            <CandidateSaveForm
              candidateId={candidateId}
              recruiterId={recruiterId}
              step={step}
              onChangeStep={changeStep}
              email={email}
            />
          </Page.Content>
        </Page>
      )}
    />
  );
}
