import CookieConsent from "react-cookie-consent";
import useTranslationCommon from "../useTranslationCommon.js";
import useShowLoader from "../loading-widgets/useShowLoader.js";

export default function UserCookieConsent(props) {
  const { t, loading: tLoading } = useTranslationCommon();
  useShowLoader(tLoading, "UserCookieConsent");

  return (
    <CookieConsent
      location="bottom"
      buttonText={t("common:common_accept_cookies_label")}
      cookieName="spotterfulCookie"
      expires={150}
    >
      {t("common:common_accept_cookies_message")}
    </CookieConsent>
  );
}
