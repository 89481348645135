import React from "react";
import Pagination from "react-bootstrap/Pagination";
import queryString from "query-string";
import useTranslationBlog from "../useTranslationBlog.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import "./PaginationSection.scss";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";
import {useNavigate} from "react-router-dom";

export default function PaginationSection(props) {
  const { url, active, size } = props;

  const { t, loading: tLoading } = useTranslationBlog();
  useShowLoader(tLoading, "BlogBlock");

  const {redirectTo} = useReferrer();
  const navigate = useNavigate();

  const changePage = (number) => {
    redirectTo({
      pathname: url,
      search: queryString.stringify({
        page: number,
      }),
    });
    navigate.go(0);
  };

  return (
    <Pagination className="PaginationSection">
      {active > 1 && (
        <Pagination.Item onClick={() => changePage(1)}>
          {t("blog:blog_page_first")}
        </Pagination.Item>
      )}
      {active > 1 && (
        <Pagination.Item onClick={() => changePage(active - 1)}>
          {t("blog:blog_page_previous")}
        </Pagination.Item>
      )}
      {active < size && (
        <Pagination.Item onClick={() => changePage(active + 1)}>
          {t("blog:blog_page_next")}
        </Pagination.Item>
      )}
      {active < size && (
        <Pagination.Item onClick={() => changePage(size)}>
          {t("blog:blog_page_last")}
        </Pagination.Item>
      )}
    </Pagination>
  );
}
