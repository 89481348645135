import React from "react";
import Page from "../../common/page/Page.js";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized.js";
import SponsoredInviteBlock from "./SponsoredInviteBlock.js";

export default function SponsoredInvitePage() {

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <Page className="SponsoredInvitePage">
          <Page.Content className="spaced-children">
            <SponsoredInviteBlock />
          </Page.Content>
        </Page>
      )}
    />
  );
}
