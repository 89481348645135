import {gql} from "@apollo/client";
import useWrappedQuery from "../data-fetching/useWrappedQuery.js";

const QUERY_SCOPE = "global";
const QUERY_NAME = "globalMaxUploadFileSize";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME} {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}
    }
  }
`;

export default function useQueryMaxUploadFileSize() {

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME);
}
