import React from "react";
import ActionButton from "../../common/widgets/ActionButton.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./PricingSlot.scss";

export default function PricingSlot(props) {
  const { children, title, priceText, onClick, highlighted, buttonText } =
    props;

  return (
    <div className="PricingSlot">
      <Row>
        <Col
          className={
            highlighted
              ? "highlighted-title-section title-section"
              : "title-section"
          }
        >
          <div className="title-div">{title}</div>
        </Col>
      </Row>
      <Row>
        <Col className="price-section">{priceText}</Col>
      </Row>
      <Row className="children-row">
        <Col>
          <div className="children-div">{children}</div>
        </Col>
      </Row>
      <Row>
        <Col>
          <ActionButton onClick={onClick} className="signup-button">
            {buttonText}
          </ActionButton>
        </Col>
      </Row>
    </div>
  );
}
