import React from "react";
import "./Separator.scss";

export default function Separator(props) {

  const {children} = props;

  return (
    <div className="Separator">
      {children ? children : null}
    </div>
  );
};
