import { gql } from "@apollo/client";
import useDeferredQuery from "../../common/data-fetching/useDeferredQuery.js";

const QUERY_SCOPE = "application";
const QUERY_NAME = "applicationResendApprovalEmail";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($applicationId: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(applicationId: $applicationId)
  }
}
`;

export default function useLazyQueryApplicationResendApprovalEmailCopy(applicationId) {
  const variables = { applicationId };

  return useDeferredQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables
  });
}
