import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./CandidatesBlock.scss";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import CandidateSearchBox from "../search/CandidateSearchBox.js";
import CandidatesWithApplicationCountList from "./CandidatesWithApplicationCountList.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import * as ROUTES from "../../../constants/routes.js";
import RouterUtils from "../../../utils/RouterUtils.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function CandidatesBlock(props) {
  const { recruiterId } = props;
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading);

  const {redirectTo} = useReferrer();

  const onClickCreateCandidate = (event) => {
    event.preventDefault();
    redirectTo(ROUTES.RECRUITER_CANDIDATE_CREATE);
  };

  const onSelectOption = (option) =>
    redirectTo(
      RouterUtils.injectParamsInRoute(ROUTES.RECRUITER_CANDIDATE_PROFILE, {
        candidateId: option._id,
      })
    );

  return (
    <div className="CandidatesBlock">
      <Row>
        <Col className="page-title">
          <h1>{t("candidates:candidates_page_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <ActionButton onClick={onClickCreateCandidate}>
            {t("candidates:candidates_dashboard_create_candidate_button_label")}
          </ActionButton>
        </Col>
      </Row>
      <Row className={"search-block-row"}>
        <Col>
          <CandidateSearchBox
            autofocus
            recruiterId={recruiterId}
            onSelectOption={onSelectOption}
          />
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <Row>
            <Col>
              <CandidatesWithApplicationCountList recruiterId={recruiterId} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
