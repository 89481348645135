import React from "react";
import SponsorCodeContext from "./SponsorCodeContext.js";
import StringUtils from "../../utils/StringUtils.js";

const SPONSOR_CODE_PARAM_KEY = "sponsor";
const SPONSOR_CODE_STORAGE_KEY = "spotterful-sponsor-key";

/**
 * Context provider that can be consumed by useSponsorCode to get the recruiter's sponsor code.
 *
 * The recruiter's sponsor code is a code that uniquely identifies a recruiter who shares a link
 * to Spotterful. When a new user clicks the link and creates a profile, the sponsor code is
 * used to link the new profile to the recruiter (sponsor) who shared the link.
 *
 * The recruiter's sponsor code is a param added to any url, eg. https://spotterful.com/?sponsor=ABCDEF
 *
 * The sponsor code is stored in session storage as soon as a url contains one and it is never
 * deleted (until the browser session ends). This allows the new user coming to the site through
 * a sponsored link to browse the site before creating a profile without loosing the sponsor code when it comes to
 * creating the profile.
 */
export default function SponsorCodeProvider(props) {

  const {children} = props;

  const searchParams = new URLSearchParams(window.location.search);

  const sponsorCodeParam = searchParams.get(SPONSOR_CODE_PARAM_KEY);

  // Store sponsorCode in session storage as soon as one page includes this param.
  // Once it is set, never forget it, even if a page is accessed without it.
  React.useEffect(() => {
    if (!StringUtils.isNullOrEmpty(sponsorCodeParam)) {
      sessionStorage.setItem(SPONSOR_CODE_STORAGE_KEY, sponsorCodeParam);
    }
  }, [sponsorCodeParam]);

  // Compute sponsor code. Look for its value in the session storage. If the value is readily
  // available from the url, return it at once.
  const sponsorCode = React.useMemo(() => {
    if (!StringUtils.isNullOrEmpty(sponsorCodeParam))
      return sponsorCodeParam;
    else
      return sessionStorage.getItem(SPONSOR_CODE_STORAGE_KEY);
  }, [sponsorCodeParam]);

  return (
    <SponsorCodeContext.Provider value={sponsorCode}>
      {children}
    </SponsorCodeContext.Provider>
  );
}
