import React from "react";
import "./LoadingSpinner.scss";
import Spinner from "react-bootstrap/Spinner";

export default function LoadingSpinner() {
  return (
    <div className="LoadingSpinner">
      <Spinner animation="border" variant="$tertiary" role="status" />
    </div>
  );
}
