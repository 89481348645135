import { React, useState } from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import HtmlUtils from "../../../utils/HtmlUtils.js";
import SimilarJobsResultList from "../search/SimilarJobsResultList.js";
import * as ROUTES from "../../../constants/routes.js";
import ActionLink from "../../common/widgets/ActionLink.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import ApprovalDetailsJob from "./ApprovalDetailsJob.js";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import RouterUtils from "../../../utils/RouterUtils.js";
import "./CandidateRejectedApplicationBlock.scss";
import CandidateApprovalStatusEnum from "../../candidates/CandidateApprovalStatusEnum.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function CandidateRejectedApplicationBlock(props) {
  const { job, applicationId, approval } = props;

  const {redirectTo} = useReferrer();

  const redirectToApproved = () => {
    redirectTo(
      RouterUtils.injectParamsInRoute(
        ROUTES.RECRUITER_CANDIDATE_APPROVED_APPLICATION,
        {
          jobId: job._id,
          applicationId: applicationId,
        }
      )
    );
  };

  if (
    approval.candidateApprovalStatus === CandidateApprovalStatusEnum.APPROVED
  ) {
    redirectToApproved();
  }

  const { t, loading: tLoading } = useTranslationJobs();
  useShowLoader(tLoading, "CandidateRejectedApplicationBlock");

  const onClickApproval = () => {
    redirectToApproved();
  };

  const [show, setShow] = useState(false);
  const [scroll, setScroll] = useState(false);

  const showJob = () => {
    setShow(!show);
    setScroll(!scroll);
  };

  return (
    <>
      <div className="CandidateRejectedApplicationBlock">
        <div className="job-approval-div">
          <Row>
            <Col className="job-approval-text">
              {HtmlUtils.htmlToComponents(
                t("jobs:job_approval_action_text", {
                  name: approval.recruiterName,
                  job: job.title,
                })
              )}
            </Col>
          </Row>
          <Row className="rejected-text-row">
            <Col>
              {HtmlUtils.htmlToComponents(
                t("jobs:job_approval_rejected_status_text", {
                  name: approval.recruiterName,
                  job: job.title,
                })
              )}
            </Col>
          </Row>
          <Row className="call-to-action-row">
            <Col>
              <ActionButton
                className="become-spotter-action"
                onClick={() =>
                  onClickApproval()
                }
              >
                {t("jobs:job_approval_action")}
              </ActionButton>
            </Col>
          </Row>

          <Row className="show-detail-job-row-containeur">
            <Col className="show-detail-job-col">
              <VisibilityOutlinedIcon />
            </Col>
            <Col className="show-detail-job-col">
              <ActionLink href="#jobdescription" onClick={showJob}>
                {t("jobs:job_show_detail")}
              </ActionLink>
            </Col>
          </Row>
        </div>
        <Row>
          <Col id="jobdescription" className="job-details-div">
            <Row>
              <Col>
                <ApprovalDetailsJob
                  job={job}
                  applicationId={applicationId}
                  approval={approval}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <SimilarJobsResultList
                  query={[job.title].concat(job.technicalSkills).slice(0, 5)}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
