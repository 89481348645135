import React from "react";
import {useTranslation} from "react-i18next";
import useTranslationContext from "./useTranslationContext.js";

/**
 * Implement on-demand loading of translation files that are located inside the React application source tree.
 * Putting the translation files next to the components that use them (instead of in an asset directory, for example),
 * makes the application more modular and easier to maintain.
 *
 * By defining one hook per module (eg. useMenuTranslation), and having all components in that module call that hook,
 * we ensure that the translations for that module are loaded whenever a component from that module is used.
 *
 * In order for the translation files to be packaged as bundles at build time, we need to call 'import(...)' with
 * the filename literal instead of using variables. That is why we need to provide the import promises as a parameter
 * to the hook. That is also the reason why we import files for all languages, however we don't wait for all promises to
 * resolve before setting loading state to false, we wait only for the promise for the current language or any more
 * generic language (eg. translations for 'fr' are suitable when current language is 'fr-CA').
 *
 * @param namespace Namespace to put the translations in
 * @param imports Map of (language, import promise)
 * @return {*} useTranslation return value + loading property
 */
export default function useModuleTranslation(namespace, imports) {

  const {addTranslations, isTranslationLoaded} = useTranslationContext();

  // Mark translation as loading if translation for current language is still loading
  const loading = !isTranslationLoaded(namespace);

  // Add translations at the end of the first render because we cannot do it while rendering. Callee will handle
  // the addition of the same languages multiple times (should do nothing).
  React.useEffect(() => {
    addTranslations(namespace, imports);
  }, [namespace, imports, addTranslations]);

  // Forward call to regular useTranslation hook; caller should look at the loading property before using the translation
  return {
    loading,
    ...useTranslation(namespace)
  };
}
