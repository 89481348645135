import React, {useCallback} from "react";
import Page from "../../common/page/Page.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useAuthUser from "../../authentication-no-ui/useAuthUser.js";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { useParams } from "react-router";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized.js";
import ScrollUtils from "../../common/page/ScrollUtils.js";
import JobStatusEnum from "../../jobs/JobStatusEnum.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";
import CandidateSaveForm from "./CandidateSaveForm.js";
import useQueryJobById from "../../jobs/view/useQueryJobById.js";

export default function CandidateRecommendPage(/*props*/) {
  // Fetch job from ID provided in the url params.
  const params = useParams();
  const jobId = params.jobId;

  const {
    data: job,
    loading: jobLoading,
    errors: jobErrors,
  } = useQueryJobById(jobId);

  const { t, loading: tLoading } = useTranslationCandidates();
  const { profile } = useAuthUser();
  const recruiterId = profile && profile._id;
  const location = useLocation();
  const {redirectTo} = useReferrer();

  // Current step
  const step = queryString.parse(location.search).step;

  // candidateId is present when candidate exists, otherwise email may exist until candidate is created
  const candidateId = queryString.parse(location.search).candidateId;
  const email = queryString.parse(location.search).email;

  // Callback when one of the form components wants to change to another page. We want the URL to change so that
  // the browser's back button works, that's why we push another page to the history.
  // Email is used until candidate is created. CandidateId may change after candidate selection or creation.
  const changeStep = useCallback((step, newCandidateId = undefined, email = undefined) => {
    redirectTo({
      pathname: location.pathname,
      search: queryString.stringify({ step, candidateId: newCandidateId, email }),
    });
    ScrollUtils.scrollToTop();
  }, [redirectTo, location.pathname]);

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={jobLoading || tLoading}
          errors={jobErrors}
          onLoaded={() => (
            <>
              {job && job.status === JobStatusEnum.PUBLISHED && (
                <Page className="CandidateRecommendPage">
                  <Page.Title loading={tLoading}>{job.title}</Page.Title>
                  <Page.Content>
                    <CandidateSaveForm
                      job={job}
                      candidateId={candidateId}
                      recruiterId={recruiterId}
                      step={step}
                      onChangeStep={changeStep}
                      email={email}
                    />
                  </Page.Content>
                </Page>
              )}
              {(!job || job.status !== JobStatusEnum.PUBLISHED) && (
                <Page className="CandidateRecommendPage">
                  <Page.Title loading={jobLoading}>
                    {t("candidates:candidates_job_details_not_found")}
                  </Page.Title>
                </Page>
              )}
            </>
          )}
        />
      )}
    />
  );
}
