import React from "react";
import * as ROUTES from "../../constants/routes.js";
import Image from "react-bootstrap/Image";
import logo from "../../img/logo-spotterful.png";
import {Link} from "react-router-dom";
import "./MenuLogo.scss";
import useReferrer from "../infra-no-ui/navigation/useReferrer.js";

export default function MenuLogo(/*props*/) {

  const {referrerStateWithCurrent} = useReferrer();

  return (
    <div className="MenuLogo">
      <Link to={ROUTES.HOME} state={referrerStateWithCurrent}>
        <Image src={logo} className="logo" />
      </Link>
    </div>
  );
};
