import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation.js";
import { QUERY as QUERY_CANDIDATE } from "../view/useQueryCandidateById.js";

const QUERY_SCOPE = "resume";
const QUERY_NAME = "resumeUploadFile";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} (
    $candidateId: ID!
    $file: Upload
  ) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME} (
        candidateId: $candidateId
        file: $file
      ) {
        _id
        resumeFile {
          filename
        }
      }
    }
  }
`;

export default function useMutationCandidateUploadResume() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME, {
    refetchQueries: [QUERY_CANDIDATE],
  });
}
