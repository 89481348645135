import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import {Form} from "react-bootstrap";
import { useJobSortLabels, jobSortTypes } from "./JobSortType.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationJobs from "../useTranslationJobs.js";
import "./JobSort.scss";

export default function JobSort(props) {
  const { selectedValue, updateSelectedValue } = props;

  const { loading: jobSortLabelsLoading, getJobSortLabel } = useJobSortLabels();
  useShowLoader(jobSortLabelsLoading);

  const onChange = (event) => {
    event.preventDefault();
    updateSelectedValue(event.target.value);
  };

  const { t, loading } = useTranslationJobs();

  if (loading) return null;

  // RENDER
  return (
    <Row className="JobSort">
      <Col className="job-sort-label-col">{t("jobs:job_sort_label")}</Col>
      <Col className="form-control-col">
        <Form.Select
          className="job-sort-form-control"
          name={"jobSort"}
          value={selectedValue}
          onChange={onChange}
        >
          {jobSortTypes.map((jobSortType) => (
            <option className={"option"} key={jobSortType} value={jobSortType}>
              {getJobSortLabel(jobSortType)}
            </option>
          ))}
        </Form.Select>
      </Col>
    </Row>
  );
}
