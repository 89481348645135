import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./SponsoredListBlock.scss";
import useTranslationSponsorship from "../useTranslationSponsorship.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import ActionButton from "../../common/widgets/ActionButton.js";
import * as ROUTES from "../../../constants/routes.js";
import SponsoredRecruiterList from "./SponsoredRecruiterList.js";
import ActionLink from "../../common/widgets/ActionLink.js";
import useReferrer from "../../infra-no-ui/navigation/useReferrer.js";

export default function SponsoredListBlock(props) {
  const { recruiterId } = props;
  const { t, loading: tLoading } = useTranslationSponsorship();
  useShowLoader(tLoading);

  const {redirectTo} = useReferrer();

  const onClickInvite = (event) => {
    event.preventDefault();
    redirectTo(ROUTES.SPONSORSHIP_INVITE);
  };

  const onClickDetails = (event) => {
    event.preventDefault();
    redirectTo(ROUTES.SPONSORSHIP);
  };

  return (
    <div className="SponsoredListBlock">
      <Row>
        <Col className="page-title">
          <h1>{t("sponsorship:sponsored_page_title")}</h1>
        </Col>
        <Col className={"button-col"}>
          <ActionButton onClick={onClickInvite}>
            {t("sponsorship:sponsored_page_invite_button_label")}
          </ActionButton>
        </Col>
      </Row>
      <Row>
        <Col>
          <ActionLink onClick={onClickDetails}>{t("sponsorship:sponsored_page_program_details")}</ActionLink>
        </Col>
      </Row>
      <Row>
        <Col className="widget-block">
          <Row>
            <Col>
              <SponsoredRecruiterList recruiterId={recruiterId} />
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
