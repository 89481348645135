import React from "react";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import { Form } from "react-bootstrap";
import "./EnumFilter.scss";
import TypeUtils from "../../../utils/TypeUtils.js";
import StringUtils from "../../../utils/StringUtils.js";

export default function EnumFilter(props) {
  const { updateSelectedValue, selectedValue, choices, name, label, className } = props;

  const onChange = (event) => {
    event.preventDefault();
    updateSelectedValue(event.target.value);
  };

  // RENDER
  return (
    <Row className={`EnumFilter ${StringUtils.nullToEmpty(className)}`}>
      <Col className="filter-label">
        {label}
      </Col>
      <Col className="form-control-col">
        <Form.Select
          className="form-control-box"
          name={name}
          value={selectedValue}
          onChange={onChange}
        >
          {TypeUtils.ensureArray(choices).map((choice, index) =>
            <option
              key={index}
              className={"option"}
              value={choice.value}
            >
              {choice.label}
            </option>
          )}
        </Form.Select>
      </Col>
    </Row>
  );
}
