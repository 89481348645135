import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery.js";

const QUERY_SCOPE = "candidate";
const QUERY_NAME = "candidateSearch";

export const CandidateSortEnum = {
  UPDATED_AT_ASC: "UPDATED_AT_ASC",
  UPDATED_AT_DESC: "UPDATED_AT_DESC",
};

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($cursor: Int!, $pageSize: Int!, $filter: CandidateFilterInput, $sort: CandidateSortEnum) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(cursor: $cursor, pageSize: $pageSize, filter: $filter, sort: $sort) {
        nodes {
          _id
          name
          email
          updatedAt
        }
        nextCursor
        hasNextPage
      }
    }
  }
`;

export default function useQueryCandidateSearch(cursor, pageSize, filter, sort) {
  const variables = { cursor, pageSize, filter, sort };
  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
  });
}
