export default class QueryUtils {

  /**
   * Data returned by GraphQL are under the module name then the query name. Get rid of both and return the meaningful
   * data directly
   * @param data Data structure as returned by GraphQL API
   * @param queryScope Module name
   * @param queryName Query name
   * @returns {*|null} Child of query name (the actual data we want). Null if module name or query name do not match with params.
   */
  static removeQueryScopeAndName(data, queryScope, queryName) {
    // leafData is value at leaf data.scope.queryName, or null if data is null, or undefined if queryName and/or scope is wrong
    const leafData = queryScope ? (data && data[queryScope] && data[queryScope][queryName]) : (data && data[queryName]);

    // Return value or null (leave no undefined)
    return (leafData != null) ? leafData : null;
  }
}
