import React from "react";
import Page from "../common/page/Page.js";
import useTranslationAuthenticationUi from "./useTranslationAuthenticationUi.js";
import useShowLoader from "../common/loading-widgets/useShowLoader.js";

export default function FirebaseErrorPage(props) {

  const {t, loading: tLoading} = useTranslationAuthenticationUi();
  useShowLoader(tLoading, "FirebaseErrorPage");

  return (
    <Page className={"FirebaseErrorPage"}>
      <Page.Title loading={tLoading} text={t("authentication-ui:firebase_error_title")}/>
      <Page.Content>
        <p>{t("authentication-ui:firebase_error_msg_1")}</p>
        <p>{props.error}</p>
        <p>{t("authentication-ui:firebase_error_msg_2")}</p>
      </Page.Content>
    </Page>
  );
}
