import { Row, Col } from "react-bootstrap";
import useTranslationEmployers from "../useTranslationEmployers.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import noResultImg from "../../../img/no-result.svg";
import { Image } from "react-bootstrap";
import "./EmployerNotFoundBlock.scss";

const EmployerNotFoundBlock = () => {
  const { t, loading: tLoading } = useTranslationEmployers();
  useShowLoader(tLoading, "EmployerNotFoundBlock");

  return (
    <div className="EmployerNotFoundBlock">
      <Row>
        <Col>
          <Image src={noResultImg} />
        </Col>
      </Row>
      <Row>
        <Col className="employer-not-found-label">
          {t("employers:employer_details_not_found_title")}
        </Col>
      </Row>
    </div>
  );
};

export default EmployerNotFoundBlock;
