import { gql } from "@apollo/client";
import useWrappedQuery from "../../common/data-fetching/useWrappedQuery.js";
import useLanguages from "../../infra-no-ui/translation/useLanguages.js";

const QUERY_SCOPE = "job";
const QUERY_NAME = "jobById";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($id: ID!, $lang: GlobalLanguageEnum!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(id: $id) {
        _id
        title
        organization {
          _id
          company
          companyUrl
          companyNameUrl
          companyIsVerifiedAccount
          paymentTerms(lang: $lang)
        }
        description
        streetAddress
        postalCode
        city
        state
        country
        datePosted
        dateUnpublished
        employmentType
        baseSalaryValue
        baseSalaryUnitText
        seniority
        commission
        technicalSkills
        softSkills
        workMode
        isWorkPermitRequired
        status
        createdAt
      }
    }
  }
`;

export default function useQueryJobById(id) {
  const { getCurrentLanguageCode } = useLanguages();
  const variables = { id, lang: getCurrentLanguageCode().toUpperCase() };

  return useWrappedQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {
    variables,
    // Get info from cache and ask the network at the same time, then update on response
    fetchPolicy: "cache-and-network",
    skip: !id
  });
}
