const JobStatusEnum = {
  DRAFT: "DRAFT",
  PENDING: "PENDING",
  PUBLISHED: "PUBLISHED",
  UNPUBLISHED: "UNPUBLISHED",
  REJECTED: "REJECTED",
  ARCHIVED: "ARCHIVED",
};

export default JobStatusEnum;
