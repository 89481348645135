import React from "react";
import FormGroup from "react-bootstrap/esm/FormGroup";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import "./CandidateSaveInformationForm.scss";
import ValidatedInputText from "../../common/widgets/ValidatedInputText.js";
import IndicationRequiredFields from "../../common/widgets/IndicationRequiredFields.js";

export default function CandidateSaveInformationForm({t, control, candidate}) {
  return (
    <>
      <div className="CandidateSaveInformationForm">
        <Row className="information_invite_row">
          <Col>
            {t("candidates:candidates_save_information_invite_msg")}
          </Col>
        </Row>
        <Row>
          <Col>
            <Row className="input-row">
              <Col className="input-col">
                <FormGroup controlId="name">
                  <ValidatedInputText
                    name="name"
                    defaultValue={candidate.name}
                    required
                    control={control}
                    className="FormControl"
                    type="text"
                    placeholder={t(
                      "candidates:candidates_create_candidate_name_placeholder"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="input-row">
              <Col className="input-col">
                <FormGroup controlId="phone">
                  <ValidatedInputText
                    name="phone"
                    className="FormControl"
                    defaultValue={candidate.phone}
                    control={control}
                    type="text"
                    placeholder={t(
                      "candidates:candidates_create_candidate_phone_placeholder"
                    )}
                  />
                </FormGroup>
              </Col>
            </Row>
            <Row className="indication-row">
              <Col className="indication-col">
                <IndicationRequiredFields />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
    </>
  );
}
