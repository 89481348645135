import React from "react";
import PillList from "../../common/widgets/PillList.js";
import TypeUtils from "../../../utils/TypeUtils.js";
import SkillMatchInText from "./SkillMatchInText.js";

export const formatRequiredSoftSkills = (requiredSoftSkills) => {
  return PillList.formatStrings(TypeUtils.ensureArray(requiredSoftSkills));
}

export const formatResumeSoftSkills = (resumeSoftSkills) => {
  return PillList.formatStrings(TypeUtils.ensureArray(resumeSoftSkills).map(softSkill => softSkill.name));
}

export function SoftSkillMatchSection(props) {

  const {allSkills, actualSkillsAsText} = props;

  // RENDER

  return (
    <SkillMatchInText allSkills={allSkills} actualSkillsAsText={actualSkillsAsText}/>
  );
}

export default SoftSkillMatchSection;
