import { gql } from "@apollo/client";
import useWrappedMutation from "../../common/data-fetching/useWrappedMutation.js";

const QUERY_SCOPE = "candidate";
const QUERY_NAME = "candidateUpdate";

export const QUERY = gql`
  mutation ${QUERY_SCOPE}_${QUERY_NAME} (
    $id: ID!,
    $name: String,
    $phone: String,
    $email: String,
    $linkedin: String,
    $indeed: String,
  ) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME} (
        id: $id,
        name: $name,
        phone: $phone,
        email: $email,
        linkedin: $linkedin,
        indeed: $indeed,
      ) {
        _id
        name,
        phone,
        email,
        linkedin,
        indeed,
        resumeId
      }
    }
  }
`;

export default function useMutationCandidateUpdate() {
  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
