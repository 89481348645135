import React from "react";
import Form from "react-bootstrap/Form";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import FormControl from "react-bootstrap/FormControl";
import useTranslationJobs from "../useTranslationJobs.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import "./KeywordFilter.scss";

export default function KeywordFilter(props) {
  const {icon, placeholder, addFilterValue, setInputValue, inputValue} = props;


  const onClickAdd = (event) => {
    event.preventDefault();
    addFilterValue(inputValue);
  };

  const { t, loading: tLoading } = useTranslationJobs();

  useShowLoader(tLoading, "KeywordFilter");

  // RENDER
  return (
    <Form className="KeywordFilter filter">
      <Row>
        <Col className="filter-input-col">
          <InputGroup>
            <FormControl
              className="input"
              name="name"
              onChange={(event) => setInputValue(event.target.value)}
              type="text"
              value={inputValue}
              placeholder={placeholder}
              autoComplete="off"
            />
            <InputGroup.Text className="icon">{icon}</InputGroup.Text>
          </InputGroup>
        </Col>
        <Col className="submit-col filter-button-col">
          <Button
            type="submit"
            onClick={onClickAdd}
            disabled={inputValue.trim().length < 1}
          >
            {t("jobs:filter_add_action")}
          </Button>
        </Col>
      </Row>
    </Form>
  );
}
