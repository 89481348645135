import React from "react";
import { Row, Col } from "react-bootstrap";
import useLazyQueryResumeDownloadUrl from "./useLazyQueryResumeDownloadUrl.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import StringUtils from "../../../utils/StringUtils.js";
import useServerErrorFormatter from "../../common/modals/useServerErrorFormatter.js";
import useErrorModal from "../../common/modals/useErrorModal.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import EditIcon from "@mui/icons-material/EditSharp";
import ActionIcon from "../../common/widgets/ActionIcon.js";
import useContentModal from "../../common/modals/useContentModal.js";
import CandidateUpdateResume from "../mutate/CandidateUpdateResume.js";
import "./ResumeDownloadLink.scss";
import ActionDiv from "../../common/widgets/ActionDiv.js";

export default function ResumeDownloadLink(props) {
  const { candidate, disabled } = props;
  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ResumeDownloadIcon");

  const hasResume = candidate.resumeId ? true : false;

  const onClickDownload = (event) => {
    event.preventDefault();

    return fetch({ id: candidate.resumeId })
      .then((downloadUrl) => {
        if (!StringUtils.isNullOrEmpty(downloadUrl)) {
          // This will trigger the file download (window.open(downloadUrl) produces a glitch)
          const a = document.createElement("a");
          a.href = downloadUrl;
          a.click();
        }
      })
      .catch((error) => {
        setError(error);
        showErrorModal();
      });
  };

  const { fetch } = useLazyQueryResumeDownloadUrl();
  // error returned by useLazyQueryResumeDownloadUrl is not updated after fetch, we have to manage it ourselves
  const [error, setError] = React.useState(null);
  const errorMsg = useServerErrorFormatter(error);
  const { ErrorModal, show: showErrorModal } = useErrorModal(
    <>
      <p>{t("candidates:candidates_resume_download_error")}</p>
      {errorMsg}
    </>
  );

  // Modal to update candidate resume
  const {
    ContentModal: updateResumeModal,
    show: showUpdateResumeModal,
    hide: hideUpdateResumeModal,
  } = useContentModal("AppliationModal");

  const onClickUpdateResume = () => {
    showUpdateResumeModal(getUpdateResume());
  };

  const getUpdateResume = () => {
    return (
      <CandidateUpdateResume
        onClose={hideUpdateResumeModal}
        candidate={candidate}
      />
    );
  };

  return (
    <>
      {ErrorModal}
      {updateResumeModal}
      <div className={"ResumeDownloadLink"}>
        <Row>
          {hasResume ? (
            <Col className="action-link-download">
              <Row>
                <Col className="action-link-download">
                  <span onClick={onClickDownload}>
                    {t("candidates:candidates_details_download_resume_action")}
                  </span>
                </Col>
                <Col className="action-icon-col">
                  <ActionIcon
                    onClick={onClickUpdateResume}
                    icon={EditIcon}
                    loading={false}
                    classNameIcon="action-icon"
                  />
                </Col>
              </Row>
            </Col>
          ) : (
            <Col>
              <ActionDiv
                className="action-link-download"
                onClick={onClickUpdateResume}
                disabled={disabled}
              >
                {t("candidates:candidates_details_add_resume_action")}
              </ActionDiv>
            </Col>
          )}
        </Row>
      </div>
    </>
  );
}
