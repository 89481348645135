import React from "react";
import useAuthUser from "../authentication-no-ui/useAuthUser.js";
import ProfileCreationForm from "./ProfileCreationForm.js";
import {Outlet} from "react-router-dom";

export default function MissingProfileInterceptor(props) {

  const authUser = useAuthUser();

  // RENDER

  // If user is authenticated but has no profile, then ask for the creation of a profile
  if (authUser.authenticated && !authUser.profile)
    return <ProfileCreationForm/>
  else
    return <Outlet/>
}
