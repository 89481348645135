import {gql} from "@apollo/client";
import useWrappedMutation from "../common/data-fetching/useWrappedMutation.js";

const QUERY_SCOPE = "recruiter";
const QUERY_NAME = "recruiterCreate";

export const QUERY = gql`
    mutation ${QUERY_SCOPE}_${QUERY_NAME} (
       $firstName: String!,
       $lastName: String!,
       $email: String!,
       $sponsoredByCode: String,
       $location: RecruiterLocationEnum,
       $signUpReason: RecruiterSignUpReasonEnum,
       $signUpSource: String,
       $defaultLanguage: RecruiterLanguageEnum
    ) {
      ${QUERY_SCOPE} {
        _id
        ${QUERY_NAME} (
          firstName: $firstName,
          lastName: $lastName,
          email: $email,
          sponsoredByCode: $sponsoredByCode,
          location: $location,
          signUpReason: $signUpReason,
          signUpSource: $signUpSource
          defaultLanguage: $defaultLanguage,
        ) {
          _id
        }
      }
    }
  `;

export default function useMutationRecruiterAdd() {

  return useWrappedMutation(QUERY, QUERY_SCOPE, QUERY_NAME);
}
