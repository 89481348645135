import React from "react";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useQueryApplicationByCandidateId from "./useQueryApplicationByCandidateId.js";
import LastViewedList from "../../common/components/LastViewedList.js";
import ApplicationsByCandidateSearchResult from "../search/ApplicationByCandidateSearchResult.js";

export default function ApplicationsByCandidateList(props) {
  const { candidateId } = props;

  const { data, loading, errors } = useQueryApplicationByCandidateId(
    candidateId
  );

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "ApplicationsByCandidateList");

  const emptyMsg = t("candidates:candidates_empty_application_list_msg");
  const columnsLabels = [
    t("candidates:candidates_application_list_job_title_column_label"),
    t("candidates:candidates_application_list_employer_column_label"),
    t("candidates:candidates_application_list_commission_column_label"),
    t("candidates:candidates_application_list_status_column_label"),
    t("candidates:candidates_application_list_date_column_label"),
  ];
  const getItemId = (application) => application._id;

  return (
    <LastViewedList
      items={data}
      loading={loading}
      errors={errors}
      columnsLabels={columnsLabels}
      className={"ApplicationsByCandidateList"}
      emptyMsg={emptyMsg}
      getItemId={getItemId}
      SearchResult={<ApplicationsByCandidateSearchResult />}
    />
  );
}
