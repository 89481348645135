import React from "react";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import CandidateSearchResult from "../search/CandidateSearchResult.js";
import useQueryCandidateSearch, {
  CandidateSortEnum,
} from "../search/useQueryCandidateSearch";
import LastViewedList from "../../common/components/LastViewedList.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import ActionButton from "../../common/widgets/ActionButton.js";
import "./CandidatesList.scss";
import CandidateSearchBox from "../search/CandidateSearchBox.js";

const LIST_LENGTH = 10;

export default function CandidatesList(props) {
  const { recruiterId, onClose } = props;

  const { data, loading, errors } = useQueryCandidateSearch(
    0,
    LIST_LENGTH,
    { recruiterId },
    CandidateSortEnum.UPDATED_AT_DESC
  );

  const { t, loading: tLoading } = useTranslationCandidates();
  useShowLoader(tLoading, "CandidatesList");

  const emptyMsg = t("candidates:candidates_search_empty_msg");
  const getItemId = (candidate) => candidate._id;
  const columnsLabels = [
    t("candidates:candidates_page_name_column_label"),
    t("candidates:candidates_page_date_column_label"),
  ];

  return (
    <div className="CandidatesList">
      <Row className={"search-block-row"}>
        <Col>
          <CandidateSearchBox
            autofocus
            recruiterId={recruiterId}
            onSelectOption={onClose}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <LastViewedList
            items={data ? data.nodes : []}
            loading={loading}
            errors={errors}
            className={"CandidatesList"}
            columnsLabels={columnsLabels}
            emptyMsg={emptyMsg}
            getItemId={getItemId}
            SearchResult={<CandidateSearchResult onClose={onClose} />}
          />
        </Col>
      </Row>
      <Row>
        <Col className="form-actions">
          <ActionButton onClick={() => onClose()}>
            {t("candidates:candidates_close_action")}
          </ActionButton>
        </Col>
      </Row>
    </div>
  );
}
