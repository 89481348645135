import {useCallback, useMemo} from "react";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationCandidates from "../useTranslationCandidates.js";
import useMutationCandidateCreate from "./useMutationCandidateCreate.js";
import useMutationCandidateUpdate from "./useMutationCandidateUpdate.js";
import { useValidatedInputForm } from "../../common/widgets/useValidatedInputForm.js";
import CandidateSaveInformationForm from "./CandidateSaveInformationForm.js";

export default function useCandidateSaveInformationForm(
  candidate,
  recruiterId,
) {

  const { t, loading: tLoading } = useTranslationCandidates();
  const {
    mutate: creationMutate,
    loading: creationLoading,
    errors: creationErrors,
  } = useMutationCandidateCreate();

  const {
    mutate: updateMutate,
    loading: updateLoading,
    errors: updateErrors,
  } = useMutationCandidateUpdate();
  useShowLoader(tLoading);

  // Errors
  const errors = creationErrors || updateErrors;

  const {
    control,
    formState: { isValid },
    getValues,
  } = useValidatedInputForm();

  const canSubmit = useCallback(() => {
    return isValid;
  }, [isValid]);

  const submit = useCallback(() => {
    const fields = getValues();
    if (candidate._id) {
      return updateMutate({
        variables: {
          id: candidate._id,
          name: fields.name.trim(),
          phone: fields.phone.trim(),
          email: candidate.email,
        },
      });
    } else {
      return creationMutate({
        variables: {
          recruiterId: recruiterId,
          name: fields.name.trim(),
          phone: fields.phone.trim(),
          email: candidate.email,
        },
      });
    }
  }, [getValues, candidate, recruiterId, updateMutate, creationMutate]);

  const formProps = useMemo(() => ({
    t,
    control,
    candidate,
  }), [t, control, candidate]);

  return {
    form: CandidateSaveInformationForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: creationLoading || updateLoading,
    errors,
  };
}
