import React from "react";
import Page from "../../common/page/Page.js";
import BlogPostBlock from "./BlogPostBlock.js";

export default function BlogPostPage(props) {
  return (
    <Page className="BlogPostPage">
      <Page.Content className="spaced-children">
        <BlogPostBlock />
      </Page.Content>
    </Page>
  );
}
