import { useLocation } from "react-router-dom";
import queryString from "query-string";
import Page from "../../common/page/Page.js";
import InsightsComparisonResultBlock from "./InsightsComparisonResultBlock.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationInsights from "../useTranslationInsights.js";
import useQueryInsightCount from "./useQueryInsightCount.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import useLanguages from "../../infra-no-ui/translation/useLanguages.js";
import InsightsNoResultBlock from "./InsightsNoResultBlock.js";

const InsightsComparisonResultPage = () => {
  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "InsightsComparisonResultPage");

  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  const firstKeyword = searchParams.firstKeyword;
  const secondKeyword = searchParams.secondKeyword;

  // Show insights in the selected language
  const { getCurrentLanguageCode } = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  const {
    loading: firstInsightCountLoading,
    errors: firstInsightCountErrors,
    data: firstInsightCount,
  } = useQueryInsightCount(firstKeyword, currentLanguageCode);

  const {
    loading: secondInsightCountLoading,
    errors: secondInsightCountErrors,
    data: secondInsightCount,
  } = useQueryInsightCount(secondKeyword, currentLanguageCode);

  const noResultLabel = t("insights:insights_comparison_no_result_label", {
    firstKeyword,
    secondKeyword,
  });

  return (
    <Page className="InsightsComparisonResultPage">
      <Page.Content>
        <WaitForData
          loading={firstInsightCountLoading || secondInsightCountLoading}
          errors={firstInsightCountErrors || secondInsightCountErrors}
          onLoaded={() => (
            <>
              {firstInsightCount > 0 && secondInsightCount > 0 && (
                <InsightsComparisonResultBlock
                  firstKeyword={firstKeyword}
                  secondKeyword={secondKeyword}
                  firstInsightCount={firstInsightCount}
                  secondInsightCount={secondInsightCount}
                  language={currentLanguageCode}
                />
              )}

              {(!firstInsightCount || !secondInsightCount) && (
                <InsightsNoResultBlock label={noResultLabel} />
              )}
            </>
          )}
        />
      </Page.Content>
    </Page>
  );
};

export default InsightsComparisonResultPage;
