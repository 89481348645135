import {useCallback, useMemo} from "react";
import useTranslationCandidates from "../useTranslationCandidates.js";
import CandidateSaveRewardForm from "./CandidateSaveRewardForm.js";

export default function useCandidateSaveRewardForm(candidate, job) {
  const { t, loading: tLoading } = useTranslationCandidates();

  const canSubmit = useCallback(() => {
    return !!job;
  }, [job]);

  const submit = useCallback(() => {
    return Promise.resolve(candidate);
  }, [candidate]);

  const formProps = useMemo(() => ({
    t,
    job
  }), [t, job]);

  return {
    form: CandidateSaveRewardForm,
    formProps,
    canSubmit,
    submit,
    ready: !tLoading,
    submitting: false,
    errors: undefined,
  };
}
