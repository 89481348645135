import "./InsightsComparisonResultBlock.scss";
import { Row, Col } from "react-bootstrap";
import CompareInsightsPane from "./CompareInsightsPane.js";
import ResponsibilitiesCard from "./ResponsibilitiesCard.js";
import SoftSkillsCard from "./SoftSkillsCard.js";
import TechnicalSkillsCard from "./TechnicalSkillsCard.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationInsights from "../useTranslationInsights.js";
import PopularityComparisonCard from "./PopularityComparisonCard.js";
import SimilarJobsResultList from "../../jobs/search/SimilarJobsResultList.js";
import CompaniesCard from "./CompaniesCard.js";
import CitiesCard from "./CitiesCard.js";
import cssVariables from "./Charts.module.scss";
import Loader from "../../common/loading-widgets/Loader.js";

const FIRST_CHART_COLOR = cssVariables.firstChartColor;
const SECOND_CHART_COLOR = cssVariables.secondChartColor;

const InsightsComparisonResultBlock = (props) => {
  const {
    firstKeyword,
    secondKeyword,
    language,
    firstInsightCount,
    secondInsightCount,
  } = props;

  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "InsightsComparisonResultPage");

  const firstResponsibilitiesCardTitle = t(
    "insights:insights_comparison_result_page_first_responsibilities_card_title",
    { firstKeyword }
  );
  const secondResponsibilitiesCardTitle = t(
    "insights:insights_comparison_result_page_second_responsibilities_card_title",
    { secondKeyword }
  );

  const firstCompaniesCardTitle = t(
    "insights:insights_comparison_result_page_first_companies_card_title",
    { firstKeyword }
  );
  const secondCompaniesCardTitle = t(
    "insights:insights_comparison_result_page_second_companies_card_title",
    { secondKeyword }
  );

  const firstCitiesCardTitle = t(
    "insights:insights_comparison_result_page_first_cities_card_title",
    { firstKeyword }
  );
  const secondCitiesCardTitle = t(
    "insights:insights_comparison_result_page_second_cities_card_title",
    { secondKeyword }
  );

  return (
    <div className="InsightsComparisonResultBlock">
      <Row>
        <Col className="title-col">
          {t("insights:insights_comparison_result_page_title", {
            firstKeyword,
            secondKeyword,
          })}
        </Col>
      </Row>
      <Row>
        <Col>
          <CompareInsightsPane
            firstKeyword={firstKeyword}
            secondKeyword={secondKeyword}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <Row>
            <Col>
              <h2>{t("insights:insights_popularity_comparison_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col>
              <PopularityComparisonCard
                firstKeyword={firstKeyword}
                secondKeyword={secondKeyword}
                firstInsightCount={firstInsightCount}
                secondInsightCount={secondInsightCount}
                language={language}
                chartColor={[FIRST_CHART_COLOR, SECOND_CHART_COLOR]}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="cards-rows-container">
          <Row>
            <Col>
              <h2>{t("insights:insights_technical_skills_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="card-col">
              <TechnicalSkillsCard
                keyword={firstKeyword}
                language={language}
                chartColor={FIRST_CHART_COLOR}
                insightCount={firstInsightCount}
              />
            </Col>
            <Col className="card-col">
              <TechnicalSkillsCard
                keyword={secondKeyword}
                language={language}
                chartColor={SECOND_CHART_COLOR}
                insightCount={secondInsightCount}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="cards-rows-container">
          <Row>
            <Col>
              <h2>{t("insights:insights_soft_skills_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="card-col">
              <SoftSkillsCard
                keyword={firstKeyword}
                language={language}
                chartColor={FIRST_CHART_COLOR}
                insightCount={firstInsightCount}
              />
            </Col>
            <Col className="card-col">
              <SoftSkillsCard
                keyword={secondKeyword}
                language={language}
                chartColor={SECOND_CHART_COLOR}
                insightCount={secondInsightCount}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="cards-rows-container">
          <Row>
            <Col>
              <h2>{t("insights:insights_responsibilities_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="card-col">
              <ResponsibilitiesCard
                keyword={firstKeyword}
                language={language}
                title={firstResponsibilitiesCardTitle}
              />
            </Col>
            <Col className="card-col">
              <ResponsibilitiesCard
                keyword={secondKeyword}
                language={language}
                title={secondResponsibilitiesCardTitle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="cards-rows-container">
          <Row>
            <Col>
              <h2>{t("insights:insights_companies_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="card-col">
              <CompaniesCard
                keyword={firstKeyword}
                language={language}
                title={firstCompaniesCardTitle}
              />
            </Col>
            <Col className="card-col">
              <CompaniesCard
                keyword={secondKeyword}
                language={language}
                title={secondCompaniesCardTitle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        <Col className="cards-rows-container">
          <Row>
            <Col>
              <h2>{t("insights:insights_cities_label")}</h2>
            </Col>
          </Row>
          <Row>
            <Col className="card-col">
              <CitiesCard
                keyword={firstKeyword}
                language={language}
                title={firstCitiesCardTitle}
              />
            </Col>
            <Col className="card-col">
              <CitiesCard
                keyword={secondKeyword}
                language={language}
                title={secondCitiesCardTitle}
              />
            </Col>
          </Row>
        </Col>
      </Row>
      <Row>
        {/* Showing similar jobs only for the first keyword for now. */}

        <Col>
          <Loader>
            <SimilarJobsResultList query={firstKeyword} currentJobId={null} />
          </Loader>
        </Col>
      </Row>
    </div>
  );
};

export default InsightsComparisonResultBlock;
