import React from "react";
import { Row, Col, Image } from "react-bootstrap";
import useTranslationLanding from "../useTranslationLanding.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import step1 from "../../../img/step1.svg";
import step2 from "../../../img/step2.svg";
import step3 from "../../../img/step3.svg";
import "./HowItWorks.scss";

export default function HowItWorks() {
  const { t, loading: tLoading } = useTranslationLanding();
  useShowLoader(tLoading, "HowItWork");

  return (
    <div className="HowItWork">
      <Row className="how-it-works-row-container">
        <Col className="how-it-works-col-container">
          <Row>
            <Col className="section-title-col">
              {t("landing:landing_how_it_works_label")}
            </Col>
          </Row>
          <Row className="how-it-works-row">
            <Col className="how-it-works-col">
              <Row>
                <Col className="how-it-works-step-pic-col">
                  <Image className="steps-img" src={step1} />
                </Col>
              </Row>
              <Row>
                <Col className="step-col">
                  {t("landing:landing_how_it_works_step_one_title")}
                </Col>
              </Row>
              <Row>
                <Col className="step-description-col">
                  {t("landing:landing_how_it_works_step_one_text")}
                </Col>
              </Row>
            </Col>
            <Col className="how-it-works-col">
              <Row>
                <Col className="how-it-works-step-pic-col">
                  <Image className="steps-img" src={step2} />
                </Col>
              </Row>
              <Row>
                <Col className="step-col">
                  {t("landing:landing_how_it_works_step_two_title")}
                </Col>
              </Row>
              <Row>
                <Col className="step-description-col">
                  {t("landing:landing_how_it_works_step_two_text")}
                </Col>
              </Row>
            </Col>
            <Col className="how-it-works-col">
              <Row>
                <Col className="how-it-works-step-pic-col">
                  <Image className="steps-img" src={step3} />
                </Col>
              </Row>
              <Row>
                <Col className="step-col">
                  {t("landing:landing_how_it_works_step_three_title")}
                </Col>
              </Row>
              <Row>
                <Col className="step-description-col">
                  {t("landing:landing_how_it_works_step_three_text")}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
}
