import { gql } from "@apollo/client";
import useDeferredQuery from "../../common/data-fetching/useDeferredQuery.js";

const QUERY_SCOPE = "application";
const QUERY_NAME = "applicationValidate";

export const QUERY = gql`
  query ${QUERY_SCOPE}_${QUERY_NAME}($candidateId: ID, $jobId: ID, $recruiterId: ID!) {
    ${QUERY_SCOPE} {
      _id
      ${QUERY_NAME}(candidateId: $candidateId, jobId: $jobId, recruiterId: $recruiterId)
  }
}
`;

export default function useLazyQueryApplicationValidate() {
  return useDeferredQuery(QUERY, QUERY_SCOPE, QUERY_NAME, {});
}
