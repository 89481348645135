import React from "react";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import BlogPostPreview from "./BlogPostPreview.js";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import StringUtils from "../../../utils/StringUtils.js";
import PaginationSection from "./PaginationSection.js";
import {Row} from "react-bootstrap";
import {Col} from "react-bootstrap";
import useLanguages from "../../infra-no-ui/translation/useLanguages.js";
import useTranslationBlog from "../useTranslationBlog.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import * as ROUTES from "../../../constants/routes.js";
import BlogUtils from "./BlogUtils.js";
import "./BlogBlock.scss";
import useContentful from "./useContentful.js";

export default function BlogBlock() {
  const { loading, data, errors, getContentEntries } = useContentful();
  const { t, loading: tLoading } = useTranslationBlog();
  useShowLoader(tLoading, "BlogBlock");

  const location = useLocation();
  const searchParams = queryString.parse(location.search);

  // Show only blog posts in the selected language
  const { getCurrentLanguageCode } = useLanguages();
  const currentLanguageCode = getCurrentLanguageCode();

  const page = StringUtils.nullToEmpty(searchParams.page)
    ? searchParams.page
    : 1;

  const PAGE_SIZE = 5;

  React.useEffect(() => {
    getContentEntries({
      "fields.language": currentLanguageCode,
      locale: BlogUtils.getBlogLocaleCode(currentLanguageCode),
      content_type: process.env.REACT_APP_CONTENTFUL_CONTENT_BLOG_TYPE,
      limit: PAGE_SIZE,
      skip: PAGE_SIZE * (page - 1),
      order: "-fields.date",
    });
  }, [currentLanguageCode, getContentEntries, page]);

  return (
    <div className="BlogBlock">
      <WaitForData
        loading={loading}
        errors={errors}
        onLoaded={() => (
          <>
            {data.total === 0 && t("blog:blog_no_posts_message")}
            {data.total > 0 && (
              <>
                <Row>
                  <Col className="blog-title-col">
                    <h1>{t("blog:blog_title")}</h1>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    {data.items.map((item, index) => (
                      <BlogPostPreview key={index} article={item} />
                    ))}
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <PaginationSection
                      active={parseInt(page)}
                      url={ROUTES.BLOG}
                      size={Math.ceil(data.total / PAGE_SIZE)}
                    />
                  </Col>
                </Row>
              </>
            )}
          </>
        )}
      />
    </div>
  );
}
