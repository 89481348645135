import useGenericModal from "./useGenericModal.js";
import "./ContentModal.scss";

/**
 * Inject a component into a Modal and provide a callback to show the modal.
 * Call show(content) to display the content.
 * The purpose of this hook is to hide the management of the modal show state and group all styling pertaining
 * to modals throughout the app.
 * @param className Class name to give to the Modal component
 * @returns {{ContentModal: *, show: (*|(function(): void))}} A modal component and a callback to show it
 */
export default function useContentModal(className = "") {

  const {GenericModal, show, hide} = useGenericModal(["ContentModal", className].join(" "));

  return {ContentModal: GenericModal, show, hide}
}
