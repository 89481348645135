import React from "react";
import * as ROUTES from "../../constants/routes.js";
import Nav from "react-bootstrap/Nav";
import "./Menu.scss";
import useAuthUser from "../authentication-no-ui/useAuthUser.js";
import isUserAuthorized from "../authentication-no-ui/isUserAuthorized.js";
import { MenuDropdown, MenuDropdownItem, MenuItem } from "./MenuComponents.js";
import useTranslationMenu from "./useTranslationMenu.js";

export default function Menu() {
  const { t, loading: tLoading } = useTranslationMenu();
  const authUser = useAuthUser();

  // Don't use useShowLoader because there is no loader to display over this component; just wait until there is something to display.
  if (tLoading) {
    return null;
  }

  // User might be authenticated but might not have a profile, if we are displaying the missing profile page
  const userIsAuthenticated = authUser && authUser.authenticated;

  // My account menu items
  const accountMenuItems = [];

  if (isUserAuthorized(authUser)) {
    accountMenuItems.push(
      <MenuDropdownItem key="menu_item_profile" to={ROUTES.RECRUITER_PROFILE}>
        {t("menu:menu_item_profile")}
      </MenuDropdownItem>
    );
    accountMenuItems.push(
      <MenuDropdownItem key="menu_item_sponsorship" to={ROUTES.SPONSORED}>
        {t("menu:menu_item_sponsorship")}
      </MenuDropdownItem>
    );
    accountMenuItems.push(
      <MenuDropdownItem key="menu_item_sign_out" to={ROUTES.SIGN_OUT}>
        {t("menu:menu_item_sign_out")}
      </MenuDropdownItem>
    );
  }

  return (
    <Nav className="Menu">
      {!userIsAuthenticated && (
        <MenuItem to={ROUTES.RECRUITER_JOBS}>
          {t("menu:menu_item_jobs")}
        </MenuItem>
      )}
      {userIsAuthenticated && (
        <MenuItem key="menu_item_dashboard" to={ROUTES.RECRUITER_DASHBOARD}>
          {t("menu:menu_item_dashboard")}
        </MenuItem>
      )}
      {userIsAuthenticated && (
        <MenuItem key="menu_item_jobs" to={ROUTES.RECRUITER_JOBS}>
          {t("menu:menu_item_jobs")}
        </MenuItem>
      )}
      {userIsAuthenticated && (
        <MenuItem key="menu_item_candidates" to={ROUTES.RECRUITER_CANDIDATES}>
          {t("menu:menu_item_candidates")}
        </MenuItem>
      )}
      {!userIsAuthenticated && (
        <MenuItem to={ROUTES.RECRUITER_EMPLOYER_LANDING}>
          {t("menu:menu_item_for_employers")}
        </MenuItem>
      )}
      <MenuItem to={ROUTES.HOW_IT_WORKS}>
        {t("menu:menu_item_howitworks")}
      </MenuItem>
      {!userIsAuthenticated && (
        <MenuItem to={ROUTES.SIGN_UP}>{t("menu:menu_item_sign_up")}</MenuItem>
      )}
      {!userIsAuthenticated && (
        <MenuItem className="menu-item-outlined" to={ROUTES.CONNECTION_MODE}>
          {t("menu:menu_item_sign_in")}
        </MenuItem>
      )}
      {userIsAuthenticated && (
        <MenuDropdown
          title={t("menu:menu_item_account")}
          items={accountMenuItems}
        />
      )}
    </Nav>
  );
}
