import React from "react";
import Page from "../../common/page/Page.js";
import EmployeeReferralLandingBlock from "./EmployeeReferralLandingBlock.js";

export default function EmployeeReferralLandingPage(props) {

  return (
    <Page className="LandingPage">
      <Page.Content className="spaced-children">
        <EmployeeReferralLandingBlock />
      </Page.Content>
    </Page>
  );
}
