import React from "react";

/**
 * Returns the previous state of a value
 * @param value New value
 * @return {undefined} Previous value
 */
export default function usePreviousState(value) {
  const ref = React.useRef();

  React.useEffect(() => {
    ref.current = value;
  });

  // The first time it is called, it returns undefined (because useRef() was called with no value, then after render of
  // the calling component, this component's useEffect will run and set the ref to the current value. On next render
  // of the calling component (before the useEffect of the next render are called), it will return the previous value.
  return ref.current;
}
