import React from "react";
import useTranslationCommon from "../useTranslationCommon.js";
import useShowLoader from "../loading-widgets/useShowLoader.js";
import "./IndicationRequiredFields.scss";

export default function IndicationRequiredFields() {
  const { t, loading: tLoading } = useTranslationCommon();

  useShowLoader(tLoading);

  if (!tLoading) {
    return <div className="IndicationRequiredFields">{t("common:common_required_field_indication")}</div>;
  }
  return null;
}
