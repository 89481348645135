import React from "react";
import Page from "../../common/page/Page.js";
import WaitForData from "../../common/data-fetching/WaitForData.js";
import ProfileBlock from "./ProfileBlock.js";
import useAuthUser from "../..//authentication-no-ui/useAuthUser.js";
import useTranslationProfile from "../useTranslationProfile.js";
import WithRedirectionUserIsAuthorized from "../../authentication-ui/WithRedirectionUserIsAuthorized.js";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";

export default function ProfilePage(props) {
  const { profile, loading, error } = useAuthUser();

  const { t, loading: tLoading } = useTranslationProfile();
  useShowLoader(tLoading, "ProfilePage");

  return (
    <WithRedirectionUserIsAuthorized
      yes={() => (
        <WaitForData
          loading={loading}
          error={error}
          onLoaded={() => (
            <Page className="ProfilePage">
              <Page.Title loading={tLoading}>
                {t("profile:recruiters_profile_title")}
              </Page.Title>
              <Page.Content>
                <ProfileBlock profile={profile} />
              </Page.Content>
            </Page>
          )}
        />
      )}
    />
  );
}
