import "./PopularSearches.scss";
import { Row, Col } from "react-bootstrap";
import useShowLoader from "../../common/loading-widgets/useShowLoader.js";
import useTranslationInsights from "../useTranslationInsights.js";
import PopularSearchButton from "./PopularSearchButton.js";

const PopularSearches = () => {
  const { t, loading: tLoading } = useTranslationInsights();
  useShowLoader(tLoading, "PopularSearches");

  const popularSearches = [
    t("insights:insights_popular_searches_button_label1"),
    t("insights:insights_popular_searches_button_label2"),
    t("insights:insights_popular_searches_button_label3"),
    t("insights:insights_popular_searches_button_label4"),
    t("insights:insights_popular_searches_button_label5"),
    t("insights:insights_popular_searches_button_label6"),
    t("insights:insights_popular_searches_button_label7"),
    t("insights:insights_popular_searches_button_label8"),
    t("insights:insights_popular_searches_button_label9"),
    t("insights:insights_popular_searches_button_label10"),
    t("insights:insights_popular_searches_button_label11"),
    t("insights:insights_popular_searches_button_label12"),
  ];

  return (
    <div className="PopularSearches">
      <Row className="popular-search-row-container">
        <Col>
          <Row>
            <Col>
              <h2>{t("insights:insights_popular_searches_label")}</h2>
            </Col>
          </Row>
          <Row className="popular-search-btns-row">
            <Col>
              {popularSearches.map((popularSearch, index) => (
                <PopularSearchButton key={index} keyword={popularSearch} />
              ))}
            </Col>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

export default PopularSearches;
